<template>
  <div
    class="modal fade"
    :id="id"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    :data-backdrop="'static'" 
    :data-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h2 class="h2 mb-0">{{ title }}</h2>
          <i
            type="button"
            class="fas fa-times text-muted"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="modalClosed()"
          ></i>
        </div>
        <div class="modal-description border-0">
          {{ description }}
        </div>
        <div class="modal-body border-0">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer border-0" v-if="this.$slots.footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    title: String,
    description: {
      type: String,
      default: ''
    }
  },
  methods: {
    modalClosed() {
      this.$emit('modalClosed')
    }
  }
};
</script>

<style>
.modal-description {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.5rem;
  color: #a19f9f;
  border-bottom: 1px solid #ecebeb;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px)  
}
</style>