import { Modal } from 'bootstrap';
import "cropperjs/dist/cropper.css";
import VueCropper from "vue-cropperjs";
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { email, required, url } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

export default {
    mounted() {
        this.videoModal = new Modal(document.getElementById('videoModal'), {
            backdrop: 'static',
            keyboard: false
        })
        // this.videoModal.toggle()
        this.getOnboardingQuestions()
        let externalScript = document.createElement('script')
        externalScript.setAttribute('src', `https://maps.googleapis.com/maps/api/js?libraries=places&key=${process.env.VUE_APP_GOOGLE_API_KEY}`)
        document.head.appendChild(externalScript)
    },
    computed: {
        userSession() {
            return this.$store.state.userSession;
        },
    },
    data() {
        return {
            // IMG CROP

            imgSrc: null,
            cropImg: "",

            // STEPS
            step: 1,
            percentage: 3,
            questions: [],
            // FORM
            location: {},
            form: {
                about: '',
                questions: {}
            },
            fileImage: null,
            // MODALS
            imageModal: null,
            videoModal: null,
            loading: false,
            errorMessage: ''
        };
    },
    components: {
        VueCropper,
        VueGoogleAutocomplete
    },
    validations() {
        let form = {
            about: { required },
            questions: {}
        }
        if (this.questions.length == 0) {
            return { form }
        } else {

            this.questions.map(r => {
                switch (r.type) {
                    case 'email':
                        form.questions[`${r.name}`] = { required, email }
                        break;
                    case 'url':
                        form.questions[`${r.name}`] = { required, url }
                        break;
                    case 'location':
                        form.questions[`${r.name}`] = {
                            city: { required },
                            region: { required },
                            country: { required },
                            countryInitials: { required },
                            coordinates: {
                                lat: { required },
                                lng: { required }
                            }
                        }
                        break;
                    default:
                        if (r.required) {
                            form.questions[`${r.name}`] = { required }
                        } else {
                            form.questions[`${r.name}`] = ''
                        }
                }

            })
            console.log(form.questions)
            return { form }
        }
    },
    methods: {
        ...mapActions([
            'syncSessionWithApi'
        ]),
        addressChanged(data) {
            console.log(data)
            const location = {
                city: data.administrative_area_level_2,
                region: data.administrative_area_level_1,
                country: data.country,
                countryInitials: data.country.slice(0, 2).toUpperCase(),
                coordinates: {
                    lat: data.latitude,
                    lng: data.longitude
                }
            }
            this.$set(this.form.questions, 'location', location)
        },
        resetLocation() {
            const l = {
                city: '',
                region: '',
                country: '',
                countryInitials: '',
                coordinates: {
                    lat: '',
                    lng: ''
                }
            }
            this.$set(this.form.questions, 'location', l)

        },
        cropImage() {
            // get image data for post processing, e.g. upload or setting image src
            this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL(null, this.$helper.DEFAULT_IMAGE_QUALITY);
            this.fileImage = this.$helper.dataURLtoFile(this.cropImg, 'text.png')
            this.imageModal.toggle()
        },
        setImage(e) {
            this.imageModal = new Modal(document.getElementById('imageModal'), {
                backdrop: 'static',
                keyboard: false
            })
            this.imageModal.toggle()
            const file = e.target.files[0];
            if (file.type.indexOf("image/") === -1) {
                alert("Please select an image file");
                return;
            }
            if (typeof FileReader === "function") {
                const reader = new FileReader();
                reader.onload = (event) => {
                    this.imgSrc = event.target.result;
                    // rebuild cropperjs with the updated source
                    this.$refs.cropper.replace(event.target.result);
                };
                reader.readAsDataURL(file);
            } else {
                alert("Sorry, FileReader API not supported");
            }
        },
        closeVideoModal() {
            this.videoModal.toggle()
        },
        nextStep(s) {
            // + 2 pq é um do primeiro step
            if (s == this.questions.length + 2) {
                this.completeOnboarding()
            } else {
                this.step = s
                this.percentage = this.percentage + 100 / this.questions.length
            }
        },

        async handleCompleteProfile() {
            this.errorMessage = ''
            try {
                this.loading = true
                const params = {
                    profileId: this.userSession.profileId,
                    img: { file: this.fileImage, base64: this.cropImg },
                    about: this.form.about
                }
                const res = await this.$universityService.completeProfile(params)
                this.nextStep(2)
            } catch (e) {
                this.errorMessage = e.message
            } finally {
                this.loading = false
            }
        },
        async completeOnboarding() {
            this.errorMessage = ''

            try {
                this.loading = true
                const params = {
                    ...this.form.questions,
                }
                // console.log(params)
                // return
                const res = await this.$universityService.completeOnboarding(params)
                await this.syncSessionWithApi()
                this.$eventBus.$emit('showToast', { title: 'Profile completed', msg: `Your profile has been completed successfully.` })
                this.$router.push({ path: this.$paths.home })
            } catch (e) {
                this.errorMessage = e.message
            } finally {
                this.loading = false
            }
        },
        async getOnboardingQuestions() {
            const res = await this.$universityService.getOnboardingQuestions()
            this.$set(this, 'questions', res.data.data.filter(q => q != null))
            let q = {}
            this.questions
                .sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
                .map((r, index) => {
                    console.log('asdasdasdzsdasd', r)
                    if (r.type == 'location') {
                        r.tooltipInfo = 'Search for an address wich contains the region, country and province/state'
                        q[`${r.name}`] = {
                            city: '',
                            region: '',
                            country: '',
                            countryInitials: '',
                            coordinates: {
                                lat: '',
                                lng: ''
                            }
                        }
                    } else {
                        q[`${r.name}`] = ''
                    }
                })
            console.log('questions', q)
            this.$set(this.form, 'questions', q)


        }
    },
};