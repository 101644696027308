import { required, email, minLength } from 'vuelidate/lib/validators';
import { firstAndLastName } from '@/utils/Validators';
export default {
  name: 'Signin',
  data() {
    return {
      selectStyle: null,
      countryPhoneCodes: this.$helper.countryPhoneCodes,
      eventLabel: 'Select country',
      form: {
        institutionName: '',
        representativeName: '',
        representativePhone: '',
        phoneCode: ''
      },
    };
  },
  validations: {
    form: {
      institutionName: {
        required,
        minLength: minLength(3)
      },
      representativeName: {
        required,
        firstAndLastName
      },
      representativePhone: {
        required,
        minLength: minLength(10)
      },
      phoneCode: {
        required
      },
    }
  },
  methods: {
    togglePassword() {
      this.passwordFieldType = this.passwordFieldType == 'password' ? 'text' : 'password'
    },
    submit() {
      const params = {
        formValues: {
          ...this.form,
          representativePhone: `${this.form.phoneCode}${this.form.representativePhone}`
        }
      }
      delete(params.formValues.phoneCode)
      this.$router.push({ path: this.$paths.signup.account, params, query: params.formValues })
    },
    setPhoneCountry(country) {
      this.$set(this.form, 'phoneCode', country.phoneCode)
      this.$set(this, 'eventLabel', `${country.countryName}`)

    },
    searchCountry(e) {
      console.log(e.target.value)
      let ct = this.$helper.searchString(this.$helper.countryPhoneCodes, e.target.value)
      this.$set(this,'countryPhoneCodes', ct.filter((ftr) => !ftr.hidden))

    }
  }
};