<template>
  <div class="audience">
    <Sidebar />

    <NavBar current="Student Detail" :link="$paths.audience" linkName="Audience" />
    <LoadingContainer v-if="loadingAll" />
    <div v-else class="main-content">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12 col-xl-8 col-lg-10">
            <div class="header mt-md-5">
              <div class="header-body pb-0 border-0">
                <div class="row">
                  <div class="col-12 text-center">
                    <span class="text-danger text-center">{{ errorMessage }}</span>
                  </div>
                </div>
                <div class="row align-items-end">
                  <div class="col">
                    <h6 class="header-pretitle">Student details</h6>
                    <h1 v-if="student.student" class="header-title">
                      {{ accepted ? student.student.name : student.student.profile.firstname }}
                    </h1>
                  </div>
                  <div class="col-auto me-0">
                    <button class="btn badge bg-primary-soft btn-sm pr-0">
                      <span v-if="student.match && student.match.value"
                        >Match {{ student.match.value }}%</span
                      >
                      <span v-else>Match 0%</span>
                    </button>

                    <button 
                      v-if="student.isFollower" 
                      @click="getOrCreateMessage()" 
                      :disabled="loadingMessage"
                      class="btn btn-primary btn-sm" style="margin-left: 10px;">
                        <span
                          v-if="loadingMessage"
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      Send a message
                    </button>
                  </div>
                  <div class="col-auto">
                    <button
                      :disabled="loadingAccept"
                      v-if="!accepted"
                      class="btn btn-primary btn-sm"
                      @click="accept()"
                    >
                      <span
                        v-if="loadingAccept"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Accept for ${{ this.request.costToAccept }}
                    </button>
                  </div>
                </div>
                <hr class="mt-5" />
              </div>
            </div>

            <div class="col-auto">
              <a
                v-if="student.student && student.student.image"
                class="avatar avatar-xl mb-4"
              >
                <img
                  :src="student.student.image"
                  alt="..."
                  class="avatar-img rounded-circle"
                />
              </a>
              <form action="">
                <div class="row">
                  <!-- <div class="form-group col-6">
                    <PrivateField
                      label="Email"
                      :show="accepted"
                      :value="student.student.email"
                    />
                  </div>
                  <div class="form-group col-6">
                    <PrivateField
                      :label="'Phone number'"
                      :show="accepted"
                      :value="student.student.profile.phone || 'Not informed'"
                    />
                  </div> -->
                  <div class="form-group col-12">
                    <PrivateField
                      label="Location"
                      :show="true"
                      :value="
                        student.student.profile.location
                          ? `${student.student.profile.location.country}, ${student.student.profile.location.region}`
                          : 'Not informed'
                      "
                    />
                  </div>
                  <div class="form-group col-4">
                    <PrivateField
                      label="School Type"
                      :show="true"
                      :value="
                        student.student.profile.highSchoolType
                          ? `${student.student.profile.highSchoolType}`
                          : 'Not informed'
                      "
                    />
                  </div>
                  <div class="form-group col-4">
                    <PrivateField
                      label="School Name"
                      :show="true"
                      :value="
                        student.student.profile.highSchool
                          ? `${student.student.profile.highSchool.name || 'Not informed'}`
                          : 'Not informed'
                      "
                    />
                  </div>
                  <div class="form-group col-4 mb-0">
                    <PrivateField
                      label="Graduation date"
                      :show="true"
                      :value="
                        student.student.profile.expectFinishSchool &&
                        student.student.profile.expectFinishSchool.year &&
                        student.student.profile.expectFinishSchool.semester
                          ? `${
                              student.student.profile.expectFinishSchool.semester == 1
                                ? 'First'
                                : 'Second'
                            } semester of ${
                              student.student.profile.expectFinishSchool.year
                            } `
                          : 'Not informed'
                      "
                    />
                  </div>
                  <div class="form-group col-12">
                    <PrivateExams
                      :show="true"
                      label="Exams of interest"
                      :value="student.student.profile.exams"
                    />
                  </div>
                  <div class="form-group col-12">
                    <PrivateFieldMultiselect
                      :show="true"
                      label="Type of school of interest"
                      :value="student.student.profile.parser.programTypeYears"
                    />
                  </div>

                  <div class="form-group col-12">
                    <PrivateFieldMultiselect
                      :show="true"
                      label="Field of study of interest"
                      :value="student.student.profile.parser.fieldsOfStudy"
                    />
                  </div>
                  <div class="form-group col-12">
                    <PrivateFieldMultiselect
                      :show="true"
                      label="Majors of interest"
                      :value="student.student.profile.parser.majors"
                    />
                  </div>

                  <div class="form-group col-12 mb-0 pb-0">
                    <PrivateFieldMultiselect
                      :show="true"
                      label="Locations of interest"
                      :value="getCountriesOfInterest()"
                    />
                  </div>
                  <div class="form-group col-12">
                    <div
                      v-show="true"
                      v-for="(location, index) in student.student.profile.parser.locationOfInterest"
                      :key="index"
                      style="margin-bottom:0px;padding-bottom:0px;margin-top:0px;"
                    >
                      <div
                        v-if="location.regions.length > 0"
                        class="input-group input-group-merge mb-0"
                        style="padding-left: 0px;margin-top:0px;padding-top:0"
                      >
                        <div
                          class="
                            input-group-text
                            border-right-0
                            border-top-right-radius-0
                            border-bottom-right-radius-0
                            border-0
                            ps-0 pt-3 pb-0
                          "
                          id="inputGroup"
                        >
                          {{location.countryId}}
                        </div>
                        <PrivateFieldMultiselect
                          :show="true"
                          v-if="location.regions.length > 0"
                          :value="location.regions"
                          style="margin-top:0px;padding-top:0px;"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-12">
                    <PrivateField
                      :show="true"
                      label="School size of preference"
                      :value="student.student.profile.parser.institutionSize
                        ? student.student.profile.parser.institutionSize
                        : 'Not informed'"
                    />
                  </div>
                  <div class="form-group col-12">
                    <PrivateField
                      :show="true"
                      label="School setting preferences"
                      :value="student.student.profile.parser.environmentalSetting
                        ? student.student.profile.parser.environmentalSetting
                        : 'Not informed'"
                    />
                  </div>
                  <div class="form-group col-12">
                    <PrivateField
                      :show="true"
                      label="Housing availability preferences"
                      :value="student.student.profile.parser.accommodationType
                        ? student.student.profile.parser.accommodationType
                        : 'Not informed'"
                    />
                  </div>
                  <div 
                    v-if="student.student.profile.disabilityType && !student.student.profile.disabilityType.includes('none')"
                    class="form-group col-12"
                  >
                    <PrivateFieldMultiselect
                      :show="true"
                      label="Disability services and facilities of interest"
                      :value="student.student.profile.parser.disabilityType"
                    />
                  </div>
                  <div class="form-group col-3">
                    <PrivateField
                      :show="true"
                      label="Investment per year"
                      :value="
                        student.student.profile.parser.investmentPerYear
                          ? getInvestmentPerYear(student.student.profile.parser.investmentPerYear)
                          : 'Not informed'
                      "
                    />
                  </div>
                  <div 
                    v-if="student.student.profile.institutionServices && !student.student.profile.institutionServices.includes('none')"
                    class="form-group col-9"
                  >
                    <PrivateFieldMultiselect
                      :show="true"
                      label="Services of interest"
                      :value="student.student.profile.parser.institutionServices"
                    />
                  </div>
                </div>
                <hr />
                <div class="row pt-3">
                  <div class="col-12">
                    <h2>Following Universities</h2>
                    <div>
                      <EmptyState
                        title="No Universities"
                        msg="This student does not follow any universities yet "
                        v-if="!universities || !universities.length"
                      >
                        <template v-slot:icon>
                          <i class="fas fa-university fa-3x text-primary"></i>
                        </template>
                      </EmptyState>

                      <div v-else class="row">
                        <div
                          class="col-4"
                          v-for="item in student.followingUniversities"
                          v-bind:key="item.id"
                        >
                          <div class="">
                            <div class="card-body p-0 mt-2 mb-3">
                              <div class="avatar avatar-xxl header-avatar-top" style="min-width: 200px">
                              <img
                                :src="item.institution.image"
                                :alt="item.institution.name"
                                :title="item.institution.name"
                                class="avatar-img rounded"
                              />
                              <!-- <img src="https://s3.amazonaws.com/content.hub.stag-dev.fpptech.ai/upload/user/5f32f634e1ed2020e4e5ea19/1650927283116.jpeg"  class="avatar-img rounded-3"> -->
                              </div>
                              <!-- <p>{{ item.institution.name }}</p> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <hr /> -->
                <!-- <div class="row pt-3">
                  <div class="col-12">
                    <h2>Preferences</h2>
                    <div class="row" v-if="!accepted">
                      <div class="col-3">
                        <PrivateContent />
                      </div>
                    </div>
                    <div class="row" v-if="accepted">
                      <div class="col-12">
                        <div class="">
                          <div class="list-group list-group-flush my-n3">
                            <div
                              class="list-group-item"
                              v-for="item in questions"
                              v-bind:key="item.id"
                            >
                              <div class="row align-items-center">
                                <div class="col">
                                  <h4 class="font-weight-base mb-1 text-dark">
                                    {{ item.title }}
                                  </h4>
                                  <small class="text-muted">
                                    {{ item.answer }}
                                  </small>
                                </div>
                                <div class="col-auto">
                                  
                                  <div class="form-check form-switch">
                                    <span class="ms-auto badge bg-primary-soft">99%</span>
                                  </div>
                                </div>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import StudentDetailController from "./StudentDetailController";
export default StudentDetailController;
</script>
<style scoped>
.bg-input {
  background-color: #ececec;
}
</style>
