<template>
  <div class="audience">
    <Sidebar />
    <NavBar current="Dashboard" />
    <LoadingContainer v-if="loadingAll" />
    <div class="main-content" v-else>
      <div class="container-fluid">
        <div class="row d-flex justify-content-center">
          <div class="col-8"></div>
        </div>
        <div class="row">
          <div class="col-12 col-xl-8">
            <div class="header mt-md-5">
              <div class="header-body border-0">
                <div class="row align-items-center">
                  <div class="col">
                    <h1 class="header-title">Dashboard</h1>
                  </div>
                  <div class="col-auto">
                    <MonthSelector v-on:monthSelectorChanged="dateChanged" />
                  </div>
                </div>

                <div
                  class="row align-items-center"
                  v-if="Object.keys(tasks).some((t) => !tasks[t])"
                >
                  <div class="col-12 mt-5">
                    <div class="card bg-primary-soft-lighter border-0">
                      <div class="card-body">
                        <div class="mb-4">
                          <h3 class="card-header-title h3">
                            <strong>Welcome to NOVVA</strong>
                          </h3>
                          <span class="text-dark"
                            >For a successful usage please complete the tasks below
                          </span>
                        </div>

                        <div class="checklist" tabindex="0">
                          <div class="row ps-0" tabindex="0">
                            <div class="col-6">
                              <router-link
                                tag="div"
                                :to="$paths.profile"
                                v-if="!tasks.registration"
                              >
                                <i class="fas fa-square me-2 text-primary-shadow"></i>
                                <label class="form-check-label h4"
                                  >Complete your Registration.</label
                                >
                              </router-link>
                              <div v-else>
                                <i class="fas fa-check-square text-primary me-2"></i>
                                <label
                                  class="form-check-label cursor-default line-through"
                                  >Complete your Registration.</label
                                >
                              </div>
                            </div>
                            <div class="col-6">
                              <router-link
                                tag="div"
                                :to="$paths.university.scholarship.add"
                                v-if="!tasks.scholarship"
                              >
                                <i class="fas fa-square me-2 text-primary-shadow"></i>
                                <label class="form-check-label  h4"
                                  >Add a Scholarship.</label
                                >
                              </router-link>
                              <div v-else>
                                <i class="fas fa-check-square text-primary me-2"></i>
                                <label
                                  class="form-check-label cursor-default line-through"
                                  >Add a Scholarship.</label
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr class="text-primary-shadow" />
                        <div class="checklist" tabindex="0">
                          <div class="row ps-0" tabindex="0">
                            <div class="col-6">
                              <router-link
                                tag="div"
                                :to="$paths.agenda.list"
                                v-if="!tasks.availability"
                              >
                                <i class="fas fa-square me-2 text-primary-shadow"></i>
                                <label class="form-check-label  h4"
                                  >Complete your availability.</label
                                >
                              </router-link>
                              <div v-else>
                                <i class="fas fa-check-square text-primary me-2"></i>
                                <label
                                  class="form-check-label cursor-default line-through"
                                  >Complete your availability.</label
                                >
                              </div>
                            </div>
                            <div class="col-6">
                              <router-link
                                tag="div"
                                :to="$paths.university.brochure.add"
                                v-if="!tasks.brochure"
                              >
                                <i class="fas fa-square me-2 text-primary-shadow"></i>
                                <label class="form-check-label  h4"
                                  >Add a Brochure.</label
                                >
                              </router-link>
                              <div v-else>
                                <i class="fas fa-check-square text-primary me-2"></i>
                                <label
                                  class="form-check-label cursor-default line-through"
                                  >Add a Brochure.</label
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr class="text-primary-shadow" />
                        <div class="checklist" tabindex="0">
                          <div class="row ps-0" tabindex="0">
                            <div class="col-6">
                              <router-link
                                tag="div"
                                :to="$paths.social"
                                v-if="!tasks.post"
                              >
                                <i class="fas fa-square me-2 text-primary-shadow"></i>
                                <label class="form-check-label h4"
                                  >Publish you first post.</label
                                >
                              </router-link>
                              <div v-else>
                                <i class="fas fa-check-square text-primary me-2"></i>
                                <label
                                  class="form-check-label cursor-default line-through"
                                  >Publish you first post.</label
                                >
                              </div>
                            </div>
                            <div class="col-6">
                              <router-link
                                tag="div"
                                :to="$paths.wallet.add_credits"
                                v-if="!tasks.credits"
                              >
                                <i class="fas fa-square me-2 text-primary-shadow"></i>
                                <label class="form-check-label  h4"
                                  >Add credit to your wallet.</label
                                >
                              </router-link>
                              <div v-else>
                                <i class="fas fa-check-square text-primary me-2"></i>
                                <label
                                  class="form-check-label cursor-default line-through"
                                  >Add credit to your wallet.</label
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <ul class="nav nav-tabs header-tabs" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <a
                          @click.prevent="handleSelectTab(0)"
                          class="nav-link active"
                          id="views-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#views"
                          type="button"
                          role="tab"
                          aria-controls="views"
                          aria-selected="true"
                        >
                          Profile Views
                          <span
                            class="ms-auto badge"
                            :class="{
                              'bg-primary-soft': selectedTab == 0,
                              'bg-secondary-soft': selectedTab != 0,
                            }"
                            >{{ requestsToFollow.partial }}</span
                          >
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          @click.prevent="handleSelectTab(1)"
                          class="nav-link"
                          id="requests-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#views"
                          type="button"
                          role="tab"
                          aria-controls="views"
                          aria-selected="false"
                        >
                          Follower Requests
                          <span
                            class="ms-auto badge"
                            :class="{
                              'bg-primary-soft': selectedTab == 1,
                              'bg-secondary-soft': selectedTab != 1,
                            }"
                            >{{ requestsToFollow.partial }}</span
                          >
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          @click.prevent="handleSelectTab(2)"
                          class="nav-link"
                          id="appointments-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#views"
                          type="button"
                          role="tab"
                          aria-controls="views"
                          aria-selected="false"
                        >
                          Appointments
                          <span
                            class="ms-auto badge"
                            :class="{
                              'bg-primary-soft': selectedTab == 2,
                              'bg-secondary-soft': selectedTab != 2,
                            }"
                            >{{ appointmentRequests.partial }}</span
                          >
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="tab-content" id="myTabContent">
              <!-- VIEWS -->
              <div
                class="table-responsive mt-5 tab-pane fade show active"
                id="views"
                role="tabpanel"
                aria-labelledby="views-tab"
              >
                <div class="row" v-if="chartData">
                  <div class="col-12">
                    <BarChart :chartData="chartData" />
                  </div>
                </div>
              </div>
              <!-- REQUESTS -->
              <!-- <div
                class="table-responsive mt-5 tab-pane fade"
                id="requests"
                role="tabpanel"
                aria-labelledby="requests-tab"
              >
                <div class="row" v-if="requestToFollowChartData">
                  <div class="col-12">
                    askndjoalsmdnjaos;dl
        
                  </div>
                </div>
              </div> -->
              <!-- APPOINTMENTS -->
              <!-- <div
                class="table-responsive mt-5 tab-pane fade"
                id="appointments"
                role="tabpanel"
                aria-labelledby="appointments-tab"
              >
                <div class="row" v-if="appointmentsChartData">
                  <div class="col-12">
                    <BarChart :chartData="appointmentsChartData" />
                  </div>
                </div>
              </div> -->
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row mt-6">
                  <div class="col-xs-4 col-sm-12 col-md-4">
                    <div
                      class="card shadow-none usage-cards border-2 border-primary bg-primary"
                    >
                      <div class="card-body">
                        <!-- Heading -->
                        <h1 class="h1 text-white header-title">
                          {{ requestsToFollow.partial
                          }}<span class="ms-3 fas fa-user-plus text-white"></span>
                        </h1>
                        <!-- Text -->
                        <h3 class="card-header-title h3 text-white pt-3">
                          <strong>Follow requests</strong>
                        </h3>
                      </div>
                      <div
                        class="card-footer pt-0 border-0 d-column d-flex justify-content-between"
                      >
                        <p class="card-text text-white">
                          {{ requestsToFollow.total }} total
                        </p>
                        <router-link tag="a" :to="$paths.audience" class="text-white">
                          See more
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-4 col-sm-12 col-md-4">
                    <div class="card shadow-none usage-cards border-2">
                      <div class="card-body">
                        <!-- Heading -->
                        <h1 class="h1 header-title">
                          {{ appointmentRequests.partial
                          }}<span class="ms-3 far fa-calendar-alt text-primary"></span>
                        </h1>
                        <!-- Text -->
                        <h3 class="card-header-title h3 pt-3">
                          <strong>Appointment request</strong>
                        </h3>
                      </div>
                      <div
                        class="card-footer pt-0 border-0 d-column d-flex justify-content-between"
                      >
                        <p class="card-text text-muted">
                          {{ appointmentRequests.total }} total
                        </p>
                        <router-link
                          tag="a"
                          :to="{ path: $paths.agenda.list, query: { tab: 1 } }"
                          class="text-primary"
                        >
                          See more
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-4 col-sm-12 col-md-4">
                    <div class="card shadow-none usage-cards border-2">
                      <div class="card-body">
                        <!-- Heading -->
                        <h1 class="h1 header-title">
                          {{ posts.partial
                          }}<span class="ms-3 fas fa-clone text-primary"></span>
                        </h1>
                        <!-- Text -->
                        <h3 class="card-header-title h3 pt-3">
                          <strong>Posts</strong>
                        </h3>
                      </div>
                      <div
                        class="card-footer pt-0 border-0 d-column d-flex justify-content-between"
                      >
                        <p class="card-text text-muted">{{ posts.total }} total</p>
                        <router-link
                          tag="a"
                          :to="{ path: $paths.social, query: { tab: 1 } }"
                          class="text-primary"
                        >
                          See more
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-4 col-sm-12 col-md-4">
                    <div class="card shadow-none usage-cards border-2">
                      <div class="card-body">
                        <!-- Heading -->
                        <h1 class="h1 header-title">
                          {{ newFollowers.partial
                          }}<span class="ms-3 fas fa-users text-primary"></span>
                        </h1>
                        <!-- Text -->
                        <h3 class="card-header-title h3 pt-3">
                          <strong>New Folowers</strong>
                        </h3>
                      </div>
                      <div
                        class="card-footer pt-0 border-0 d-column d-flex justify-content-between"
                      >
                        <p class="card-text text-muted">{{ newFollowers.total }} total</p>
                        <router-link
                          tag="a"
                          :to="{
                            path: $paths.audience,
                            query: { tab: 1 },
                          }"
                          class="text-primary"
                        >
                          See more
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-4 col-sm-12 col-md-4">
                    <div class="card shadow-none usage-cards border-2">
                      <div class="card-body">
                        <!-- Heading -->
                        <h1 class="h1 header-title">
                          {{ scholarshipApplications.partial
                          }}<span class="ms-3 fas fa-graduation-cap text-primary"></span>
                        </h1>
                        <!-- Text -->
                        <h3 class="card-header-title h3 pt-3">
                          <strong>Scholarship Applications</strong>
                        </h3>
                      </div>
                      <div
                        class="card-footer pt-0 border-0 d-column d-flex justify-content-between"
                      >
                        <p class="card-text text-muted">
                          {{ scholarshipApplications.total }} total
                        </p>
                        <router-link
                          tag="a"
                          :to="$paths.university.list"
                          class="text-primary"
                        >
                          See more
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-4 col-sm-12 col-md-4">
                    <div class="card shadow-none usage-cards border-2">
                      <div class="card-body">
                        <!-- Heading -->
                        <h1 class="h1 header-title">
                          {{ usedCredits.total
                          }}<span class="ms-3 fas fa-coins text-primary"></span>
                        </h1>
                        <!-- Text -->
                        <h3 class="card-header-title h3 pt-3">
                          <strong>Credits Used</strong>
                        </h3>
                      </div>
                      <div
                        class="card-footer pt-0 border-0 d-column d-flex justify-content-between"
                      >
                        <p class="card-text text-white">x</p>
                        <router-link
                          tag="a"
                          :to="{ path: $paths.wallet.list, query: { tab: 1 } }"
                          class="text-primary"
                        >
                          See more
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-4">
            <div class="header-body mt-3"></div>
            <AppointmentsCalendar
              :data="{ appointments: appointments, date: null }"
              showAppointments
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OverviewController from "./OverviewController";
export default OverviewController;
</script>
<style scoped>
.line-through {
  text-decoration: line-through;
}
.card.bg-primary-soft-lighter {
  background: #ffeee7;
}
#profile-right-container {
  position: fixed;
  right: 0;
}
.card.usage-cards {
  min-height: 170px;
}
</style>
