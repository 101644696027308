<template>
  <div class="slider">
    <div
      class="slider-inner"
      :style="`grid-template-columns: repeat(${length}, 1fr);`"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    length: Number,
  },
  mounted() {
    let slider = document.querySelector(".slider");
    let innerSlider = document.querySelector(".slider-inner");

    let pressed = false;
    let startx;
    let x;

    slider.addEventListener("mousedown", (e) => {
      pressed = true;
      startx = e.offsetX - innerSlider.offsetLeft;
      slider.style.cursor = "grabbing";
    });

    slider.addEventListener("mouseenter", () => {
      slider.style.cursor = "grab";
    });

    slider.addEventListener("mouseleave", () => {
      slider.style.cursor = "default";
    });

    slider.addEventListener("mouseup", () => {
      pressed = false;
      slider.style.cursor = "grab";
    });

    slider.addEventListener("mousemove", (e) => {
      if (!pressed) return;
      e.preventDefault();

      x = e.offsetX;
      innerSlider.style.left = `${x - startx}px`;
      checkboundery();
    });

    function checkboundery() {
      let outer = slider.getBoundingClientRect();
      let inner = innerSlider.getBoundingClientRect();
      if (parseInt(innerSlider.style.left) > 0) {
        innerSlider.style.left = "0px";
      } else if (inner.right < outer.right) {
        innerSlider.style.left = `-${inner.width - outer.width}px`;
      }
    }
  },
};
</script>

<style scoped>
.slider {
  position: absolute;
  overflow: hidden;
  height: 70px;
  width: 53%;
}
.slider-inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 300%;
  display: grid;
  pointer-events: none;
  user-select: none;
  /* grid-template-columns: repeat(31, 1fr); */
  /* gap: 5px; */
  /* transition: 0s ease-in; */
}
</style>