<template>
  <div>
    <router-view />
    <NotificationToast />
    <WalletModal />
    <ConfirmationAlert />
  </div>
</template>

<script>
import NotificationToast from "@/components/NotificationToast";
import WalletModal from "@/components/WalletModal";
export default {
  name: "App",
  mounted() {
  },
  components: {
    NotificationToast,
    WalletModal,
  },
};
</script>
