<template>
  <div class="radio">
    <input type="radio" :ref="id" :id="id" :checked="checked" v-on="$listeners" />
    <label :for="id" :class="['label', { hasImage }]">
      <span :class="[{hasImage}]"></span>
      <slot name="label" />
      <slot name="image" />
    </label>
  </div>
</template>
<script>
export default {
  name: 'BaseFormRadio',
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    hasImage: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      id: null
    }
  },
  mounted() {
    this.id = this._uid
  }
}
</script>
<style lang="scss" scoped>
.radio {
  font-size: 15px;
  font-weight: 300;
  display: flex;

  .label {
    // margin-left: 8px;
    span {
      margin-right: 20px;
    }
    span.hasImage {
      margin-top: 1rem;
    }
  }
  input[type='radio'] {
    display: none;
  }
  input[type='radio'] + label span {
    position: relative;
    cursor: pointer;
  }
  input[type='radio'] + label span:before {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: -1px;
    left: -1px;
    border: solid 1px #979797;
    border-radius: 100%;
    background: white;
  }
  input[type='radio']:checked + label span:before {
    border: none;
    position: absolute;
    left: -1px;
    top: -1px;
    width: 16px;
    height: 16px;
    border: 1px solid #979797;
    border-radius: 100%;
    background: white;
  }
  input[type='radio']:checked + label span:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: #fd7e14;
    border-radius: 100%;
    position: absolute;
    top: 2px;
    left: 2px;
  }
  input[type='radio']:checked + label {
    img,
    video {
      border: 2px solid #fd7e14;
    }
  }
  .radio-image {
    width: 200px;
    height: 128px;
    cursor: pointer;
  }
  .hasImage {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}
</style>
