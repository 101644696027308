import NavBar from "@/components/NavBar";
import Sidebar from "@/components/Sidebar";
import DatePicker from "vue2-datepicker";
import "cropperjs/dist/cropper.css";
import { maxValue, minValue, required, url } from 'vuelidate/lib/validators';
import moment from 'moment'
import { isDateFuture, isDateValid } from '@/utils/Validators';
import BaseFormRadioGroup from "../../../../components/BaseFormRadioGroup"
import BaseFormInputTagsAutocomplete from "../../../../components/BaseFormInputTagsAutocomplete"
import BaseFormExams from "../../../../components/BaseFormExams"
import BaseSelectTags from "../../../../components/BaseSelectTags"
import BaseInputLocation from "../../../../components/BaseInputLocation"

const formInitialState = () => {
    return {
        suitableFor: '',
        type: '',
        applicableSports: [],
        applicableAreas: [],
        applicableAreasOther: '',
        ethnicityCriteria: '',
        needsBasedSubtype: '',
        locations: [],
        title: '',
        description: '',
        linkInformation: '',
        awardableAmount: '',
        awardable: {
            currency: '',
            upTo: 0,
            average: 0,
            amountValue: 0,
        },
        availableSpaces: '',
        datesAvailability: {
            availableSpaces: '',
            dateRange: '',
            deadline: '',
        },
        exams: [],
        requirements: [{
            title: '',
            description: ''
        }],
    };
  };

export default {
    async created() {
        await this.loadFields();

        let session = this.$store.state.userSession
        this.country = session.profile.institution.location.countryInitials

        if (this.isInternational()) {
            this.typeOptions = this.typeOptionsInternational
            this.form.suitableFor = 'international-students'
            this.step = 2
        }
    },
    data() {
        return {
            form: formInitialState(),
            quillOptions: {
                placeholder: 'Enter the scholarship description',
                readonly: false,
                bold: false,
                modules: {
                    toolbar: [['bold', 'italic', 'underline', 'strike', 'list'], [{ 'list': 'ordered' }]]
                },
            },
            typeOptions: [],
            typeOptionsUs: [],
            typeOptionsUk: [],
            typeOptionsInternational: [],
            examsOptions: [],
            needsBasedSubtypeOptions: [],
            ethnicityCriteriaOptions: [],
            awardableAmountCurrencyOptions: [],
            awardableAmountOptions: [],
            step: 1,
            answeredSteps: [],
            paymentMethod: 'credit_card',
            loading: false,
            errorMessage: '',
            locationErrorMsg: '',
            country: '',
            countInputs: 1,
        };
    },
    components: {
        DatePicker,
        BaseFormRadioGroup,
        BaseFormInputTagsAutocomplete,
        BaseInputLocation,
        BaseFormExams,
        BaseSelectTags
    },
    validations: {
        form: {
            locations: {},
            applicableSports: { required },
            applicableAreas: { required },
            applicableAreasOther: { required },
            needsBasedSubtype: { required },
            ethnicityCriteria: { required },
            exams: { 
                required,
                scoreValidation(exams) {
                    let validate = exams.filter(exam => !exam.score)
                    return !validate.length
                }
            },
            suitableFor: { required },
            type: { required },
            title: { required },
            description: { required },
            linkInformation: { 
                required,
                url
            },
            awardableAmount: { required },
            awardable: {
                currency: { required },
                upTo: {
                    required,
                    minValue: minValue(1)
                },
                average: {
                    required,
                    minValue: minValue(1)
                },
                amountValue: {
                    required,
                    minValue: minValue(1)
                },
            },
            availableSpaces: { required },
            datesAvailability: {
                availableSpaces: {
                    required,
                    minValue: minValue(1),
                    maxValue: maxValue(999),
                },
                dateRange: { required },
                deadline: { 
                    isDateValid,
                    isDateFuture,
                    required 
                },
            },
            requirements: {
                required,
                $each: {
                    title: { required },
                    description: { required }
                }
            }
        }
    },
    methods: {
        validateAmount() {
            return this.$v.form.awardable.currency.$invalid ||
                (this.form.awardableAmount == 'up-to' && this.$v.form.awardable.upTo.$invalid) ||
                (this.form.awardableAmount == 'variable' && this.$v.form.awardable.average.$invalid) ||
                (this.form.awardableAmount == 'fixed' && this.$v.form.awardable.amountValue.$invalid)
        },
        validateDetails() {
            return this.$v.form.title.$invalid ||
                this.$v.form.description.$invalid ||
                this.$v.form.linkInformation.$invalid ||
                this.$v.form.requirements.$invalid
        },
        validateType() {
            return this.$v.form.type.$invalid ||
                (this.form.type == 'athletic-sports' && this.$v.form.applicableSports.$invalid) ||
                (this.form.type == 'gifted-talented' && this.$v.form.applicableAreas.$invalid) ||
                (this.form.type == 'location-based' && this.$v.form.locations.$invalid) ||
                (this.form.type == 'merit-academic' && this.$v.form.exams.$invalid) ||
                (this.form.type == 'needs-based' && this.$v.form.needsBasedSubtype.$invalid) ||
                (this.form.type == 'minorities-ethnicity' && this.$v.form.ethnicityCriteria.$invalid)
        },
        validateAudience() {
            return this.$v.form.suitableFor.$invalid
        },

        validateDatesAvailability() {
            return this.$v.form.availableSpaces.$invalid || 
                (this.$v.form.datesAvailability.availableSpaces.$invalid) ||
                (this.form.availableSpaces == 'variable-range' && this.$v.form.datesAvailability.dateRange.$invalid) ||
                (this.form.availableSpaces == 'specific' && this.$v.form.datesAvailability.deadline.$invalid) ||
                this.loading
        },
        setStep(step) {
            if(this.canAccess(step) || this.canAccess(step-1)) {
                this.step = step
            }
        },
        canFinish() {
            if (!this.isInternational() && this.validateAudience()) {
                return false
            } else {
                return !this.validateType() && !this.validateDetails() && !this.validateAmount()
            }
        },
        canAccess(step) {
            return this.answeredSteps.includes(step)
        },
        isInternational() {
            return !['US', 'GB', 'UK'].includes(this.country);
        },
        nextStep(step) {
            if (!this.answeredSteps.includes(step-1)) {
                this.answeredSteps.push(step-1)
            }

            this.step = step
        },
        async loadFields() {
            const data = (await this.$universityService.getScholarshipFields()).data;

            let  {
                sports,
                areas,
                ethnicityCriteria,
                needsBased,
                currencies,
                exams,
                internationalTypes,
                usTypes,
                ukTypes,
                awardableAmount
            } = data.data;

            this.applicableSportsOptions = sports;
            this.applicableAreasOptions = areas;
            this.ethnicityCriteriaOptions = ethnicityCriteria
            this.needsBasedSubtypeOptions = needsBased
            this.awardableAmountCurrencyOptions = currencies
            this.examsOptions = exams
            this.typeOptionsInternational = internationalTypes
            this.typeOptionsUs = usTypes
            this.typeOptionsUk = ukTypes
            this.awardableAmountOptions = awardableAmount
        },
        async handleContinue() {
            try {
                let dateRange = []
                if(this.form.datesAvailability.dateRange) {
                    dateRange = this.form.datesAvailability.dateRange.map(date => moment(date).format())
                }

                const params = { 
                    ...this.form,
                    datesAvailability: {
                        dateRange,
                        deadline: this.form.datesAvailability.deadline ? moment(this.form.datesAvailability.deadline).format() : '',
                        availableSpaces: this.form.datesAvailability.availableSpaces
                    }
                }

                this.errorMessage = ''
                this.loading = true
                const res = await this.$universityService.createScholarship(params)
                this.$eventBus.$emit('showToast', { title: 'Scholarship created', msg: `${this.form.title} was created successfully` })
                this.$router.push({ path: this.$paths.university.list })
            } catch (e) {
                this.errorMessage = e.message
            } finally {
                this.loading = false
            }
        },
        addRequirement() {
            this.form.requirements.push({
                title: '',
                description: ''
            })
        },
        removeRequirement(index) {
            this.form.requirements.splice(index, 1)
        },
        addressChanged(data) {
            this.locationErrorMsg = "";
            if (
              (!data.city && !data.region) ||
              !data.country ||
              !data.coordinates.lat ||
              !data.coordinates.lng
            ) {
              this.locationErrorMsg = "Please, select a more detailed address";
            } else {
                let locations = this.form.locations;
                locations.push(data);
                this.$set(this.form, "locations", locations);
            }
        },
        addNewInputLocation() {
            this.countInputs++
        },
    },
    watch: {
        'form.suitableFor'(newValue) {
            Object.assign(this.form, formInitialState());

            this.form.suitableFor = newValue;
            if (!this.isInternational()) {
                this.step = 1
            } else {
                this.step = 2
            }
            
            if(newValue == 'domestic-students') {
                this.typeOptions = this.country == 'US' ? this.typeOptionsUs : (['GB', 'UK'].includes(this.country) ? this.typeOptionsUk : this.typeOptionsInternational) 
            } else {
                this.typeOptions = this.typeOptionsInternational
            }
        }
    }
};