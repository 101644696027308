import { required, email } from "vuelidate/lib/validators";
import UserService from '@/services/UserService'
export default {
  name: "Signin",
  data() {
    return {
      errorMessage: '',
      successMessage: '',
      loading: false,
      form: {
        email: ''
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    }
  },
  methods: {
    async submit() {
      this.errorMessage = ''
      this.successMessage = ''
      this.loading = true
      try {
        const res = await this.$userService.forgotPassword({...this.form})
        this.successMessage = 'Please, check your email. We sent you the instructions to reset your password'
        this.$set(this.form, 'email', '')
      } catch (e) {
        this.errorMessage = e.message
      } finally {
        this.loading = false
      }
    }
  },
};