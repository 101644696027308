<script>
import { Bar } from "vue-chartjs";
export default {
  extends: Bar,
  props: {
    chartData: {
      type: Object,
    },
  },
  watch: {
    chartData() {
      this.setupChart();
    },
  },
  mounted() {
    this.setupChart();
  },

  methods: {
    setupChart() {
      this.renderChart(
        {
          labels: this.chartData.labels,
          datasets: [
            {
              label: "",
              data: this.chartData.values,
              backgroundColor: this.chartData.bgColors,
              barThickness: 10,
              // borderWidth: 2,
              barRadius: 20,
              // borderSkipped: false,
            },
          ],
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            titleFontColor: "white",
            mode: "label",
          },
          legend: {
            display: false,
          },
          scales: {
            scaleLabel: {
              display: false,
              labelString: "",
              // lineHeight?: number | string | undefined;
              // fontColor?: ChartColor | undefined;
              // fontFamily?: string | undefined;
              // fontSize?: number | undefined;
              // fontStyle?: string | undefined;
              // padding?: ChartLayoutPaddingObject | number | undefined;
            },
            xAxes: [
              {
                gridLines: {
                  display: false,
                  z: 2,
                },
                ticks: {
                  maxTicksLimit: 1000,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  // maxTicksLimit: 500,
                  // z: 2,
                },
                // barThickness: 10,
              },
            ],
          },
        }
      );
    },
  },
};
</script>
