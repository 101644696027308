import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Account from '@/views/Account/Account.vue'
import Agenda from '@/views/Agenda/Agenda.vue'
import EditAvailability from '@/views/Agenda/Availability/EditAvailability/EditAvailability.vue'
import Appointment from '@/views/Agenda/Appointment/Appointment.vue'
import Audience from '@/views/Audience/Audience.vue'
import ForgotPassword from '@/views/ForgotPassword/ForgotPassword.vue'
import PasswordRecovery from '@/views/PasswordRecovery/PasswordRecovery.vue'
import Meeting from '@/views/Meeting/Meeting'
import NotFound from '@/views/NotFound/NotFound.vue'
import Notifications from '@/views/Notifications/Notifications.vue'
import Onboarding from '@/views/Onboarding/Onboarding.vue'
import Overview from '@/views/Overview/Overview.vue'
import Profile from '@/views/Profile/Profile.vue'
import Signin from '@/views/Signin/Signin.vue'
import SignupAccount from '@/views/Signup/SignupAccount/SignupAccount.vue'
import SignupConfirmation from '@/views/Signup/SignupConfirmation/SignupConfirmation.vue'
import AccountActivation from '@/views/Signup/AccountActivation/AccountActivation.vue'
import SignupInfo from '@/views/Signup/SignupInfo/SignupInfo.vue'
import Social from '@/views/Social/Social.vue'
import StudentDetail from '@/views/StudentDetail/StudentDetail.vue'
import AddMember from '@/views/Team/AddMember/AddMember.vue'
import MemberDetail from '@/views/Team/MemberDetail/MemberDetail.vue'
import Team from '@/views/Team/Team.vue'
import AddBrochure from '@/views/University/Brochures/AddBrochure/AddBrochure.vue'
import BrochureDetail from '@/views/University/Brochures/BrochureDetail/BrochureDetail.vue'
import AddPhoto from '@/views/University/Photos/AddPhoto/AddPhoto.vue'
import AddScholarship from '@/views/University/Scholarships/AddScholarship/AddScholarship.vue'
import Messages from '@/views/Messages/Messages.vue'
import Chat from '@/views/Messages/Chat/Chat.vue'
import ScholarshipDetail from '@/views/University/Scholarships/ScholarshipDetail/ScholarshipDetail.vue'
import University from '@/views/University/University.vue'
import AddVideo from '@/views/University/Videos/AddVideo/AddVideo.vue'
import AddCredits from '@/views/Wallet/AddCredits/AddCredits.vue'
import Wallet from '@/views/Wallet/Wallet.vue'
import paths from './paths'

Vue.use(VueRouter)

function isLogged() {
  return store.state.userSession ? true : false
}
function registrationComplete() {
  return store.state.userSession.isProfileCompleted
}

function getRedirectionPath() {
  if (!isLogged()) {
    return paths.signin
  }
  if (!registrationComplete()) {
    return paths.onboarding
  }
  return false
}

function authCheck(to, from, next) {
  const path = getRedirectionPath()
  if (!path) {
    next()
  } else {
    if (to.path != path) {
      next(path)
    } else {
      next()
    }
  }
}

const routes = [
  {
    path: '/',
    redirect: {
      name: 'Home'
    }
  },
  {
    path: paths.home,
    name: 'Home',
    component: Overview,
    props: true,
    beforeEnter: authCheck
  },
  {
    path: `${paths.meeting}/:id`,
    name: 'Meeting',
    component: Meeting,
    props: true,
    beforeEnter: authCheck
  },
  {
    path: paths.signin,
    name: 'Signin',
    component: Signin,
    props: true,
  },
  // {
  //   path: paths.signup.info,
  //   name: 'SignupInfo',
  //   component: SignupInfo,
  //   props: true
  // },
  // {
  //   path: paths.signup.account,
  //   name: 'SignupAccount',
  //   component: SignupAccount,
  //   props: true
  // },
  {
    path: paths.audience,
    name: 'Audience',
    component: Audience,
    props: true,
    beforeEnter: authCheck
  },
  {
    path: paths.university.list,
    name: 'University',
    component: University,
    props: true,
    beforeEnter: authCheck
  },
  {
    path: paths.university.scholarship.add,
    name: 'AddScholarship',
    component: AddScholarship,
    props: true,
    beforeEnter: authCheck
  },
  {
    path: `${paths.university.scholarship.detail}/:id`,
    name: 'ScholarshipDetail',
    component: ScholarshipDetail,
    props: true,
    beforeEnter: authCheck
  },
  {
    path: paths.university.brochure.add,
    name: 'AddBrochure',
    component: AddBrochure,
    props: true,
    beforeEnter: authCheck
  },
  {
    path: `${paths.university.brochure.detail}/:id`,
    name: 'BrochureDetail',
    component: BrochureDetail,
    props: true,
    beforeEnter: authCheck
  },
  {
    path: paths.social,
    name: 'Social',
    component: Social,
    props: true,
    beforeEnter: authCheck
  },
  {
    path: paths.wallet.list,
    name: 'Wallet',
    component: Wallet,
    props: true,
    beforeEnter: authCheck
  },
  {
    path: paths.wallet.add_credits,
    name: 'AddCredits',
    component: AddCredits,
    props: true,
    beforeEnter: authCheck
  },
  {
    path: `${paths.students.detail}/:id`,
    name: 'StudentDetail',
    component: StudentDetail,
    props: true,
    beforeEnter: authCheck
  },
  {
    path: paths.signup.confirmation,
    name: 'SignupConfirmation',
    component: SignupConfirmation,
    props: true,
    // beforeEnter: authCheck
  },
  {
    path:  `${paths.signup.accountActivation}/:token`,
    name: 'AccountActivation',
    component: AccountActivation,
    props: true,
    // beforeEnter: authCheck
  },
  {
    path: paths.forgotPassword,
    name: 'ForgotPassword',
    component: ForgotPassword,
    props: true,
  },
  {
    path: `${paths.passwordRecovery}/:token`,
    name: 'PasswordRecovery',
    component: PasswordRecovery,
    props: true,
  },
  {
    path: paths.onboarding,
    name: 'Onboarding',
    component: Onboarding,
    props: true,
    beforeEnter: authCheck
  },
  {
    path: paths.notifications,
    name: 'Notifications',
    component: Notifications,
    props: true,
    beforeEnter: authCheck
  },
  {
    path: paths.account,
    name: 'Account',
    component: Account,
    props: true,
    beforeEnter: authCheck
  },
  // {
  //   path: paths.team.list,
  //   name: 'Team',
  //   component: Team,
  //   props: true,
  //   beforeEnter: authCheck,
  // },
  // {
  //   path: `${paths.team.detail}/:id`,
  //   name: 'MemberDetail',
  //   component: MemberDetail,
  //   props: true,
  //   beforeEnter: authCheck
  // },
  // {
  //   path: paths.team.add,
  //   name: 'AddMember',
  //   component: AddMember,
  //   props: true,
  //   beforeEnter: authCheck
  // },
  {
    path: paths.profile,
    name: 'Profile',
    component: Profile,
    props: true,
    beforeEnter: authCheck
  },
  {
    path: paths.university.addVideo,
    name: 'AddVideo',
    component: AddVideo,
    props: true,
    beforeEnter: authCheck
  },
  {
    path: paths.university.addPhoto,
    name: 'AddPhoto',
    component: AddPhoto,
    props: true,
    beforeEnter: authCheck
  },
  {
    path: paths.agenda.list,
    name: 'Agenda',
    component: Agenda,
    props: true,
    beforeEnter: authCheck
  },
  {
    path: paths.agenda.edit,
    name: 'EditAvailability',
    component: EditAvailability,
    props: true,
    beforeEnter: authCheck
  },
  {
    path: `${paths.agenda.appointment_detail}/:id`,
    name: 'Appointment',
    component: Appointment,
    props: true,
    beforeEnter: authCheck
  },
  {
    path: paths.messages.list,
    name: 'Messages',
    component: Messages,
    props: true,
    beforeEnter: authCheck
  },
  {
    path: `${paths.messages.chat}/:id`,
    name: 'Chat',
    component: Chat,
    props: true,
    beforeEnter: authCheck
  },
  {
    path: `${paths.legal}/:type`,
    name: 'Chat',
    component: Chat,
    props: true,
    beforeEnter: authCheck
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  paths
})

export default router
