<template>
  <div>
    <div>
      <label class="form-label text-muted mb-0">{{ label }}</label>
      <div class="input-group input-group-merge">
        <div
          v-if="!show"
          class="
            input-group-text
            border-right-0
            border-top-right-radius-0
            border-bottom-right-radius-0
            border-0
            mt-1
          "
          id="inputGroup"
        >
          <span class="fas fa-lock"></span>
        </div>
        <div
          v-if="typeof value == 'object' && show"
          class="
            border-top-left-radius-0 border-bottom-left-radius-0 border-left-0
            ml-0 pl-0 ml-0 mt-1
            text-muted
            border-0
          ">
          <span 
            v-for="(option, index) in value"
            :key="index"
            class="`{ index == 0 ? 'pl-0 me-1 ml-0 m-1' : 'p-3 m-1'}` mb-2 rounded bg-input span-option"
          >
            {{option}}
          </span>
        </div>
        <input
          v-if="!show"
          disabled
          class="
            form-control
            border-top-left-radius-0 border-bottom-left-radius-0 border-left-0
            text-muted
            border-0
            mt-1
          "
          type="text"
          value="To see content, you need to accept"
          aria-label="Input group appended"
          aria-describedby="inputGroup"
        />
      </div>
    </div>
    <ReadOnlyInput v-if="!value || value.length == 0 && show" class="mt-0" :value="'Not informed'" />
  </div>
</template>

<script>
import ReadOnlyInput from "./ReadOnlyInput.vue";
export default {
  name: "PrivateExams",
  props: {
    label: String,
    show: Boolean,
    value: Array,
  },
  components: { ReadOnlyInput },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
input,
.input-group-text {
  background-color: #ececec;
}
.bg-input {
  background-color: #ececec;
}
.span-option {
  padding: 0.5rem 0.75rem;
  display: inline-block;
}
</style>
