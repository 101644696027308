import moment from "moment";

export default {
  name: "AppointmentsCalendar",
  props: {
    enableDaySelection: false,
    mainTitle: null,
    data: {
      date: null,
      appointments: []
    },
    showAppointments: Boolean,
  },
  watch: {
    data: function (newVal, oldVal) {
      this.setupDate(newVal.appointments, newVal.date)
    }
  },
  mounted() {
    this.setupDate()
  },
  data() {
    return {
      selectedDate: '',
      selectedMonth: moment(),
      weekDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      weeks: [[], [], [], [], []]

    }
  },
  methods: {
    setupDate(appointments = [], date = null) {
      // console.log('appt', this.appointments)
      if (!date) {
        this.selectedMonth = moment()
      } else {
        this.selectedMonth = moment(date)
      }
      const startOfMonth = this.selectedMonth.clone().startOf('month')
      const startOfWeek = startOfMonth.clone().startOf('week')

      var currDay = startOfWeek

      const today = moment().format('YYYY-MM-DD')

      var newWeek = [[], [], [], [], [], []]
      for (let i = 0; i < 6; i++) { // semanas
        for (let j = 0; j <= 6; j++) { // dias
          const formattedDate = currDay.format('YYYY-MM-DD')
          const hasAppointment = this.checkIfHasAppointment(formattedDate)
          const isCurrentMonth = this.selectedMonth.format('YYYY-MM') == currDay.format('YYYY-MM')
          newWeek[i].push({
            date: currDay,
            formattedDate,
            hasAppointment: hasAppointment,
            appointmentActive: today == formattedDate || currDay.isAfter(),
            day: currDay.format('D'),
            isToday: today == formattedDate,
            isCurrentMonth: isCurrentMonth
          })
          currDay.add(1, 'days')
        }
      }
      this.$set(this, 'weeks', newWeek)

    },

    dateSelected(day) {
      if (this.enableDaySelection && day.isCurrentMonth) {
        this.selectedDate = day.formattedDate
        this.$emit("calendarDayChanged", this.$helper.pad(day.day));
      }
      // this.setupDate(day.date)
    },
    checkIfHasAppointment(day) {
      return this.data.appointments.some(r => r.dateTime.date == day)
    }
  },

  components: {
  }
};

