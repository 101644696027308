var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card border-2 position-fixed me-5 calendar-height"},[_c('div',{staticClass:"row p-4"},[_c('div',{staticClass:"col-12"},[_c('h2',{},[_c('strong',[_vm._v(_vm._s(_vm.mainTitle || "Appointments Today"))])])]),_c('div',{staticClass:"col-12 mt-4"},[_c('div',{staticClass:"row"},_vm._l((_vm.weekDays),function(weekDay,index){return _c('div',{key:'week-days' + index,staticClass:"col justify-content-center p-0 d-flex flex-column align-items-center"},[_c('p',{staticClass:"text-dark h4 text-center",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(weekDay)+" ")])])}),0),_vm._l((_vm.weeks),function(week,index){return _c('div',{key:'week-' + index,staticClass:"row my-3"},_vm._l((week),function(day,indexJ){return _c('div',{key:'day-' + indexJ,staticClass:"col p-0 d-flex justify-content-center flex-column align-items-center",class:{'cursor-pointer': _vm.enableDaySelection},on:{"click":function($event){$event.preventDefault();return _vm.dateSelected(day)}}},[_c('div',{staticClass:"align-items-center d-flex bg-primary justify-content-center number-container p-2",class:{
              'text-gray-400': !day.isCurrentMonth,
              'text-dark bg-transparent': (!day.isToday && day.formattedDate != _vm.selectedDate),
              'text-dark bg-dark-soft': (!day.isToday && day.formattedDate == _vm.selectedDate),
              'text-white bg-primary ': day.isToday,
            }},[_c('p',{staticClass:"h3 text-center m-0 opacity-75",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(day.day)+" ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pt-1 d-flex justify-content-center"},[_c('span',{staticClass:"fas fa-circle small appointment-badge",class:{
                  'text-white': !day.hasAppointment || !day.isCurrentMonth,
                  'text-primary': day.hasAppointment && day.appointmentActive,
                  'text-black-50': day.hasAppointment && !day.appointmentActive,
                }})])])])}),0)})],2),(_vm.showAppointments)?[(!_vm.data.appointments)?_c('div',{staticClass:"col-12 pt-5 text-center"},[_c('i',{staticClass:"far fa-calendar-alt fa-2x text-muted"}),_vm._m(0)]):_c('div',{staticClass:"col-12 py-3 pb-4"},[_vm._l((_vm.data.appointments
            .slice(0, 2)),function(item,index){return _c('div',{key:'appointment-' + index,staticClass:"card-body border-bottom border-2 py-3"},[_c('div',{staticClass:"list-group list-group-flush my-n3"},[_c('div',{staticClass:"list-group-item"},[_c('div',{staticClass:"row"},[_vm._m(1,true),_c('div',{staticClass:"col ms-n2"},[_c('h4',{staticClass:"h4 text-dark mb-2"},[_vm._v(" "+_vm._s(item.dateTime.start)+" to "+_vm._s(item.dateTime.end)+" ")]),_c('span',{staticClass:"mb-0"},[_vm._v("Meeting with student "+_vm._s(item.student.name))])])])])])])}),_c('div',{staticClass:"col-12 text-center mt-auto"},[_c('router-link',{staticClass:"text-primary",attrs:{"tag":"a","to":_vm.$paths.agenda.list}},[_vm._v(" See All Appointments ")])],1)],2)]:_vm._e()],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"mt-2 text-muted"},[_c('strong',[_vm._v("No Appointments Today")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-auto pe-0 ps-0"},[_c('span',{staticClass:"fa fa-2x fa-user-circle text-primary mb-0"})])}]

export { render, staticRenderFns }