import { Modal } from 'bootstrap';
import { required } from 'vuelidate/lib/validators';

export default {
    mounted() {
        this.newDateModal = new Modal(document.getElementById('newDateModal'))
        this.loadingAll = true
        this.getAppointment()
        let th = this
        this.availableMonths = Array.apply(0, Array(4)).map(function (_, i) {
            return {
                id: th.$moment().add(i, 'M').format('YYYY/MM'),
                label: th.$moment().add(i, 'M').format('MMMM/YYYY'),
            }
        })
        this.setMonth(0)
    },
    data() {
        return {
            rescheduleDate: '',
            canSuggestOtherDay: false,
            loadingAccept: false,
            loadingAll: false,
            actionDisabled: true,
            canEnterTheRoom: false,
            loading: false,
            loadingCheck: false,
            loadingNewDate: false,
            availableMonths: [],
            availableDays: [],
            availableTimes: [],
            selectedDay: 0,
            item: {},
            date: '',
            errorMessage: '',
            errorMessageDate: '',
            timeRemaining: null,
            eventLabel: {
                month: '',
                time: '',
                day: ''
            },
            form: {
                date: '',
                time: '',
                month: ''
            },
            newDateModal: null
        }
    },
    validations: {
        form: {
            day: {
                required,
            },
            time: {
                required,
            },
            month: {
                required,
            },
        }
    },
    methods: {

        async getAppointment() {
            try {
                const res = await this.$agendaService.showAppointment(this.$route.params.id)
                let it = res.data.data
                it.appointmentIsInTheFuture = this.$moment().isBefore(`${it.dateTime.date} ${it.dateTime.start}`)
                this.date = this.$moment(it.dateTime.date).format('MMMM, DD')
                this.$set(this, 'item', it)
                this.actionDisabled = !this.item.appointmentIsInTheFuture && Math.abs(this.$moment().diff(`${it.dateTime.date} ${it.dateTime.end}`, 'm')) >= 15
                this.canEnterTheRoom = !this.item.appointmentIsInTheFuture && Math.abs(this.$moment().diff(`${it.dateTime.date} ${it.dateTime.end}`, 'm')) <= 15

                if (it.lastRechedule) {
                    this.rescheduleDate = this.$moment(it.lastRechedule.dateTime.date).format('MMMM, DD')
                    if (it.lastRechedule.status == 'AWAITING_ACCEPT' && !it.lastRechedule.accept.institution) {
                        this.$set(this, 'canSuggestOtherDay', true)
                    }
                } else if (it.status == 'AWAITING_ACCEPT' && !it.accept.institution) {
                    this.$set(this, 'canSuggestOtherDay', true)
                }

            } catch (e) {
                console.log(e)
            } finally {
                this.loadingAll = false
            }
        },
        async acceptAppointment() {
            try {
                this.loadingAccept = true
                this.errorMessage = ''
                const r = await this.$agendaService.makeAppointmentDecision(this.item.lastRescheduleId, { decision: "true" })
                this.getAppointment()
            } catch (e) {
                this.errorMessage = e.message
            } finally {
                this.loadingAccept = false
            }
        },
        async cancelAppointment() {
            this.$eventBus.$emit("showConfirmationAlert", {
                message: "Are you sure you want to cancel the appointment?",
                confirmCallback: async () => {
                    try {
                        this.errorMessage = ''
                        const r = await this.$agendaService.cancelAppointment(this.item.lastRescheduleId)
                        this.$eventBus.$emit('showToast', { title: 'Appointment cancelled', msg: `Your appointment has been cancelled` })
                        this.$router.back()
                        // this.getAppointment()
                    } catch (e) {
                        this.errorMessage = e.message
                    }
                }
            })
            // if (confirm('Are you sure you want to cancel the appointment?')) {
            //     try {
            //         this.errorMessage = ''
            //         const r = await this.$agendaService.cancelAppointment(this.item.lastRescheduleId)
            //         this.$eventBus.$emit('showToast', { title: 'Appointment cancelled', msg: `Your appointment has been cancelled` })
            //         this.$router.back()
            //         // this.getAppointment()
            //     } catch (e) {
            //         this.errorMessage = e.message
            //     }
            // }
        },
        otherDateClicked() {
            this.newDateModal.toggle()
        },
        async submitNewDate() {
            try {
                this.errorMessageDate = ''
                const f = { ...this.form, decision: "false" }
                delete (f.month)
                this.loadingNewDate = true
                const res = await this.$agendaService.makeAppointmentDecision(this.item.lastRescheduleId, f)
                this.getAppointment()
                this.otherDateClicked()
                this.$eventBus.$emit('showToast', { title: 'New date suggested', msg: `Your request to change the date was sent. The student should confirm the new date` })

            } catch (e) {
                console.log(e)
                this.errorMessageDate = e.message
            } finally {
                this.loadingNewDate = false
            }
        },

        async getAvailableDays() {
            this.loading = true
            const res = await this.$agendaService.getAvailableDates(this.form.month)
            this.$set(this, 'availableDays', res.data.data)
            this.setDay(0)
            this.loading = false
        },
        setTime(i) {
            const t = this.availableDays[this.selectedDay].times[i]
            // console.log(this.selectedDay)
            this.$set(this.form, 'time', t.time)
            this.$set(this.eventLabel, 'time', t.label)
        },
        setDay(i) {
            this.selectedDay = i
            const d = this.availableDays[i]
            this.$set(this.eventLabel, 'day', this.availableDays[i].label)
            this.$set(this.form, 'date', this.availableDays[i].date)

            this.$set(this, 'availableTimes', this.availableDays[i].times)
            this.setTime(0)


        },
        setMonth(i) {
            this.selectedDay = 0
            const m = this.availableMonths[i]
            this.$set(this.eventLabel, 'month', this.availableMonths[i].label)
            this.$set(this.form, 'month', this.availableMonths[i].id)
            this.getAvailableDays()
        },

        async goToMeeting() {
            this.$router.push({ path: `${this.$paths.meeting}/${this.item._id}` })
        }
    },
    components: {
    },
}