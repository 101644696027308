<template>
  <div class="requeststofollow">
    <span>requests</span>
  </div>
</template>
<script>
import RequestsToFollowController from "./RequestsToFollowController";
export default RequestsToFollowController;
</script>
<style scoped>
</style>
