import NetworkService from '@/services/NetworkService';

export default class TeamService {
    constructor() {
        this.network = new NetworkService()
    }
    getMembers() {
        return this.network.get(this.network.endpoints.Team.members)
    }
    showMember(id) {
        const url = `${this.network.endpoints.Team.members}/${id}`
        return this.network.get(url)
    }
    createMember(params) {
        const url = `${this.network.endpoints.Team.members}`
        return this.network.post(url, params)
    }
    updateMember(id, params) {
        const url = `${this.network.endpoints.Team.members}/${id}`
        return this.network.put(url, params)
    }

}
