<template>
  <div>
    <Sidebar />
    <NavBar current="Messages" />
    <LoadingContainer v-if="loadingAll" />
    <Container col="col-12" v-else>
      <div class="header mt-md-5">
        <div class="header-body border-0">
          <div class="row align-items-center">
            <div class="col">
              <h1 class="header-title">Messages</h1>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col">
              <ul class="nav nav-tabs header-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    @click.prevent="selectedTab = 0"
                    class="nav-link active"
                    id="members-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#members"
                    type="button"
                    role="tab"
                    aria-controls="members"
                    aria-selected="true"
                  >
                    All Messages
                    <span
                      class="ms-auto badge"
                      :class="{
                        'bg-primary-soft': selectedTab == 0,
                        'bg-secondary-soft': selectedTab != 0,
                      }"
                      >{{ items.length }}</span
                    >
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="search-table-form">
        <div class="row align-items-center">
          <div class="col">
            <form>
              <div
                class="input-group input-group-flush input-group-merge input-group-reverse"
              >
                <input
                  class="form-control list-search"
                  type="search"
                  @input="searchString"
                  placeholder="Search"
                />
                <span class="input-group-text">
                  <i class="fas fa-search ps-3"></i>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- REQUESTS  -->
      <div class="tab-content" id="myTabContent">
        <div
          class="table-responsive mt-5 tab-pane fade show active"
          id="requests"
          role="tabpanel"
          aria-labelledby="requests-tab"
        >
          <EmptyState
            title="No Messages"
            msg="It looks like you have no messages"
            v-if="items.length == 0"
          >
            <template v-slot:icon>
              <i class="fas fa-comments fa-3x text-primary"></i>
            </template>
          </EmptyState>
          <table
            v-if="items.length > 0"
            class="table table-sm table-hover table-nowrap card-table bg-white"
          >
            <thead>
              <tr>
                <th width="1%"></th>
                <th
                  v-for="(column, index) in columns"
                  :key="column"
                  :class="{ 'text-start': index == 0 }"
                  :width="index != 0 ? '1%' : 'auto'"
                >
                  {{ column }}
                </th>
                <th width="1%"></th>
              </tr>
            </thead>
            <tbody class="list">
              <tr
                v-for="item in showingItems"
                v-bind:key="item.id"
                class="cursor-pointer"
              >
                <td @click="itemPressed(item)" class="text-start">
                  <ImagePlaceholder v-if="item.student.image" :src="item.student.image">
                    <template v-slot:placeholder>
                      {{ item.studentName.charAt(0) }}
                    </template>
                  </ImagePlaceholder>
                  <div
                    v-else
                    class="avatar-placeholder rounded-circle d-flex justify-content-center align-items-center"
                  >
                    <h3 class="text-white mb-0">
                      {{ item.studentName.charAt(0) }}
                    </h3>
                  </div>
                </td>
                <td @click="itemPressed(item)" class="text-start">
                  <strong>{{ item.studentName }}</strong>
                </td>
                <td @click="itemPressed(item)" class="strong">
                  <span v-if="item.lastMessage.length > 0"
                    ><span
                      :class="{
                        'fw-bold text-black':
                          !item.lastMessageIsMine &&
                          item.lastMessage &&
                          item.lastMessage.length &&
                          !item.lastMessage[0].viewedAt,
                      }"
                    >
                      {{ $helper.limitString(item.lastMessage[0].message) }}
                    </span>

                    <span
                      v-if="item.lastMessageIsMine && !item.lastMessage[0].viewedAt"
                      class="text-blue-link"
                    >
                      <small class="fas fa-check"></small>
                      <small
                        style="left: -9px; position: relative; right: 0px; top: 1px"
                        class="fas fa-check"
                      ></small>
                    </span>
                  </span>
                </td>
                <td @click="itemPressed(item)">
                  <span class="ms-auto badge bg-primary-soft"
                    >{{ item.match || 0 }}%</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import MessagesController from "./MessagesController";
export default MessagesController;
</script>

<style></style>
