<template>
  <nav
    class="
      navbar navbar-vertical
      fixed-start
      navbar-expand-md navbar-light
      border-right
    "
    id="sidebar"
  >
    <div class="container-fluid">
      <!-- Toggler -->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebarCollapse"
        aria-controls="sidebarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- User (xs) -->
      <div class="navbar-user d-md-none">
        <!-- Dropdown -->
        <div class="dropdown">
          <!-- Toggle -->
          <a
            href="#"
            id="sidebarIcon"
            class="dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="avatar avatar-sm avatar-online">
              <!-- <img
                src="./assets/img/avatars/profiles/avatar-1.jpg"
                class="avatar-img rounded-circle"
                alt="..."
              /> -->
            </div>
          </a>

          <!-- Menu -->
          <div
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="sidebarIcon"
          >
            <a href="./profile-posts.html" class="dropdown-item">Profile</a>
            <a href="./account-general.html" class="dropdown-item">Settings</a>
            <hr class="dropdown-divider" />
            <a href="./sign-in.html" class="dropdown-item">Logout</a>
          </div>
        </div>
      </div>

      <!-- Collapse -->
      <div class="collapse navbar-collapse" id="sidebarCollapse">
        <!-- Form -->
        <form class="mt-4 mb-3 d-md-none">
          <div
            class="
              input-group
              input-group-rounded
              input-group-merge
              input-group-reverse
            "
          >
            <input
              class="form-control"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <div class="input-group-text">
              <span class="fe fe-search"></span>
            </div>
          </div>
        </form>

        <!-- Navigation -->
        <ul class="navbar-nav px-3">
          <router-link
            active-class="router-link-active"
            :to="$paths.home"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link">
              <i class="sidebar-icon fas fa-chart-bar"></i> Dashboard
            </a>
          </router-link>
          <router-link
            active-class="router-link-active"
            :to="$paths.agenda.list"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link">
              <i class="sidebar-icon fas fa-calendar-day"></i> Diary
              <!-- <span class="ms-auto badge bg-secondary-soft">23</span> -->
            </a>
          </router-link>
          <router-link
            active-class="router-link-active"
            :to="$paths.social"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link">
              <i class="sidebar-icon fas fa-file"></i> Community
              <!-- <span class="ms-auto badge bg-secondary-soft">145</span> -->
            </a>
          </router-link>
        </ul>

        <!-- Divider -->
        <hr class="navbar-divider my-3" />

        <!-- Heading -->
        <h6 class="navbar-heading px-3">University</h6>

        <!-- Navigation -->
        <ul class="navbar-nav px-3">
          <router-link
            active-class="router-link-active"
            :to="$paths.profile"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link">
              <i class="sidebar-icon fas fa-user"></i> Profile
            </a>
          </router-link>
          <router-link
            active-class="router-link-active"
            :to="$paths.messages.list"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link">
              <i class="sidebar-icon fas fa-comments"></i> Messages
            </a>
          </router-link>
          <router-link
            active-class="router-link-active"
            :to="$paths.audience"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link">
              <i class="sidebar-icon fas fa-users"></i> Audience
              <!-- <span class="ms-auto badge bg-secondary-soft">07</span> -->
            </a>
          </router-link>
          <router-link
            active-class="router-link-active"
            :to="$paths.university.list"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link">
              <i class="sidebar-icon fas fa-university"></i> Resources
            </a>
          </router-link>
        </ul>

        <!-- Divider -->
        <hr class="navbar-divider my-3" />

        <!-- Heading -->
        <h6 class="navbar-heading px-3">Other</h6>

        <!-- Navigation -->
        <ul class="navbar-nav px-3">
          <!-- <router-link
            active-class="router-link-active"
            :to="$paths.team.list"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link">
              <i class="sidebar-icon fas fa-users-cog"></i> Team
            </a>
          </router-link> -->
          <router-link
            active-class="router-link-active"
            :to="$paths.account"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link">
              <i class="sidebar-icon fas fa-sliders-h"></i> Account
            </a>
          </router-link>
        </ul>
      </div>
      <!-- / .navbar-collapse -->
    </div>
  </nav>
</template>

<script>

export default {
  data() {
    return {
      
    };
  },
};
</script>

<style scoped>
.navbar {
  padding-top: 100px;
}
.navbar-heading {
  text-align: left;
  font-weight: 700;
}
.sidebar-icon {
  width: 25px;
  text-align: left;
}
.nav-link {
  padding: 0.7rem 1.5rem;
}
.navbar-vertical.navbar-expand-md {
  padding-right: 1em;
  padding-left: 1em;
}
</style>