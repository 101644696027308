<template>
  <div>
    <Sidebar />
    <NavBar current="Notifications" />
    <LoadingContainer v-if="loadingAll" />
    <Container col="col-12" v-else>
     
      <div class="row pt-5">
        <div class="col-12 col-md-8">
          <h1 class="header-title mb-3">Notifications</h1>
           <EmptyState
        title="No Notifications"
        msg="It looks like you have no notifications"
        v-if="items.length == 0"
      >
        <template v-slot:icon>
          <i class="fas fa-bell fa-3x text-primary"></i>
        </template>
      </EmptyState>
          <ul class="list-group list-group-flush">
            <li
              v-for="(item, index) in filteredItems"
              v-bind:key="'not-' + index"
              class="list-group-item"
            >
              <i
                class="fas text-primary me-2"
                :class="[$userService.notificationIcons[item.type]]"
              ></i>
              <strong>{{ item.title }}</strong>
              <p>
                {{ item.message }}
              </p>
            </li>
          </ul>
        </div>
        <div class="col-12 col-md-4">
          <div class="card card-filters-notifications p-4">
            <h2 class="h2">Filters</h2>

            <div class="row mb-3">
              <div class="col">
                <a
                  class="cursor-pointer"
                  @click.prevent="
                    postComments = !postComments;
                    filterChanged();
                  "
                >
                  <i v-if="!postComments" class="fas fa-square me-2 text-light"></i>
                  <i
                    v-if="postComments"
                    class="fas fa-check-square text-primary me-2"
                  ></i>
                  <strong class="text-dark">Post comments</strong>
                </a>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col">
                <a
                  class="cursor-pointer"
                  @click.prevent="
                    followerRequest = !followerRequest;
                    filterChanged();
                  "
                >
                  <i v-if="!followerRequest" class="fas fa-square me-2 text-light"></i>
                  <i
                    v-if="followerRequest"
                    class="fas fa-check-square text-primary me-2"
                  ></i>
                  <strong class="text-dark">Follower request</strong>
                </a>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col">
                <a
                  class="cursor-pointer"
                  @click.prevent="
                    appointmentRequest = !appointmentRequest;
                    filterChanged();
                  "
                >
                  <i v-if="!appointmentRequest" class="fas fa-square me-2 text-light"></i>
                  <i
                    v-if="appointmentRequest"
                    class="fas fa-check-square text-primary me-2"
                  ></i>
                  <strong class="text-dark">Appointment request</strong>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import NotificationsController from "./NotificationsController";
export default NotificationsController;
</script>

<style scoped>
@import "./Notifications.scss";
</style>
