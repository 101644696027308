<template>
  <div class="appointments">
    <span>Appiintments</span>
  </div>
</template>
<script>
import AppointmentsController from "./AppointmentsController";
export default AppointmentsController;
</script>
<style scoped>
</style>
