<template>
  <div>
    <span class="text-white h3">{{title}} {{ $helper.pad(minutes) }}:{{ $helper.pad(seconds) }}</span>
  </div>
</template>
<script>
import TimeCounterController from "./TimeCounterController";
export default TimeCounterController;
</script>
<style scoped></style>
