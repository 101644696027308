var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"audience"},[_c('Sidebar'),_c('NavBar',{attrs:{"current":"Resources"}}),(_vm.loadingAll)?_c('LoadingContainer'):_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"header mt-md-5"},[_c('div',{staticClass:"header-body border-0"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[_c('h1',{staticClass:"header-title"},[_vm._v("Resources")])]),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"btn-group",attrs:{"role":"group","aria-label":"Button group with nested dropdown"}},[_c('div',{staticClass:"btn-group",attrs:{"role":"group"}},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":_vm.actionNames[_vm.selectedTab].path,"tag":"button"}},[_c('i',{staticClass:"me-2 fas fa-plus-circle"}),_vm._v(" "+_vm._s(_vm.actionNames[_vm.selectedTab].name)+" ")])],1)])])]),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[_c('ul',{staticClass:"nav nav-tabs header-tabs",attrs:{"id":"myTab","role":"tablist"}},[_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('a',{staticClass:"nav-link",class:{'active': _vm.selectedTab == 0},attrs:{"id":"scholarships-tab","data-bs-toggle":"tab","data-bs-target":"#scholarships","type":"button","role":"tab","aria-controls":"scholarships","aria-selected":"true"},on:{"click":function($event){$event.preventDefault();return _vm.handleSelectTab(0)}}},[_vm._v(" Scholarships "),_c('span',{staticClass:"ms-auto badge",class:{
                            'bg-primary-soft': _vm.selectedTab == 0,
                            'bg-secondary-soft': _vm.selectedTab != 0,
                          }},[_vm._v(_vm._s(_vm.scholarships.length))])])]),_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('a',{staticClass:"nav-link",class:{'active': _vm.selectedTab == 1},attrs:{"id":"brochures-tab","data-bs-toggle":"tab","data-bs-target":"#brochures","type":"button","role":"tab","aria-controls":"brochures","aria-selected":"false"},on:{"click":function($event){$event.preventDefault();return _vm.handleSelectTab(1)}}},[_vm._v(" Brochures "),_c('span',{staticClass:"ms-auto badge",class:{
                            'bg-primary-soft': _vm.selectedTab == 1,
                            'bg-secondary-soft': _vm.selectedTab != 1,
                          }},[_vm._v(_vm._s(_vm.brochures.length))])])]),_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('a',{staticClass:"nav-link",class:{'active': _vm.selectedTab == 2},attrs:{"id":"photos-tab","data-bs-toggle":"tab","data-bs-target":"#photos","type":"button","role":"tab","aria-controls":"photos","aria-selected":"false"},on:{"click":function($event){$event.preventDefault();return _vm.handleSelectTab(2)}}},[_vm._v(" Photos "),_c('span',{staticClass:"ms-auto badge",class:{
                            'bg-primary-soft': _vm.selectedTab == 2,
                            'bg-secondary-soft': _vm.selectedTab != 2,
                          }},[_vm._v(_vm._s(_vm.photos.reduce(function (a,b) { return a + b.photos.length; }, 0)))])])]),_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('a',{staticClass:"nav-link",class:{'active': _vm.selectedTab == 3},attrs:{"id":"videos-tab","data-bs-toggle":"tab","data-bs-target":"#videos","type":"button","role":"tab","aria-controls":"videos","aria-selected":"false"},on:{"click":function($event){$event.preventDefault();return _vm.handleSelectTab(3)}}},[_vm._v(" Videos "),_c('span',{staticClass:"ms-auto badge",class:{
                            'bg-primary-soft': _vm.selectedTab == 3,
                            'bg-secondary-soft': _vm.selectedTab != 3,
                          }},[_vm._v(_vm._s(_vm.videos.length))])])])])])])])]),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"table-responsive mt-5 tab-pane fade",class:{'show active': _vm.selectedTab == 0},attrs:{"id":"scholarships","role":"tabpanel","aria-labelledby":"scholarships-tab"}},[_c('Scholarships',{attrs:{"items":_vm.scholarships}})],1),_c('div',{staticClass:"table-responsive mt-5 tab-pane fade",class:{'show active': _vm.selectedTab == 1},attrs:{"id":"brochures","role":"tabpanel","aria-labelledby":"brochures-tab"}},[_c('Brochures',{attrs:{"items":_vm.brochures}})],1),_c('div',{staticClass:"table-responsive mt-5 tab-pane fade",class:{'show active': _vm.selectedTab == 2},attrs:{"id":"photos","role":"tabpanel","aria-labelledby":"photos-tab"}},[_c('Photos',{attrs:{"items":_vm.photos}})],1),_c('div',{staticClass:"table-responsive mt-5 tab-pane fade",class:{'show active': _vm.selectedTab == 3},attrs:{"id":"videos","role":"tabpanel","aria-labelledby":"videos-tab"}},[_c('Videos',{attrs:{"items":_vm.videos}})],1)])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }