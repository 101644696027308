<template>
  <div
    class="modal fade"
    id="walletModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h2 class="h2"></h2>

          <span
            @click="hideModal()"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            class="fas fa-times text-muted"
          ></span>
        </div>
        <div class="modal-body text-center">
          <i class="fas fa-coins fa-3x text-primary"></i>
          <h2 class="h2 mt-4 mb-2">Your wallet is empty</h2>
          <span class="text-muted">You must add credit to accept new followers</span>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" @click="goToAddCredits" class="btn btn-primary">
            Add credits
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
export default {
  mounted() {
    this.walletModal = new Modal(document.getElementById("walletModal"));
    this.$eventBus.$on("toggleWalletModal", () => {
      this.walletModal.show();
    });
  },
  created() {},
  data() {
    return {
      walletModal: null,
    };
  },
  methods: {
    showModal() {
      this.walletModal.show();
    },
    hideModal() {
      this.walletModal.hide();
    },
    goToAddCredits() {
      this.walletModal.hide();
      this.$router.push({ path: this.$paths.wallet.add_credits });
    },
  },
};
</script>
