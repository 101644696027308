<template>
  <div>
    <Sidebar />
    <NavBar current="Team" />
    <LoadingContainer v-if="loadingAll"  />
    <Container col="col-12" v-else>
      <div class="header mt-md-5">
        <div class="header-body border-0">
          <div class="row align-items-center">
            <div class="col">
              <h1 class="header-title">Team</h1>
            </div>
            <div class="col-auto">
              <button class="btn btn-primary" @click="handleAdd">
                Add Member <i class="ms-2 fas fa-plus-circle"></i>
              </button>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col">
              <ul class="nav nav-tabs header-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    @click.prevent="selectedTab = 0"
                    class="nav-link active"
                    id="members-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#members"
                    type="button"
                    role="tab"
                    aria-controls="members"
                    aria-selected="true"
                  >
                    All Members
                    <span
                      class="ms-auto badge"
                      :class="{
                        'bg-primary-soft': selectedTab == 0,
                        'bg-secondary-soft': selectedTab != 0,
                      }"
                      >{{ items.length }}</span
                    >
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="search-table-form">
        <div class="row align-items-center">
          <div class="col">
            <form>
              <div
                class="input-group input-group-flush input-group-merge input-group-reverse"
              >
                <input
                  class="form-control list-search"
                  type="search"
                  @input="searchString"
                  placeholder="Search"
                />
                <span class="input-group-text">
                  <i class="fas fa-search ps-3"></i>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- REQUESTS  -->
      <div class="tab-content" id="myTabContent">
        <div
          class="table-responsive mt-5 tab-pane fade show active"
          id="requests"
          role="tabpanel"
          aria-labelledby="requests-tab"
        >
          <EmptyState
            title="No Member"
            msg="It looks like you have no members"
            v-if="items.length == 0"
          >
            <template v-slot:icon>
              <i class="fas fa-users fa-3x text-primary"></i>
            </template>
          </EmptyState>
          <table
            v-if="items.length > 0"
            class="table table-sm table-hover table-nowrap card-table bg-white"
          >
            <thead>
              <tr>
                <!-- <th width="1%">
                  <a class="form-check ps-0" @click.prevent="toggleSelectAll()">
                    <i v-if="!allSelected" class="fas fa-square text-light"></i>
                    <i v-if="allSelected" class="fas fa-check-square text-primary"></i>
                  </a>
                </th> -->
                <th></th>
                <th
                  v-for="(column, index) in columns"
                  :key="column"
                  :class="{ 'text-start': index == 0 }"
                >
                  {{ column }}
                </th>
              </tr>
            </thead>
            <tbody class="list">
              <tr v-for="item in showingItems" :key="item.id" class="cursor-pointer">
                <!-- <td>
                  <a class="form-check ps-0" @click.prevent="toggleSelectItem(item)">
                    <i v-if="!item.selected" class="fas fa-square text-light"></i>
                    <i v-if="item.selected" class="fas fa-check-square text-primary"></i>
                  </a>
                </td> -->
                <td width="1%">
                  <div v-if="item.image" class="avatar avatar-xs align-middle">
                    <img class="avatar-img rounded-circle" :src="item.image" alt="..." />
                  </div>
                  <div
                    v-if="!item.image"
                    class="avatar-placeholder rounded-circle d-flex justify-content-center align-items-center"
                  >
                    <h3 class="text-white mb-0">{{ item.name.charAt(0) }}</h3>
                  </div>
                </td>
                <td @click="itemPressed(item)" class="not-first">
                  <strong>{{ item.name }}</strong>
                </td>
                <td width="1%" @click="itemPressed(item)">
                  {{ item.email }}
                </td>
                <td width="1%" @click="itemPressed(item)">
                  {{ item.locationsString }}
                </td>
                <td width="1%" @click="itemPressed(item)">
                  {{ item.created }}
                </td>
                <td width="1%" @click="itemPressed(item)">
                  {{ item.role }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Container>

    <div
      class="list-alert alert alert-dark alert-dismissible border fade pe-2"
      :class="{ show: items.filter((r) => r.selected).length }"
      role="alert"
    >
      <div class="row align-items-center">
        <div class="col">
          <div class="">
            <i class="fas fa-check-square text-primary me-2"></i>
            <label class="form-check-label text-white" for="listAlertCheckbox">
              <span class="list-alert-count">{{
                items.filter((r) => r.selected).length
              }}</span>
              selected
            </label>
          </div>
        </div>
        <div class="col-auto me-n3">
          <button
            class="btn btn-sm btn-white-20 bg-transparent pe-4"
            @click="clearSelected()"
          >
            <i class="fas fa-trash"></i> <small>Remove All</small>
          </button>
          <button class="btn btn-sm btn-primary">
            {{ items.filter((r) => r.selected).reduce((a, b) => a + b.credit, 0) }}
            Credits to accept all
          </button>
        </div>
        <div class="col-auto">
          <button @click="clearSelected()" type="button" class="btn" aria-label="Close">
            <i class="fas fa-times text-white"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeamController from "./TeamController";
export default TeamController;
</script>

<style></style>
