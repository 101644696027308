<template>
  <div class="photos">
    <EmptyState
      title="No Photos"
      msg="It looks like you have no photos"
      v-if="items.length == 0"
    >
      <template v-slot:button>
        <router-link
          tag="button"
          :to="$paths.university.addPhoto"
          type="button"
          class="btn btn-primary"
        >
          <i class="fas fa-plus-circle"></i>
          Add Photo
        </router-link>
      </template>
    </EmptyState>
    <div v-if="items.length > 0">
      <div v-for="(item, indexAlbum) in items" :key="indexAlbum" class="col-12">
        <h2>
          <!-- {{editingAlbums[`alb-${indexAlbum}`] ? 'true' : 'false'}} -->
          <div v-if="!editingAlbums[`alb-${indexAlbum}`]">
            <span>{{ item.title }}</span>
            <button @click="editAlbumClicked(indexAlbum)" class="btn btn-link ps-1">
              <span class="fas fa-pen"></span>
            </button>
          </div>
          <div v-else>
            <input
              :id="`alb-${indexAlbum}`"
              style="width: 250px;"
              class="form-control"
              v-on:blur="handleBlur(indexAlbum)"
              v-model.trim="editingAlbumTexts[`alb-${indexAlbum}`]"
              type="text"
            />
          </div>
        </h2>
        <div class="row px-0">
          <div
            class="col-3"
            v-for="(ph, index) in item.photos"
            :key="index"
            :ref="`photoImg${index}`"
          >
            <div class="">
              <div class="card-body p-0 mb-4 position-relative">
                <div class="img-preview-container position-absolute">
                  <button
                    class="btn btn-dark opacity-75"
                    @click.prevent="deleteItem(indexAlbum, index)"
                  >
                    <span
                      v-if="loadingDelete[`${indexAlbum}${index}`]"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span v-else class="fas fa-trash"></span>
                  </button>
                </div>
                <img
                  :src="ph"
                  alt="..."
                  class="w-100 rounded photo-img"
                  :style="{ height: `${photoWidth}px` }"
                />
              </div>
            </div>
          </div>
        </div>
        <hr class="my-5" />
      </div>
    </div>
  </div>
</template>
<script>
import PhotosController from "./PhotosController";
export default PhotosController;
</script>
<style scoped>
img.photo-img {
  /* height: 150px; */
  object-fit: cover;
}
</style>
