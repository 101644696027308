<template>
  <div>
    <NavBar current="Onboarding" :showFullOptions="false" />
    <div class="container-fluid bg-white onboarding-content">
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3 py-6">
          <h1 class="header-title">Complete Registration</h1>
          <div class="progress my-5">
            <div
              class="progress-bar"
              role="progressbar"
              :style="`width: ${percentage}%`"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>

          <!-- STEP 1 -->
          <div v-if="step == 1">
            <p><strong>1. About University Profile</strong></p>

            <label for=""> University Profile Photo </label>

            <div class="form-control-custom mb-5">
              <input
                ref="input"
                type="file"
                name="image"
                accept="image/*"
                @change="setImage"
              />
              <h2 class="h2" v-if="!cropImg">
                <i class="fas fa-plus-circle"></i> Add Image
              </h2>

              <img
                :src="cropImg"
                v-if="cropImg"
                width="200"
                height="200"
                class="rounded"
              />
            </div>

            <div class="form-group">
              <label>University Bio</label>
              <textarea
                v-model.trim="$v.form.about.$model"
                :class="{ 'is-invalid': $v.form.about.$error }"
                rows="5"
                placeholder="Tell us about your university"
                class="form-control"
              ></textarea>
              <div class="invalid-feedback">
                <small>Please, fill up this field correctly</small>
              </div>
            </div>
            <!-- :disabled="$v.$invalid" -->
            <div class="row">
                <div class="col-12 text-center">
                  <span class="text-danger text-center">{{ errorMessage }}</span>
                </div>
              </div>
            <div class="d-grid gap-2">
              <button
                :disabled="!imgSrc || $v.form.about.$invalid || loading"
                class="btn btn-primary mt-5"
                @click="handleCompleteProfile()"
              >
               <span
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                Save and continue
              </button>
            </div>
          </div>
          <!-- END STEP 1 -->

          <!-- STEP questions -->

          <div v-for="(question, index) in questions" v-bind:key="'question-' + index">
            <div v-if="step == 1 + (index + 1)">
              <p>
                <strong> {{ step }}. {{ question.label }} </strong>
                <span
                  v-if="question.tooltipInfo"
                  :title="question.tooltipInfo"
                  class="fas fa-question-circle text-muted cursor-pointer ms-1"
                ></span>
              </p>
              <div class="form-group">
                <label>{{ question.label }}</label>
                <vue-google-autocomplete
                  v-if="question.type == 'location'"
                  id="map"
                  classname="form-control"
                  v-on:focus="resetLocation"
                  :placeholder="question.label"
                  :class="{ 'is-invalid': $v.form.questions[`${question.name}`].$error }"
                  v-on:placechanged="addressChanged"
                >
                </vue-google-autocomplete>
                <input
                  v-else
                  type="text"
                  v-model.trim="$v.form.questions[`${question.name}`].$model"
                  :class="{ 'is-invalid': $v.form.questions[`${question.name}`].$error }"
                  :placeholder="question.label"
                  class="form-control"
                />
                <div class="invalid-feedback">
                  <small>Please, fill up this field correctly</small>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <span class="text-danger text-center">{{ errorMessage }}</span>
                </div>
              </div>
              <div class="d-grid gap-2">
                <button
                  class="btn btn-primary mt-5"
                  @click="nextStep(step + 1)"
                  :disabled="$v.form.questions[`${question.name}`].$invalid || loading"
                >
                  <span
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  {{ step == questions.length + 1 ? "Finish" : "Save and continue" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalComponent title="Welcome to NOVVA" id="videoModal">
      <template v-slot:body>
        <iframe
          width="100%"
          height="350"
          src="https://www.youtube.com/embed/IFjQZRmi988"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          style="border-radius: 15px"
        ></iframe>
      </template>
      <template v-slot:footer>
        <div class="d-grid gap-2">
          <button type="button" @click="closeVideoModal" class="btn btn-primary">
            Complete your registration
          </button>
        </div>
      </template>
    </ModalComponent>

    <ModalComponent title="Crop Image" id="imageModal">
      <template v-slot:body>
        <vue-cropper
          :modal="false"
          :movable="false"
          :zoomable="false"
          ref="cropper"
          :src="imgSrc"
          alt="Source Image"
          :aspect-ratio="1 / 1"
          :minContainerWidth="550"
          :minContainerHeight="$helper.cropMinHeight"
        >
        </vue-cropper>
      </template>
      <template v-slot:footer>
        <button type="button" @click="cropImage" class="btn btn-primary">
          Crop & Upload
        </button>
      </template>
    </ModalComponent>
  </div>
</template>
<script>
import OnboardingController from "./OnboardingController";
export default OnboardingController;
</script>

<style scoped>
input[type="file"] {
  opacity: 0;
  height: 200px;
  width: 200px;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}
.form-control-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ecebeb;
  border-radius: 10px;
  opacity: 0.9;
  transition: 0.2s;
  width: 200px;
  height: 200px;
}
.form-control-custom .h2 {
  position: absolute;
  color: #a19f9f;
}
.onboarding-content {
  margin-top: 65px;
}
</style>
