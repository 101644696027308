<template>
  <div class="audience">
    <Sidebar />
    <NavBar current="Resources" />
    <LoadingContainer v-if="loadingAll"  />
    <div class="main-content" v-else>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="header mt-md-5">
              <div class="header-body border-0">
                <div class="row align-items-center">
                  <div class="col">
                    <h1 class="header-title">Resources</h1>
                  </div>
                  <div class="col-auto">
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="Button group with nested dropdown"
                    >
                      <div class="btn-group" role="group">
                        <router-link
                        :to="actionNames[selectedTab].path"
                        tag="button"
                          class="btn btn-primary"
                        >
                          <i class="me-2 fas fa-plus-circle"></i>
                          {{ actionNames[selectedTab].name }}

                        </router-link>
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col">
                    <ul
                      class="nav nav-tabs header-tabs"
                      id="myTab"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation">
                        <a
                          @click.prevent="handleSelectTab(0)"
                          class="nav-link"
                          :class="{'active': selectedTab == 0}"
                          id="scholarships-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#scholarships"
                          type="button"
                          role="tab"
                          aria-controls="scholarships"
                          aria-selected="true"
                        >
                          Scholarships
                          <span
                            class="ms-auto badge"
                            :class="{
                              'bg-primary-soft': selectedTab == 0,
                              'bg-secondary-soft': selectedTab != 0,
                            }"
                            >{{ scholarships.length }}</span
                          >
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          @click.prevent="handleSelectTab(1)"
                          class="nav-link"
                          :class="{'active': selectedTab == 1}"
                          id="brochures-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#brochures"
                          type="button"
                          role="tab"
                          aria-controls="brochures"
                          aria-selected="false"
                        >
                          Brochures
                          <span
                            class="ms-auto badge"
                            :class="{
                              'bg-primary-soft': selectedTab == 1,
                              'bg-secondary-soft': selectedTab != 1,
                            }"
                            >{{ brochures.length }}</span
                          >
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          @click.prevent="handleSelectTab(2)"
                          class="nav-link"
                          :class="{'active': selectedTab == 2}"
                          id="photos-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#photos"
                          type="button"
                          role="tab"
                          aria-controls="photos"
                          aria-selected="false"
                        >
                          Photos
                          <span
                            class="ms-auto badge"
                            :class="{
                              'bg-primary-soft': selectedTab == 2,
                              'bg-secondary-soft': selectedTab != 2,
                            }"
                            >{{ photos.reduce((a,b) => a + b.photos.length, 0) }}</span
                          >
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          @click.prevent="handleSelectTab(3)"
                          class="nav-link"
                          :class="{'active': selectedTab == 3}"
                          id="videos-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#videos"
                          type="button"
                          role="tab"
                          aria-controls="videos"
                          aria-selected="false"
                        >
                          Videos
                          <span
                            class="ms-auto badge"
                            :class="{
                              'bg-primary-soft': selectedTab == 3,
                              'bg-secondary-soft': selectedTab != 3,
                            }"
                            >{{ videos.length }}</span
                          >
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="tab-content" id="myTabContent">
              <!-- SCHOLARSHIPS -->
              <div
                class="table-responsive mt-5 tab-pane fade"
                :class="{'show active': selectedTab == 0}"
                id="scholarships"
                role="tabpanel"
                aria-labelledby="scholarships-tab"
              >
                <Scholarships :items="scholarships" />
              </div>
              <!-- BROCHURES -->
              <div
                class="table-responsive mt-5 tab-pane fade"
                :class="{'show active': selectedTab == 1}"
                id="brochures"
                role="tabpanel"
                aria-labelledby="brochures-tab"
              >
                <Brochures :items="brochures" />
              </div>
              <!-- PHOTOS -->
              <div
                class="table-responsive mt-5 tab-pane fade"
                :class="{'show active': selectedTab == 2}"
                id="photos"
                role="tabpanel"
                aria-labelledby="photos-tab"
              >
                <Photos :items="photos" />
              </div>
              <!-- VIDEOS -->
              <div
                class="table-responsive mt-5 tab-pane fade"
                :class="{'show active': selectedTab == 3}"
                id="videos"
                role="tabpanel"
                aria-labelledby="videos-tab"
              >
                <Videos :items="videos" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UniversityController from "./UniversityController";
export default UniversityController;
</script>
