<template>
  <div class="card border-2 position-fixed me-5 calendar-height">
    <div class="row p-4">
      <div class="col-12">
        <h2 class="">
          <strong>{{ mainTitle || "Appointments Today" }}</strong>
        </h2>
      </div>
      <div class="col-12 mt-4">
        <div class="row">
          <div
            v-for="(weekDay, index) in weekDays"
            v-bind:key="'week-days' + index"
            class="col justify-content-center p-0 d-flex flex-column align-items-center"
          >
            <p class="text-dark h4 text-center" style="font-size: 14px">
              {{ weekDay }}
            </p>
          </div>
        </div>
        <div class="row my-3" v-for="(week, index) in weeks" v-bind:key="'week-' + index">
          <div
            @click.prevent="dateSelected(day)"
            class="col p-0 d-flex justify-content-center flex-column align-items-center"
            :class="{'cursor-pointer': enableDaySelection}"
            v-for="(day, indexJ) in week"
            v-bind:key="'day-' + indexJ"
          >
            <div
              class="align-items-center d-flex bg-primary justify-content-center number-container p-2"
              :class="{
                'text-gray-400': !day.isCurrentMonth,
                'text-dark bg-transparent': (!day.isToday && day.formattedDate != selectedDate),
                'text-dark bg-dark-soft': (!day.isToday && day.formattedDate == selectedDate),
                'text-white bg-primary ': day.isToday,
              }"
            >
              <p class="h3 text-center m-0 opacity-75" style="font-size: 14px">
                {{ day.day }}
              </p>
            </div>
            <div class="row">
              <div class="col-12 pt-1 d-flex justify-content-center">
                <span
                  class="fas fa-circle small appointment-badge"
                  :class="{
                    'text-white': !day.hasAppointment || !day.isCurrentMonth,
                    'text-primary': day.hasAppointment && day.appointmentActive,
                    'text-black-50': day.hasAppointment && !day.appointmentActive,
                  }"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="showAppointments">
        <div
          class="col-12 pt-5 text-center"
          v-if="!data.appointments"
        >
          <i class="far fa-calendar-alt fa-2x text-muted"></i>
          <h3 class="mt-2 text-muted">
            <strong>No Appointments Today</strong>
          </h3>
        </div>
        <div class="col-12 py-3 pb-4" v-else>
          <div
            class="card-body border-bottom border-2 py-3"
            v-for="(item, index) in data.appointments
              .slice(0, 2)"
            v-bind:key="'appointment-' + index"
          >
            <div class="list-group list-group-flush my-n3">
              <div class="list-group-item">
                <div class="row">
                  <div class="col-auto pe-0 ps-0">
                    <span class="fa fa-2x fa-user-circle text-primary mb-0"></span>
                  </div>
                  <div class="col ms-n2">
                    <h4 class="h4 text-dark mb-2">
                      {{ item.dateTime.start }} to {{ item.dateTime.end }}
                    </h4>
                    <span class="mb-0">Meeting with student {{ item.student.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 text-center mt-auto">
            <router-link tag="a" :to="$paths.agenda.list" class="text-primary">
              See All Appointments
            </router-link>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import AppointmentsCalendarController from "./AppointmentsCalendarController";
export default AppointmentsCalendarController;
</script>
<style scoped>
div.number-container {
  border-radius: 50%;
  height: 36px;
  width: 36px;
}
span.appointment-badge {
  font-size: 0.68rem;
}
.calendar-height {
  /* height: calc(100vh - 175px); */
  overflow: scroll;
}
</style>
