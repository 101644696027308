import Container from "@/components/Container.vue";
import EmptyState from "@/components/EmptyState.vue";
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/Sidebar.vue";
import { required } from 'vuelidate/lib/validators';

export default {
    data() {
        return {
            errorMessage: '',
            loading: false,
            form: {
                title: '',
                url: '',
            },
            hasLink: false,
            embed: '',
            parsedUrl: ''
        }
    },
    validations: {
        form: {
            title: { required },
            url: { required },
        }
    },
    components: {
    },
    methods: {
        setIframe() {
    // https://youtu.be/-MeFJTxGpfU

            this.parsedUrl = this.$helper.youtubeParser(this.form.url)
            this.embed = this.parsedUrl.replace('watch?v=', 'embed/').split('&')[0]
            const url = this.parsedUrl.replace('watch?v=', 'embed/').split('&')[0]
            if (url != undefined || url != '') {
                var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/
                var match = url.match(regExp);
                if (match && match[2].length == 11) {
                    // Do anything for being valid
                    // if need to change the url to embed url then use below line
                    this.hasLink = true

                }
                else {
                    this.hasLink = false

                    // Do anything for not being valid
                }
            }
        },
        async save() {
            this.errorMessage = ''
            this.loading = true
            try {
                const res = await this.$universityService.createVideo({ ...this.form, url: this.parsedUrl })
                this.$eventBus.$emit('showToast', { title: 'Video Uploaded', msg: `${this.form.title} was uploaded successfully.` })
                this.$router.push({ path: `${this.$paths.university.list}`, query: { tab: 3 } }).catch(e => { })
            } catch (e) {
                this.errorMessage = e.message
            } finally {
                this.loading = false
            }

        },

    }
}