import ModalComponent from "@/components/ModalComponent";
import { Modal } from 'bootstrap';
import "cropperjs/dist/cropper.css";
import VueCropper from "vue-cropperjs";


export default {
    props: {
        accept: {
            type: String,
            default: 'image/*'
        },
        customFileInputWidth: {
            type: Number,
            default: 724,
        },
        aspectRatio: {
            type: Number,
            default: 1,
        },
    },
    mounted() {
        this.$eventBus.$on("clearImageCropper", () => {
            console.log('resetoou')
            this.imgSrc = null
            this.cropImg = ""
            this.fileImage = ''
            this.title = ''
            this.fileName = ''
        });
    },
    created() {
    },
    data() {
        return {
            imgSrc: null,
            cropImg: "",
            fileImage: '',
            title: '',
            percentage: 0,
            uploading: false,
            counter: null,
            fileName: '',
            eventLabel: 'Select Photo Collection'
        }
    },
    components: {
        VueCropper,
        ModalComponent
    },
    methods: {
        cropClosed() {
            console.log('NEVERS')
            this.$refs.input.value = ''
        },
        cropImage() {
            // get image data for post processing, e.g. upload or setting image src

            this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', this.$helper.DEFAULT_IMAGE_QUALITY);
            this.fileImage = this.$helper.dataURLtoFile(this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', this.$helper.DEFAULT_IMAGE_QUALITY), 'text.jpg')
            this.imageModal.toggle()
            this.upload()
        },
        setImage(e) {
            const file = e.target.files[0];
            this.fileName = file.name
            if (this.accept == 'image/*') {
                if (file.size > 10000000) {
                    alert("Max file size: 10mb");
                    return;
                }
                this.imageModal = new Modal(document.getElementById('imageModal'), {
                    backdrop: 'static',
                    keyboard: false
                })
                this.imageModal.toggle()
                if (file.type.indexOf("image/") === -1) {
                    alert("Please select an image file");
                    return;
                }
                if (typeof FileReader === "function") {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        this.imgSrc = event.target.result;
                        // rebuild cropperjs with the updated source
                        this.$refs.cropper.replace(event.target.result);
                    };
                    reader.readAsDataURL(file);
                } else {
                    alert("Sorry, FileReader API not supported");
                }
            } else {
                // this.imgSrc = '@assets/img/pdf_placeholder.png'; // imagem do pdf placeholder
                if (file.type.indexOf("pdf") === -1) {
                    alert("Please select an pdf file");
                    return;
                }
                this.fileImage = file
                this.cropImg = '@/assets/img/pdf_placeholder.png'
                this.upload()
            }
        },
        upload() {
            this.uploading = true
            let timer = Math.floor(Math.random() * (700 - 100)) + 500;

            this.counter = setTimeout(() => {
                this.setCounter()
            }, timer)
        },
        cancelUpload() {
            this.uploading = false
            this.percentage = 0
            this.cropImg = ''
            clearTimeout(this.counter)
        },
        setCounter() {
            if (this.percentage < 100) {
                let max = 100
                let p = Math.floor(Math.random() * (max - this.percentage + 1)) + this.percentage;
                this.percentage = p >= 100 ? 100 : p
                this.upload()
            } else {
                this.uploading = false
                this.percentage = 0
                clearTimeout(this.counter)
                this.$emit('cropEnd', { file: this.fileImage, base64: this.cropImg })
            }
        },
    }
}