<template>
  <div class="audience">
    <Sidebar />
    <NavBar
      current="Scholarship Detail"
      :link="$paths.university.list"
      linkName="Resources"
    />
    <LoadingContainer v-if="loadingAll" />
    <div class="main-content" v-else>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="header mt-md-5">
              <div class="header-body border-0">
                <div class="row align-items-center">
                  <div class="col">
                    <h6 class="header-pretitle">Scholarship Detail</h6>
                    <h1 class="header-title">{{ scholarship.name }}</h1>
                  </div>
                  <div class="col-auto">
                    <div class="col-auto">
                      <div class="btn-group me-3" role="group">
                        <button
                          id="btnGroupDrop1"
                          type="button"
                          class="btn bg-primary-soft btn-link"
                          :class="{
                            'bg-primary-soft': scholarship.isActive,
                            'bg-secondary-soft text-secondary': !scholarship.isActive,
                          }"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {{ scholarship.isActive ? "Active" : "Inactive" }}
                          <i class="me-2 fas fa-chevron-down"></i>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                          <li>
                            <a
                              @click.prevent="updateStatus(!scholarship.isActive)"
                              class="dropdown-item cursor-pointer"
                              >{{ scholarship.isActive ? "Deactivate" : "Activate" }}</a
                            >
                          </li>
                        </ul>
                      </div>
                      <button
                        type="button"
                        class="btn btn-danger"
                        :disabled="loadingDelete"
                        @click.prevent="deleteItem()"
                      >
                        <span
                          v-if="loadingDelete"
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Delete
                        <i class="ms-2 fas fa-trash-alt"></i>
                      </button>
                      <!-- <button type="button" class="btn btn-primary">
                        Edit
                        <i class="ms-2 fas fa-pencil-alt"></i>
                      </button> -->
                      <div
                        class="btn-group"
                        role="group"
                        aria-label="Button group with nested dropdown"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col">
                    <ul class="nav nav-tabs header-tabs" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <a
                          @click.prevent="selectedTab = 0"
                          class="nav-link active"
                          id="overview-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#overview"
                          type="button"
                          role="tab"
                          aria-controls="overview"
                          aria-selected="true"
                        >
                          Overview
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          @click.prevent="selectedTab = 1"
                          class="nav-link"
                          id="type-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#type"
                          type="button"
                          role="tab"
                          aria-controls="type"
                          aria-selected="true"
                        >
                          Type
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          @click.prevent="selectedTab = 2"
                          class="nav-link"
                          id="details-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#details"
                          type="button"
                          role="tab"
                          aria-controls="details"
                          aria-selected="false"
                        >
                          Details
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          @click.prevent="selectedTab = 3"
                          class="nav-link"
                          id="amount-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#amount"
                          type="button"
                          role="tab"
                          aria-controls="amount"
                          aria-selected="true"
                        >
                          Amount
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          @click.prevent="selectedTab = 4"
                          class="nav-link"
                          id="requirements-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#requirements"
                          type="button"
                          role="tab"
                          aria-controls="requirements"
                          aria-selected="false"
                        >
                          Requirements
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="tab-content" id="myTabContent">
              <!-- OVERVIEW -->
              <div
                class="table-responsive mt-5 tab-pane fade show active"
                id="overview"
                role="tabpanel"
                aria-labelledby="overview-tab"
              >
                <div class="row">
                  <div class="col-7">
                    <div class="card card-overview border-2">
                      <div class="card-header border-0">
                        <h2 class="card-header-title">Applications</h2>
                      </div>
                      <div class="card-body pt-3">
                        <div class="row mb-3">
                          <div class="col">
                            <span class="h3"
                              >{{ scholarship.applicationsCount }} Applied</span
                            >
                            <span class="text-muted ms-3"
                              >{{ scholarship.avaliableSeats }} Remaining</span
                            >
                          </div>
                          <div class="col-auto">
                            <span class="text-muted ms-3"
                              >{{ scholarship.datesAvailability.availableSpaces }} Spaces</span
                            >
                          </div>
                        </div>

                        <div class="progress">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            :style="`width: ${scholarship.occupancyRate}%`"
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="card card-overview border-2">
                      <div class="card-header border-0">
                        <h2 class="card-header-title">Views</h2>
                      </div>
                      <div class="card-body pt-0"></div>
                      <div class="card-footer border-0">
                        <span class="h2 fas fa-eye text-primary"></span>
                        <span class="h3 ms-2">{{ scholarship.views }}</span>
                      </div>
                    </div>
                  </div>
                  <div v-if="hasDeadline()" class="col">
                    <div class="card card-overview border-2">
                      <div class="card-header border-0">
                        <h2 class="card-header-title">Deadline</h2>
                      </div>
                      <div class="card-body pt-0"></div>
                      <div class="card-footer border-0">
                        <span class="h3 fas fa-calendar-alt text-primary"></span>
                        <span class="h3 ms-2">{{ scholarship.formatted_deadline }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <h2 class="h2 my-4">Students applied</h2>

                <div class="search-table-form">
                  <div class="row align-items-center">
                    <div class="col">
                      <form>
                        <div
                          class="input-group input-group-flush input-group-merge input-group-reverse"
                        >
                          <input
                            class="form-control list-search"
                            type="search"
                            @input="searchString"
                            placeholder="Search"
                          />
                          <span class="input-group-text">
                            <i class="fas fa-search ps-3"></i>
                          </span>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <EmptyState
                  title="No Students"
                  msg="No students have applied"
                  v-if="scholarship.applicationsCount == 0"
                >
                  <template v-slot:icon>
                    <i class="fas fa-users fa-3x text-primary"></i>
                  </template>
                </EmptyState>
                <table
                  v-if="scholarship.applicationsCount > 0"
                  class="table table-sm table-hover table-nowrap card-table bg-white"
                >
                  <thead>
                    <tr>
                      <th
                        v-for="(column, index) in columns.overview"
                        :key="column"
                        :class="{ 'text-start': index == 0 }"
                        :width="index != 0 ? '1%' : 'auto'"
                      >
                        {{ column }}
                      </th>
                      <!-- <th width="1%">
                        <a href="#" class="text-muted"> Action </a>
                      </th> -->
                      <!-- <th width="1%"></th> -->
                    </tr>
                  </thead>
                  <tbody class="list">
                    <tr
                      v-for="item in showingApplications"
                      v-bind:key="item.id"
                      class="cursor-pointer"
                    >
                      <td @click="itemPressed(item)" class="text-start">
                        <strong>{{ item.studentName }}</strong>
                      </td>
                      <td @click="itemPressed(item)">
                        {{ $moment(item.createdAt).format("MMMM DD") }}
                      </td>
                      <td @click="itemPressed(item)">
                        <span v-if="item.user.match" class="ms-auto badge bg-primary-soft"
                          >{{ item.user.match.value }}%</span
                        >
                        <span v-else class="ms-auto badge bg-primary-soft">0%</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- TYPE TAB -->
              <div
                class="table-responsive mt-5 tab-pane fade container-fluid"
                id="type"
                role="tabpanel"
                aria-labelledby="type-tab"
              >
                <div class="row">
                  <div class="col-12">
                    <ReadOnlyInput :label="'Type'" :value="scholarship.parser.type" />
                  </div>

                  <div class="col-12">
                    <ReadOnlyInput v-if="scholarship.type == 'athletic-sports'" :label="'Sports'" :value="scholarship.parser.applicableSports" />
                    <ReadOnlyInput v-if="scholarship.type == 'gifted-talented'" :label="'Areas/options'" :value="scholarship.parser.applicableAreas" />
                    <ReadOnlyInput v-if="scholarship.type == 'location-based'" :label="'Locations'" :value="scholarship.parser.locations" />
                    <ReadOnlyInput v-if="scholarship.type == 'merit-academic'" :label="'Exams/tests'" :value="scholarship.parser.exams" />
                    <ReadOnlyInput v-if="scholarship.type == 'needs-based'" :label="'Subtype'" :value="scholarship.parser.needsBasedSubtype" />
                    <ReadOnlyInput v-if="scholarship.type == 'minorities-ethnicity'" :label="'Minority/ethnicity criteria'" :value="scholarship.parser.ethnicityCriteria" />
                  </div>
                </div>
              </div>

              <!-- DETAILS TAB -->
              <div
                class="table-responsive mt-5 tab-pane fade"
                id="details"
                role="tabpanel"
                aria-labelledby="details-tab"
              >
                <div v-html="scholarship.description"></div>
              </div>
              
              <!-- AMOUNT TAB -->
              <div
                class="table-responsive mt-5 tab-pane fade container-fluid"
                id="amount"
                role="tabpanel"
                aria-labelledby="amount-tab"
              >
                <div class="row">
                  <div class="col-12 col-md-4">
                    <ReadOnlyInput :label="'Awardable amount'" :value="scholarship.parser.awardableAmount" />
                  </div>

                  <div v-if="scholarship.awardableAmount == 'up-to'" class="col-12 col-md-6">
                    <label class="form-label text-muted mb-1">Up to</label>
                    <currency-input
                      v-model.trim="scholarship.awardable.upTo"
                      class="currency-input-readonly"
                      :currency="scholarship.awardable.currency"
                      :disabled="true"
                    />
                  </div>
                  <div v-if="scholarship.awardableAmount == 'variable'" class="col-12 col-md-6">
                    <label class="form-label text-muted mb-1">Average amount</label>
                    <currency-input
                      v-model.trim="scholarship.awardable.average"
                      class="currency-input-readonly"
                      :currency="scholarship.awardable.currency"
                      :disabled="true"
                    />
                  </div>
                  <div v-if="scholarship.awardableAmount == 'fixed'" class="col-12 col-md-6">
                    <label class="form-label text-muted mb-1">Amount value</label>
                    <currency-input
                      v-model.trim="scholarship.awardable.amountValue"
                      class="currency-input-readonly"
                      :currency="scholarship.awardable.currency"
                      :disabled="true"
                    />
                  </div>
                </div>
              </div>

              <div
                class="table-responsive mt-5 tab-pane fade"
                id="requirements"
                role="tabpanel"
                aria-labelledby="requirements-tab"
              >
                <div>
                  <EmptyState
                    title="No Requirements"
                    msg="This scholarship has no requirements"
                    v-if="
                      scholarship.requirements && scholarship.requirements.length == 0
                    "
                  >
                    <template v-slot:icon>
                      <i class="fas fa-book fa-3x text-primary"></i>
                    </template>
                  </EmptyState>
                  <div class="list-group list-group-flush my-n3">
                    <div
                      class="list-group-item"
                      v-for="item in scholarship.requirements"
                      v-bind:key="item.id"
                    >
                      <div class="row align-items-center">
                        <div class="col">
                          <!-- Heading -->
                          <h4 class="font-weight-base mb-1 text-muted">
                            {{ item.description }}
                          </h4>
                        </div>
                        <div class="col-auto">
                          <!-- Switch -->
                          <div class="form-check form-switch">
                            <span class="text-dark"> {{ item.title }} </span>
                          </div>
                        </div>
                      </div>
                      <!-- / .row -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div
      class="list-alert alert alert-dark alert-dismissible border fade pe-2"
      :class="{ show: items.filter((r) => r.selected).length }"
      role="alert"
    >
      <div class="row align-items-center">
        <div class="col">
          <div class="">
            <i class="fas fa-check-square text-primary me-2"></i>
            <label class="form-check-label text-white" for="listAlertCheckbox">
              <span class="list-alert-count">{{
                items.filter((r) => r.selected).length
              }}</span>
              selected
            </label>
          </div>
        </div>
        <div class="col-auto me-n3">
          <button
            class="btn btn-sm btn-white-20 bg-transparent pe-4"
            @click="clearSelected()"
          >
            <i class="fas fa-trash"></i> <small>Remove All</small>
          </button>
          <button class="btn btn-sm btn-primary">
            {{ items.filter((r) => r.selected).reduce((a, b) => a + b.credit, 0) }}
            Credits to accept all
          </button>
        </div>
        <div class="col-auto">
          <button @click="clearSelected()" type="button" class="btn" aria-label="Close">
            <i class="fas fa-times text-white"></i>
          </button>
        </div>
      </div>
    </div> -->

    <!-- <div
      class="modal fade"
      id="walletModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header border-0">
            <h2 class="h2"></h2>

            <span
              @click="toggleWalletModal('hide')"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              class="fas fa-times text-muted"
            ></span>
          </div>
          <div class="modal-body text-center">
            <i class="fas fa-coins fa-3x text-primary"></i>
            <h2 class="h2 mt-4 mb-2">Your wallet is empty</h2>
            <span class="text-muted">You must add credit to accept new followers</span>
          </div>
          <div class="modal-footer justify-content-center">
            <button type="button" @click="goToAddCredits" class="btn btn-primary">
              Add credits
            </button>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import ScholarshipDetailController from "./ScholarshipDetailController";
export default ScholarshipDetailController;
</script>
<style>
.card.card-overview {
  border-radius: 25px;
  height: 150px;
}
.currency-input-readonly {
  display: block;
  border-radius: 6px;
  border: none;
  padding: 0 15px;
  height: 40px;
  color: #a19f9f !important;
  background-color: #ececec;
}
</style>
