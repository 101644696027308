

export default {
    name: 'Chat',
    mounted() {
        this.interval = setInterval(() => {
            this.getItem()
        }, 2000);
        this.getItem()
        this.readMessages()

    },
    destroyed() {
        clearInterval(this.interval)
    },
    computed: {
        userSession() {
            return this.$store.state.userSession;
        },
    },
    data() {
        return {
            maxTextLength: 400,
            interval: null,
            chatId: this.$route.params.id,
            textMessage: '',
            item: {
                image: 'https://i.pravatar.cc/150'
            },
            chatInfo: {},
            items: []
        }
    },
    methods: {
        async getItem() {
            
            try {
                const res = await this.$messagesService.getChatMessages(this.chatId)
                const d = res.data.data
                const student = d.student
                let location = ''
                if (student.profile.location) {
                    location = `${student.profile.location.city}, ${student.profile.location.region}`
                }
                const prevItemsLength = this.items.length
                this.$set(this, 'items', d.chat.messages)
                this.$set(this, 'chatInfo', {
                    firstMessage: d.chat.messages && d.chat.messages.length > 0 ? d.chat.messages[0].createdAt : null,
                    studentName: student.name,
                    studentId: student._id,
                    studentPicture: student.image || '',
                    studentLocation: location
                })
                setTimeout(() => {
                    let container = document.getElementById('main-chat-container')
                    if(d.chat.messages.length != prevItemsLength) {
                        container.scrollTop = container.scrollHeight;
                    }
                }, 100);

            } catch (e) {

            }
        },
        async handleSend() {
            if (this.textMessage) {
                const m = this.textMessage.trim()
                this.textMessage = ''
                try {
                    const res = await this.$messagesService.sendMessage({ message: m, student: this.chatInfo.studentId })
                    // this.getItem()
                } catch (e) {

                }
            }
        },
        readMessages() {
            this.$messagesService.readMessages(this.chatId)
        }
    },
    components: {
    }
}