import '@fortawesome/fontawesome-free/css/all.css'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.snow.css'
import 'bootstrap'
import 'jquery'
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import 'vue2-datepicker/index.css'
import './assets/theme/scss/theme.scss'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './styles.scss'
import VueMask from 'v-mask'
import UniversityService from './services/UniversityService'
import UserService from './services/UserService'
import AgendaService from './services/AgendaService'
import FinancialService from './services/FinancialService'
import SocialService from './services/SocialService'
import TeamService from './services/TeamService'
import AudienceService from './services/AudienceService'
import MessagesService from './services/MessagesService'
import paths from './router/paths'
import { EventBus } from '@/utils/EventBus'
import * as moment from 'moment-timezone'
import * as helper from '@/utils/Helpers'
import Sidebar from "@/components/Sidebar.vue";
import NavBar from "@/components/NavBar.vue";
import EmptyState from "@/components/EmptyState.vue";
import Container from "@/components/Container.vue";
import ImagePlaceholder from "@/components/ImagePlaceholder.vue";
import LoadingContainer from "@/components/LoadingContainer.vue";
import ModalComponent from "@/components/ModalComponent";
import ConfirmationAlert from "@/components/ConfirmationAlert/ConfirmationAlert.vue";
import VueNumeralFilter from 'vue-numeral-filter'
import VueCurrencyInput from 'vue-currency-input'
import GooglePlaceAutocomplete from './utils/GooglePlaceAutocomplete'

const pluginOptions = {
  globalOptions: { currency: 'USD' }
}

Vue.use(VueCurrencyInput, pluginOptions)
Vue.use(VueQuillEditor);
Vue.use(Vuelidate)
Vue.config.productionTip = false
Vue.use(VueMask);
Vue.use(VueNumeralFilter)
Vue.use(GooglePlaceAutocomplete)

Vue.prototype.$agendaService = new AgendaService()
Vue.prototype.$audienceService = new AudienceService()
Vue.prototype.$messagesService = new MessagesService()
Vue.prototype.$financialService = new FinancialService()
Vue.prototype.$socialService = new SocialService()
Vue.prototype.$teamService = new TeamService()
Vue.prototype.$universityService = new UniversityService()
Vue.prototype.$userService = new UserService()
Vue.prototype.$paths = paths
Vue.prototype.$eventBus = EventBus
Vue.prototype.$moment = moment
Vue.prototype.$helper = helper

Vue.component('Sidebar', Sidebar)
Vue.component('NavBar', NavBar)
Vue.component('EmptyState', EmptyState)
Vue.component('LoadingContainer', LoadingContainer)
Vue.component('ModalComponent', ModalComponent)
Vue.component('ConfirmationAlert', ConfirmationAlert)
Vue.component('Container', Container)
Vue.component('ImagePlaceholder', ImagePlaceholder)


Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


