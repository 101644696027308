import EmptyState from "@/components/EmptyState.vue";
export default {
  name: "Photos",
  mounted() {
    this.$eventBus.$on("onTabPhotosSelected", this.handleTabPhotosSelected);

    let x = {};
    this.items.map((r, index) => {
      const key = `alb-${index}`;
      x[key] = r.title;
    });
    console.log(x)
    this.$set(this, "editingAlbumTexts", x);

    setTimeout(() => {
      this.calculateHeight();
    }, 1000);
  },
  created() {
    window.addEventListener("resize", this.calculateHeight);
  },
  data() {
    return {
      photoWidth: 175,
      loadingDelete: [],
      editingAlbums: {},
      albumFocus: [],
      editingAlbumTexts: {},
    };
  },
  props: {
    items: Array,
  },
  methods: {
    handleTabPhotosSelected() {
      console.log("asjbdhkashjdjklnas");
      setTimeout(() => {
        this.calculateHeight();
      }, 250);
    },
    calculateHeight() {
      // if(this.items.length > 0) {
      //   const width = this.$refs.photoImg0[0].clientWidth
      //   console.log(width)
      //   this.photoWidth = width - 24
      // }
    },
    async deleteItem(indexAlbum, index) {

      this.$eventBus.$emit("showConfirmationAlert", {
        message: "Are you sure you want to remove this photo permanently?",
        confirmCallback: async () => {
          try {
            this.$set(this.loadingDelete, `${indexAlbum}${index}`, true);
            this.loadingDelete[index] = true;
            // TODO: REVISAR
            const id = this.items[indexAlbum].photos[index]._id;
            const params = {
              album: this.items[indexAlbum]._id,
              arrayPosition: index,
              photo: this.items[indexAlbum].photos[index],
            };
            const res = await this.$universityService.deletePhoto(params);
            this.$eventBus.$emit("showToast", {
              title: "Photo deleted",
              msg: `The photo was deleted successfully`,
            });
            this.items[indexAlbum].photos.splice(index, 1);
            if (!this.items[indexAlbum].photos.length) {
              this.items.splice(indexAlbum, 1);
            }
          } catch (e) {
            console.log(e);
          } finally {
            this.$set(this.loadingDelete, `${indexAlbum}${index}`, false);
          }
        },
      });

      // if (confirm("Are you sure you want to remove this photo permanently ?")) {
      //   try {
      //     this.$set(this.loadingDelete, `${indexAlbum}${index}`, true);
      //     this.loadingDelete[index] = true;
      //     // TODO: REVISAR
      //     const id = this.items[indexAlbum].photos[index]._id;
      //     const params = {
      //       album: this.items[indexAlbum]._id,
      //       arrayPosition: index,
      //       photo: this.items[indexAlbum].photos[index],
      //     };
      //     const res = await this.$universityService.deletePhoto(params);
      //     this.$eventBus.$emit("showToast", {
      //       title: "Photo deleted",
      //       msg: `The photo was deleted successfully`,
      //     });
      //     this.items[indexAlbum].photos.splice(index, 1);
      //     if (!this.items[indexAlbum].photos.length) {
      //       this.items.splice(indexAlbum, 1);
      //     }
      //   } catch (e) {
      //     console.log(e);
      //   } finally {
      //     this.$set(this.loadingDelete, `${indexAlbum}${index}`, false);
      //   }
      // }
    },
    editAlbumClicked(albumIndex) {
      try {
        const key = `alb-${albumIndex}`;
        console.log(this.editingAlbumTexts);
        // let editingAlbums = this.editingAlbums;
        // editingAlbums[key] = true;
        // console.log(JSON.stringify(editingAlbums));
        this.$set(this.editingAlbums, key, true);
        setTimeout(() => {
          document.getElementById(key).focus();
        }, 200);
      } catch (e) {
        console.log(e);
      }
    },
    async handleBlur(albumIndex) {
      try {
        const key = `alb-${albumIndex}`;
        this.$set(this.editingAlbums, key, false);
        this.$set(this.items, albumIndex, {...this.items[albumIndex], title: this.editingAlbumTexts[key]});
        const res = await this.$universityService.updateAlbum(this.items[albumIndex]._id, {title: this.editingAlbumTexts[key]})
      } catch (e) {
        console.log(e);
      }
    },
  },
  components: {},
};
