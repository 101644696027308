<template>
  <div>
    <Sidebar />
    <NavBar current="Add Member" :link="$paths.team.list" linkName="Team" />
    <Container col="col-12 col-lg-6 col-xl-4">
      <div class="header mt-md-4">
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col">
              <h1 class="header-title">New member</h1>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="form-group">
          <label class="form-label">Name</label>
          <input
            ref="nameInput"
            type="text"
            v-model.trim="$v.form.name.$model"
            :class="{ 'is-invalid': $v.form.name.$error }"
            placeholder="Name"
            class="form-control"
          />
          <div class="invalid-feedback">
            <small>Please, fill up this field correctly</small>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label">Email</label>
          <input
            type="text"
            v-model.trim="$v.form.email.$model"
            :class="{ 'is-invalid': $v.form.email.$error }"
            placeholder="Email"
            class="form-control"
          />
          <div class="invalid-feedback">
            <small>Please, fill up this field correctly</small>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label">Job Title</label>
          <input
            type="text"
            v-model.trim="$v.form.jobTitle.$model"
            :class="{ 'is-invalid': $v.form.jobTitle.$error }"
            placeholder="Job Title"
            class="form-control"
          />
          <div class="invalid-feedback">
            <small>Please, fill up this field correctly</small>
          </div>
        </div>

        <div class="form-group">
          <label for="" class="form-label">Permissions</label>
          <div class="d-grid gap-2">
            <button
              id="btnGroupDrop1"
              type="button"
              class="btn btn-white dropdown-toggle text-start d-flex justify-content-between align-items-center"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style="
                border: 1px solid #d0cfcf;
                border-radius: 0.375rem !important;
                font-weight: 400 !important;
              "
            >
              {{ permissionLabel }}
            </button>
            <ul
              class="dropdown-menu"
              aria-labelledby="btnGroupDrop1"
              :style="{ width: customSelectWidth + 'px' }"
            >
              <li
                class="dropdown-item cursor-pointer"
                v-for="role in roles"
                :key="role.id"
                @click="setPermission(role)"
              >
                {{ role.label }}
              </li>
            </ul>
          </div>
        </div>

        <!-- <div class="form-group">
          <label class="form-label">Permissions</label>
          <select
            v-model.trim="$v.form.role.$model"
            :class="{ 'is-invalid': $v.form.role.$error }"
            class="form-select"
          >
            <option value="" selected disabled>Select permission</option>
            <option v-for="role in roles" :key="role">{{ role }}</option>
          </select>
          <div class="invalid-feedback">
            <small>Please, fill up this field correctly</small>
          </div>
        </div> -->

        <div class="form-group">
          <label class="form-label">Location</label>
          <input
            readonly
            type="text"
            @click.prevent="openSelectLocationModal"
            v-model.trim="$v.form.locations.$model"
            :class="{ 'is-invalid': $v.form.locations.$error }"
            class="form-control cursor-pointer form-custom"
            placeholder="Select location"
          />
          <div class="invalid-feedback">
            <small>Please, fill up this field correctly</small>
          </div>
        </div>

        <!-- :disabled="$v.$invalid" -->
        <div class="row">
          <div class="col-12 text-center">
            <span class="text-danger text-center">{{ errorMessage }}</span>
          </div>
        </div>
        <div class="d-grid gap-2">
          <button
            class="btn btn-primary mt-3"
            @click="save"
            :disabled="$v.$invalid || loading"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Add
          </button>
        </div>
      </div>
    </Container>
    <ModalComponent id="location_select_modal" title="Locations">
      <template v-slot:body>
        <p>
          <strong>Select the locations you will recrute</strong>
        </p>
        <ul class="list-group list-group-flush">
          <li
            class="list-group-item cursor-pointer"
            v-for="(location, index) in locations"
            :key="index"
            @click.prevent="selectLocation(index)"
          >
            <div class="row">
              <div class="col">
                <strong class="text-dark">
                  {{ location.label }}
                </strong>
              </div>
              <div class="col-auto">
                <a class="form-check">
                  <i
                    v-if="!location.checked"
                    class="fas fa-square me-2 text-primary-shadow"
                  ></i>
                  <i
                    v-if="location.checked"
                    class="fas fa-check-square text-primary me-2"
                  ></i>
                </a>
              </div>
            </div>
          </li>
        </ul>

        <div class="d-grid gap-2">
          <button class="btn btn-primary mt-5" @click="doneSelectLocations">Done</button>
        </div>
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import AddMemberController from "./AddMemberController";
export default AddMemberController;
</script>

<style scoped>
.form-custom {
  color: #434040 !important;
  background-color: #ffffff !important;
  border-color: #d0cfcf !important;
}

.btn-white:hover,
.btn-white:focus {
  background-color: #fff;
  border-color: #ed6125 !important;
}
</style>
