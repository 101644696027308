

export default {
  name: "TimeCounter",
  props: {
    title: '',
    initialSeconds: {
      type: Number | String,
      default: 0
    },
    initialMinutes: {
      type: Number | String,
      default: 0
    },
  },
  watch: {
    // initialTime(newVal) {
    //   console.log('newVal', newVal)
    //   if (newVal) {
    //     this.seconds = newVal.seconds
    //     this.minutes = newVal.minutes
    //   } else {
    //     this.seconds = 0
    //     this.minutes = 0
    //   }
    //   this.setupTimer()
    // }
  },
  mounted() {
    console.log('INITIAL SECONDS', this.initialSeconds)
    this.seconds = this.initialSeconds
    this.minutes = this.initialMinutes
    this.setupTimer()
  },
  data() {
    return {
      minutes: 0,
      seconds: 0,
      interval: null
    }
  },
  methods: {
    setupTimer() {
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        if (this.seconds == 59) {
          this.seconds = 0
          this.minutes++
        } else {
          this.seconds++
        }
      }, 1000)
    }
  },
  destroyed() {
    clearInterval(this.interval)
  },
};

