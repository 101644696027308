import * as AgoraRtc from '@/assets/js/plugins/agorartc/agoraRTC.js';
import TimeCounter from "@/components/TimeCounter/TimeCounter";
import { Modal } from 'bootstrap';
import "cropperjs/dist/cropper.css";
import VueCropper from "vue-cropperjs";
import { required } from 'vuelidate/lib/validators';

export default {
    mounted() {
        window.onbeforeunload = function () {
            console.log('unloading')
            this.localStream?.close()
            this.agoraClient?.leave()
        }
        this.agoraClient = AgoraRtc.createClient({
            mode: 'rtc',
            codec: 'vp8'
        })
        this.checkAppointmentToken()
        this.getAppointment()
    },
    destroyed() {
        console.log('destroying')
        this.localStream?.close()
        this.agoraClient?.leave()
    },
    computed: {
        userSession() {
            return this.$store.state.userSession
        },
    },
    data() {
        return {
            appointment: {},
            initialMinutes: 0,
            initialSeconds: 0,
            meetingStartTime: this.$moment(),
            token: '',
            channel: '',
            studentName: '',
            appId: process.env.VUE_APP_AGORA_APP_ID,
            hostLeft: false,
            localShareScreen: null,
            localStream: null,
            agoraClient: null,
            myControls: {
                videoEnabled: true,
                micEnabled: true,
                screenShareEnabled: false,
            },
            guestControls: {
                videoEnabled: true,
                micEnabled: true,
            },
            waitingGuest: true,
            showCounter: false
        };
    },
    components: {
        VueCropper,
        TimeCounter
    },
    validations: {
        form: {
            bio: { required },
            opt1: { required },
            opt2: { required },
            opt3: { required },
            opt4: { required },
        }
    },
    methods: {
        async getAppointment() {
            try {
                const res = await this.$agendaService.showAppointment(this.$route.params.id)
                let it = res.data.data
                this.$set(this, 'studentName', it.student.name)
                this.$set(this, 'appointment')
                if (it.status != 'ACCEPTED') {
                    this.$router.push({ path: '/not-found' })
                }
            } catch (e) {
                console.log(e)
            } finally {
            }
        },
        async checkAppointmentToken() {
            console.log('checkAppointmentToken')
            try {
                this.loadingCheck = true
                const res = await this.$agendaService.checkAgoraToken(this.$route.params.id)
                console.log('res', res.date)
                this.token = res.token
                this.channel = res.channel
                this.meetingStartTime = this.$moment(res.date)
                let totalSeconds = this.$moment().diff(this.meetingStartTime, 's')
                console.log('TOTAL SECONDS', totalSeconds)
                console.log('START TIME', this.meetingStartTime)
                const s = parseInt(totalSeconds % 60)
                const m = parseInt(totalSeconds / 60)
                this.initialSeconds = s.toString()
                this.initialMinutes = m.toString()
                this.setupAgora()
                this.showCounter = true

            } catch (e) {
                console.log(e)
            } finally {
                this.loadingCheck = false
            }
        },
        setupAgora() {
            this.agoraClient.init(this.appId)
            this.agoraClient.join(this.token, this.channel, null, (uid) => {
                this.joinHostChannel({ video: true, audio: true })
            }, (err) => {
                console.log('ERRO 3', err)
            })
            this.setupAgoraCallbacks()
        },
        setupAgoraCallbacks() {
            this.agoraClient.on('stream-published', (evt) => {
                console.log('PUUUUBLISHEEEDDDD')
            })
            this.agoraClient.on('peer-leave', (evt) => {
                console.log(evt)
                console.log(`player-${evt.uid}`)
                document.getElementById(`player_${evt.uid}`).remove()
            })

            // OK
            this.agoraClient.on('unmute-video', (evt) => {
                this.guestControls.videoEnabled = true
            })
            // OK
            this.agoraClient.on('mute-video', (evt) => {
                this.guestControls.videoEnabled = false
            })
            // OK
            this.agoraClient.on('unmute-audio', (evt) => {
                this.guestControls.micEnabled = true
            })
            // OK
            this.agoraClient.on('mute-audio', (evt) => {
                this.guestControls.micEnabled = false
            })
            this.agoraClient.on('stream-added', (evt) => {
                console.warn('STREAM ADDED')
                this.agoraClient.subscribe(evt.stream, (err) => { console.log('ERRO 4', err) })
            })
            this.agoraClient.on('stream-subscribed', (evt) => {
                this.waitingGuest = false
                let stream = evt.stream
                stream.play('guest-video-container')
                this.agoraClient.subscribe(evt.stream, (err) => { console.log('ERRO 4', err) })
            })
        },

        joinHostChannel(confs) {
            this.localStream = AgoraRtc.createStream(confs)
            this.localStream.init(() => {
                this.localStream.play('my-video-display')
                this.agoraClient.publish(this.localStream)
                // this.localStream.unmuteAudio()
            }, (err) => { console.log('ERRO 2', err) })
        },
        toggleVideo() {
            this.myControls.videoEnabled = !this.myControls.videoEnabled
            if (this.myControls.videoEnabled) {
                this.localStream.unmuteVideo()
            } else {
                this.localStream.muteVideo()
            }
        },
        toggleMic() {
            try {
                this.myControls.micEnabled = !this.myControls.micEnabled
                if (this.myControls.micEnabled) {
                    this.localStream.unmuteAudio()
                } else {
                    this.localStream.muteAudio()
                }
            } catch (e) { }
        },
        toggleShareScreen() {
            if (!this.myControls.screenShareEnabled) {
                let newClient = AgoraRtc.createClient({
                    mode: 'rtc',
                    codec: 'vp8'
                })

                newClient.init(this.appId)
                newClient.join(this.token, this.channel, null, (uid) => {
                    this.localShareScreen = AgoraRtc.createStream({ video: false, audio: true, screen: true })

                    this.localShareScreen.init(() => {
                        this.localShareScreen.play('screen-share')
                        this.agoraClient.publish(this.localShareScreen)
                        this.myControls.screenShareEnabled = true
                        this.localShareScreen.on('stopScreenSharing', (evt) => {
                            this.myControls.screenShareEnabled = false
                        })
                    }, (err) => { console.log('ERRO 2', err) })
                }, (err) => {
                    console.log('ERRO 3', err)
                })


            } else {
                this.localShareScreen.close()
                this.myControls.screenShareEnabled = false
            }
        },

        async leaveMeeting() {
            this.$eventBus.$emit("showConfirmationAlert", {
                message: "Are you sure you want to leave the meeting?",
                confirmCallback: async () => {
                    this.localStream.close()
                    this.hostLeft = true
                    await this.$agendaService.completeAppointment(this.$route.params.id)
                }
            })
            // if (confirm('Are you sure you want to leave the meeting?')) {
            //     this.localStream.close()
            //     this.hostLeft = true
            //     await this.$agendaService.completeAppointment(this.$route.params.id)
            // }
        },
        setImage(e) {
            this.imageModal = new Modal(document.getElementById('imageModal'))
            this.imageModal.toggle()
            const file = e.target.files[0];
            if (file.type.indexOf("image/") === -1) {
                alert("Please select an image file");
                return;
            }
            if (typeof FileReader === "function") {
                const reader = new FileReader();
                reader.onload = (event) => {
                    this.imgSrc = event.target.result;
                    // rebuild cropperjs with the updated source
                    this.$refs.cropper.replace(event.target.result);
                };
                reader.readAsDataURL(file);
            } else {
                alert("Sorry, FileReader API not supported");
            }
        },
        closeVideoModal() {
            this.videoModal.toggle()
        },
        nextStep(s) {
            this.step = s
            this.percentage = this.percentage + 25
        },
        handleContinue() {
            this.$router.push('/')
        }
    },
};