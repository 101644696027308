
import Vue from "vue"
import Vuex from "vuex"
import NetworkService from "@/services/NetworkService"

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    userSession: JSON.parse(localStorage.getItem('userSession')),
    token: null
  },
  mutations: {
    saveSession(state, newSession) {
      state.userSession = newSession
      localStorage.setItem('userSession', JSON.stringify(newSession))
    },
    saveToken(state, token) {
      state.token = token
      localStorage.setItem('token', token)
    },
    clearSession(state) {
      state.userSession = null
      state.token = null
      localStorage.removeItem('token')
      localStorage.removeItem('userSession')

    }
  },
  actions: {
    syncSessionWithApi: async ({ commit }, { token } = { token: null }) => {
      const network = new NetworkService()
      console.log('TOKEN', token)
      const account = await network.get(network.endpoints.User.account, token)
      const resCredits = await network.get(network.endpoints.Financial.deposits, token)
      let credits = 0
      let totalCreditsUsed = resCredits.data.data[0] ? resCredits.data.data[0].usedCredits : 0
      resCredits.data.data.map((a) => { credits += a.totalCredits })
      const institution = account.data.data.profile.institution
      console.log('INSTITUTION ACCOUNT', account.data.data)
      console.log('INSTITUTION', institution)
      const response = {
        ...account.data.data,
        // email: account.data.data.email,
        id: account.data.data._id,
        profileId: account.data.data.profile._id,
        isProfileCompleted: institution.location ? true : false,
        image: account.data.data.image,
        credits: credits - totalCreditsUsed,
      }

      console.log(response)
      console.log('response', response)
      if (token) {
        commit('saveToken', token)
      }
      commit('saveSession', response)
      return response
    }
  }
});