<template>
  <div>
    <Sidebar />
    <NavBar current="Account" />
    <LoadingContainer v-if="loadingAll" />
    <Container col="col-12 col-lg-10 col-xl-10" v-else>
      <div class="header mt-md-4">
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="header-pretitle">Account</h6>
              <h1 class="header-title">{{ account.name }}</h1>
            </div>
            <div class="col-auto">
              <button v-if="isEditing" class="btn btn-link text-primary" @click="disableEditing()">Cancel editing</button>
              <button v-else class="btn btn-primary" @click="enableEditing()">Edit</button>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col">
              <ul class="nav nav-tabs nav-overflow header-tabs">
                <li class="nav-item cursor-pointer">
                  <a
                    class="nav-link"
                    @click.prevent="selectedTab = 0"
                    :class="{ active: selectedTab == 0 }"
                    id="info-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#info"
                    type="button"
                    role="tab"
                    aria-controls="info"
                    aria-selected="true"
                  >
                    Information
                  </a>
                </li>
                <li class="nav-item cursor-pointer">
                  <a
                    class="nav-link"
                    @click.prevent="selectedTab = 1"
                    :class="{ active: selectedTab == 1 }"
                    id="password-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#password"
                    type="button"
                    role="tab"
                    aria-controls="password"
                    aria-selected="true"
                  >
                    Password
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- INFORMATION TAB -->

      <div class="tab-content" id="myTabContent">
        <div
          class="table-responsive mt-5 tab-pane show fade active"
          id="info"
          role="tabpanel"
          aria-labelledby="info-tab"
        >
          <form>
            <div class="row justify-content-between align-items-center mb-5">
              <div class="col">
                <!-- <div class="row align-items-center">
                  <div class="col-auto">
                    <div class="avatar avatar-xl">
                      <img
                        class="avatar-img rounded-circle"
                        src="../../assets/img/logo.jpg"
                        alt="..."
                      />
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
            <div v-if="isEditing" class="row">
              <div class="col-6 col-md-6">
                <div class="form-group">
                  <label class="form-label"> Email address </label>
                  <input
                    type="text"
                    v-model.trim="$v.dataForm.email.$model"
                    :class="{
                      'is-invalid': $v.dataForm.email.$error,
                    }"
                    :placeholder="'Email address'"
                    class="form-control"
                  />
                  <div class="invalid-feedback">
                    <small>Please, fill up this field correctly</small>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <div class="row">
                    <div class="col-6">
                      <label class="form-label"> Country </label>
                      <button
                        id="btnGroupDrop1"
                        type="button"
                        class="btn btn-white dropdown-toggle text-start d-flex justify-content-between align-items-center"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style="
                          width: 100%;
                          height: 41px;
                          border: 1px solid #d0cfcf;
                          border-radius: 0.375rem !important;
                          font-weight: 400 !important;
                        "
                      >
                        {{ eventLabel }}
                      </button>
                      <ul
                        class="dropdown-menu dropdown-fixed-height"
                        aria-labelledby="btnGroupDrop1"
                        style="min-width: 250px"
                      >
                        <li class="w-100 me-0 position-absolute" style="top: 0">
                          <input
                            @input="searchCountry"
                            class="form-control border-0 border-bottom ps-4"
                            type="text"
                            placeholder="Search..."
                          />
                        </li>
                        <li
                          class="dropdown-item cursor-pointer"
                          v-for="(e, i) in countryPhoneCodes"
                          :key="i"
                          :class="{ 'pt-5': i == 0 }"
                          @click="setPhoneCountry(e)"
                        >
                          {{ e.countryName }}
                        </li>
                      </ul>
                      <div class="invalid-feedback">
                        <small>Please, fill up this field correctly</small>
                      </div>
                    </div>
                    <div class="col-6">
                      <label class="form-label"> Phone </label>
                      <div class="input-group input-group-merge input-group-reverse mb-3">
                        <input
                          class="form-control"
                          v-model.trim="$v.dataForm.phone.$model"
                          :class="{ 'is-invalid': $v.dataForm.phone.$error }"
                          type="text"
                          maxlength="12"
                          placeholder="1234567890"
                        />
                        <div class="input-group-text text-dark" id="inputGroupReverse">
                          <span>{{ dataForm.phoneCode }}</span>
                        </div>
                      </div>
                      <div class="invalid-feedback">
                        <small>Please, fill up this field correctly</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="col-12 col-md-6">
                <div class="form-group">
                  <label class="form-label"> Phone </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="$v.dataForm.phone.$model"
                    :class="{
                      'is-invalid': $v.dataForm.phone.$error,
                    }"
                    :placeholder="'Phone'"
                  />
                </div>
              </div> -->

              <div class="col-12">
                <button @click.prevent="handleSave()" class="btn w-100 btn-primary my-3">
                  <span
                    v-if="loadingEditing"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Save
                </button>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label class="form-label"> Email address </label>
                  <input
                    type="email"
                    class="form-control"
                    disabled
                    :value="account.email"
                  />
                </div>
              </div>
              <div
                class="col-12 col-md-6"
                v-if="account.profile && account.profile.institution"
              >
                <div class="form-group">
                  <label class="form-label"> Phone </label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    :value="account.profile.institution.contact.phone"
                  />
                </div>
              </div>
            </div>
            <hr class="mt-4 mb-5" />
            <div class="row justify-content-between">
              <div class="col-12 text-center">
                <span class="text-danger text-center">{{
                  deleteAccountErrorMessage
                }}</span>
              </div>
              <div class="col-12 col-md-6">
                <h3 class="mb-1">
                  <strong> Close Account </strong>
                </h3>
                <p>Delete your account and all your data</p>
              </div>
              <div class="col-auto">
                <button
                  @click.prevent="deleteAccount"
                  class="btn btn-danger"
                  :disabled="loadingDeleteAccount"
                >
                  <span
                    v-if="loadingDeleteAccount"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Close Account
                </button>
              </div>
            </div>
          </form>
        </div>

        <!-- PASSWORD -->
        <div
          class="table-responsive mt-5 tab-pane fade active"
          id="password"
          role="tabpanel"
          aria-labelledby="password-tab"
        >
          <div>
            <div class="form-group">
              <label class="form-label">Old Password</label>
              <input
                type="password"
                v-model.trim="$v.form.password.$model"
                :class="{ 'is-invalid': $v.form.password.$error }"
                placeholder="Enter old password"
                class="form-control"
              />
              <div class="invalid-feedback">
                <small>Please, fill up this field correctly</small>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label">New Password</label>
              <input
                type="password"
                v-model.trim="$v.form.newPassword.$model"
                :class="{ 'is-invalid': $v.form.newPassword.$error }"
                placeholder="Enter new password"
                class="form-control"
              />
              <div class="invalid-feedback">
                <small>Minimum 8 characters</small>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <span class="text-danger text-center">{{ errorMessage }}</span>
              </div>
            </div>
            <div class="d-grid gap-2">
              <button
                class="btn btn-primary mt-3"
                @click="changePassword"
                :disabled="$v.$invalid || loading"
              >
                <span
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Change
              </button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import AccountController from "./AccountController";
export default AccountController;
</script>

<style scoped></style>
