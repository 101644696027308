<template>
  <div>
    <label>{{label}}</label>

    <div class="base-select-wrapper">
      <select
        :id="id"
        :class="[
          'base-select',
          hasError ? 'base-select-error' : null,
          value == '' ? 'base-select-empty' : null
        ]"
        :disabled="disabled"
        :value="value"
        @change="$emit('input', $event.target.value)"
      >
        <option value="" class="default-option" selected disabled>
          {{ placeholder }}
        </option>
        <option
          v-for="(option, index) in options"
          :value="getValue(option)"
          :key="`option-${index}`"
          :selected="getValue(option) === value"
        >
          {{ getValue(option, 'name') }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSelect',

  props: {
    id: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Boolean],
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Select an option'
    },
    hasError: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },

  methods: {
    getValue(option, key = 'value') {
      return typeof option === 'string' ? option : option[key]
    }
  }
}
</script>

<style lang="scss" scoped>
.base-select-wrapper {
  font-size: 17px;
  border-radius: 6px;
  border: solid 1px #E3EBF6;
  background-color: #FFF;
  width: 100%;
  height: 60px;

  .base-select {
    padding: 0 50px 0 20px;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    border: none;
    background-color: white;
    font-weight: 500;
    appearance: none;
    color: #4a4a4a;
    background: url("data:image/svg+xml;utf8,<svg fill='1c2e36' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
      no-repeat calc(100% - 20px);

    &-error {
      border: 1px solid red;
    }

    &-empty {
      color: #4a4a4a;
    }

    option {
      color: #4a4a4a;
      width: 100%;
      appearance: none;
      font-weight: 500;

      &.default-option {
        display: none;
      }
    }
  }
}
</style>
