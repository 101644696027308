import PrivateContent from "@/components/PrivateContent.vue";
import PrivateField from "@/components/PrivateField.vue";
import PrivateExams from "@/components/PrivateExams.vue";
import PrivateFieldMultiselect from "@/components/PrivateFieldMultiselect.vue";
import ReadOnlyInput from "@/components/ReadOnlyInput.vue";

export default {
  name: "Signin",
  created() {
    this.loadingAll = true
    this.getData()
  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
  },
  data() {
    return {
      loadingAll: false,
      loadingAccept: false,
      loadingMessage: false,
      errorMessage: '',
      accepted: false,
      studentId: this.$route.params.id,
      request: this.$route.query.request ? JSON.parse(this.$route.query.request) : null,
      student: {},
      universities: [],
      questions: [
        { id: 1, title: 'Question 1', answer: 'Answer to Question 1' },
        { id: 2, title: 'Question 2', answer: 'Answer to Question 2' },
        { id: 3, title: 'Question 3', answer: 'Answer to Question 3' },
        { id: 4, title: 'Question 4', answer: 'Answer to Question 4' },
      ],
    };
  },
  methods: {
    getInvestmentPerYear(investmentPerYear) {
      return `${investmentPerYear.currency} ${this.$options.filters.numeral(investmentPerYear.value, '0,0[.]00')}` 
    },
    async getOrCreateMessage() {
      this.loadingMessage = true
      try {
        const { data } = await this.$messagesService.sendMessage({ 
          student: this.studentId
        })

        this.$router.push({ path: `${this.$paths.messages.chat}/${data.data._id}` })
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingMessage = false
      }
    },
    async getData() {
      try {
        const res = await this.$socialService.getStudent(this.studentId)
        let item = res.data.data
        this.$set(this, 'student', item)
        if (!item.student.name) {
          item.student.name = `${item.student.profile.firstname} ${item.student.profile.lastname}`
        }
        this.$set(this, 'accepted', item.isFollower)
        this.$set(this, 'universities', item.followingUniversities)
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingAll = false
      }
    },
    async accept() {
      if (this.isFollower) return
      if (this.userSession.credits < this.request.costToAccept) {
        this.$eventBus.$emit("toggleWalletModal");
        return
      }
      try {
        this.errorMessage = ''
        this.loadingAccept = true
        const res = await this.$audienceService.acceptRequests([this.student.userFollowRequest._id])
        this.$eventBus.$emit('showToast', { title: `Follower Accepted`, msg: `${this.request.costToAccept} credits were deducted from your wallet.` })
      } catch (e) {
        console.log(e)
        this.errorMessage = e.message
      } finally {
        this.loadingAccept = false
        this.getData()
      }
    },
    getFieldLevelOfImportance(field) {
      field = this.student.student.profile.fieldsLevelOfImportance.find((f) => f.field == field);
      return field ? field.level : ''
    },
    getCountriesOfInterest() {
      return this.student.student.profile.locationOfInterest.map((location) => {
        return location.country
      })
    }
  },
  components: {
    PrivateField,
    PrivateExams,
    PrivateFieldMultiselect,
    PrivateContent,
    ReadOnlyInput,
  },
};