const GooglePlaceAutocomplete = {
  install(Vue) {
    new Promise(resolve => {
      const mapsScript = document.getElementsByTagName('script')[0]
      if (document.getElementById('google-place-autocomplete')) {
        resolve()
      }
      const js = document.createElement('script')
      js.id = 'google-place-autocomplete'
      js.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_API_KEY}&libraries=places&language=en`
      mapsScript.parentNode.insertBefore(js, mapsScript)
      js.onload = () => {
        // Create the event
        var event = new Event("google-autocomplete-loaded");

        // Dispatch/Trigger/Fire the event
        document.dispatchEvent(event);

        resolve()
      }
    })
    Vue.directive('map', {
      bind(el, binding) {
        el.map = new window.google.maps.Map(el, {
          center: {
            lat: binding.value.center.lat,
            lng: binding.value.center.lng
          },
          zoom: binding.value.mode == 'macro' ? 12 : 16,
          disableDefaultUI: true
        })
        binding.value.markers.forEach(marker => {
          new window.google.maps.Marker({
            position: { lat: marker.lat, lng: marker.lng },
            map: el.map
          })
        })
      },
      update(el, binding) {
        el.map.panTo({
          lat: binding.value.center.lat,
          lng: binding.value.center.lng
        })
        el.map.setZoom(16)
      }
    })
  }
}

export default GooglePlaceAutocomplete
