<template>
  <div>
    <Sidebar />
    <NavBar current="Add Credits" />

    <div class="main-content">
      <div class="container-fluid bg-white vh-100">
        <div class="row">
          <div class="col-12 col-md-4 justify-content-center offset-md-4 py-6">
            <h1 class="header-title">Add credits</h1>
            <div class="progress my-5">
              <div
                class="progress-bar"
                role="progressbar"
                :style="`width: ${percentage}%`"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>

            <!-- STEP 1 -->
            <div v-if="step == 1">
              <p><strong>1. Choose amount</strong></p>

              <div class="row">
                <div
                  class="col-12 col-lg-12 cursor-pointer"
                  v-for="item in prices"
                  v-bind:key="item.id"
                  @click="handleItemPressed(item)"
                >
                  <div
                    class="card border-2"
                    :class="{
                      'border-primary': item.selected,
                      'border-muted': !item.selected,
                    }"
                  >
                    <div class="card-body">
                      <div class="row align-items-center gx-0">
                        <div class="col">
                          <span class="h3">{{ item.credits }} credits</span>
                          <h5 class="mb-2 text-primary">
                            <strong>Cost Us${{ item.amount }}</strong>
                            <span
                              v-if="item.discount > 0"
                              class="badge bg-primary-soft ms-2"
                              >{{ item.discount }}% off</span
                            >
                          </h5>
                        </div>
                        <div class="col-auto">
                          <span
                            v-if="item.selected"
                            class="h2 fas fa-check-circle mb-0 text-primary"
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary w-100 mt-5" @click="nextStep(2)">
                Continue
              </button>
            </div>
            <!-- END STEP 1 -->

            <!-- STEP 2 -->
            <div v-if="step == 2">
              <p><strong> 2. Payment Details </strong></p>

              <!-- <div class="row">
                <div
                  class="col-12 col-lg-12 cursor-pointer"
                  @click="paymentMethod = 'bank_transfer'"
                >
                  <div class="card border-0 shadow-none">
                    <div class="card-body px-0 pb-0">
                      <div class="row align-items-center gx-0">
                        <div class="col">
                          <span class="h3">Bank transfer</span>
                        </div>
                        <div class="col-auto">
                          <span
                            v-if="paymentMethod == 'bank_transfer'"
                            class="h2 fas fa-check-circle mb-0 text-primary"
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="m-0" /> -->
              <!-- <div class="row">
                <div
                  class="col-12 col-lg-12 cursor-pointer"
                  @click="paymentMethod = 'credit_card'"
                >
                  <div class="card border-0 shadow-none">
                    <div class="card-body px-0">
                      <div class="row align-items-center gx-0">
                        <div class="col">
                          <span class="h3">Credit card</span>
                        </div>
                        <div class="col-auto">
                          <span
                            v-if="paymentMethod == 'credit_card'"
                            class="h2 fas fa-check-circle mb-0 text-primary"
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->

              <div class="form-group">
                <label>Name</label>
                <input
                  type="text"
                  v-model.trim="$v.form.paymentDetails.holder.$model"
                  :class="{ 'is-invalid': $v.form.paymentDetails.holder.$error }"
                  placeholder="Name on card"
                  class="form-control"
                />
                <div class="invalid-feedback">
                  <small>Please, fill up this field correctly</small>
                </div>
              </div>

              <div class="form-group">
                <label>Card</label>
                <div class="input-group input-group-merge">
                  <input
                    type="text"
                    v-model.trim="$v.form.paymentDetails.cardNumber.$model"
                    placeholder="Card number"
                    class="form-control"
                    :class="{ 'is-invalid': $v.form.paymentDetails.cardNumber.$error }"
                    v-mask="'#### #### #### ####'"
                  />
                  <span class="input-group-text">
                    <i class="fas fa-credit-card"></i>
                  </span>
                <div class="invalid-feedback">
                  <small>Please, fill up this field correctly</small>
                </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-6">
                  <label>Expiry</label>
                  <input
                    type="text"
                    v-model.trim="$v.form.paymentDetails.expiration.$model"
                    :class="{ 'is-invalid': $v.form.paymentDetails.expiration.$error }"
                    placeholder="MM/YYYY"
                    v-mask="'##/####'"
                    class="form-control"
                  />
                  <div class="invalid-feedback">
                    <small>Please, fill up this field correctly</small>
                  </div>
                </div>

                <div class="form-group col-6">
                  <label>CVV</label>
                  <input
                    type="text"
                    v-model.trim="$v.form.paymentDetails.cvv.$model"
                    :class="{ 'is-invalid': $v.form.paymentDetails.cvv.$error }"
                    placeholder="123"
                    v-mask="'####'"
                    class="form-control"
                  />
                  <div class="invalid-feedback">
                    <small>Please, fill up this field correctly</small>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-primary w-100 mt-5"
                @click.prevent="handleCreateDeposit()"
                :disabled="$v.form.paymentDetails.$invalid"
              >
                <span
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Add credits
              </button>
            </div>
            <!-- END STEP 2 -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddCreditsController from "./AddCreditsController";
export default AddCreditsController;
</script>
<style scoped>
.border-muted {
  border-color: #d9d9d9 !important;
}
</style>
