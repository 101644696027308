<template>
  <div class="brochures">
    <div class="search-table-form">
      <div class="row align-items-center">
        <div class="col">
          <form>
            <div
              class="
                input-group
                input-group-flush
                input-group-merge
                input-group-reverse
              "
            >
              <input
                class="form-control list-search"
                type="search"
                @input="searchString"
                placeholder="Search"
              />
              <span class="input-group-text">
                <i class="fas fa-search ps-3"></i>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
     <EmptyState
      title="No Brochures"
      msg="It looks like you have no brochures"
      v-if="items.length == 0"
    >
      <template v-slot:button>
        <router-link tag="button" :to="$paths.university.brochure.add" type="button" class="btn btn-primary">
          <i class="fas fa-plus-circle"></i>
          Add Brochure
        </router-link>
      </template>
    </EmptyState>
    <table
      v-if="items.length > 0"
      class="table table-sm table-hover table-nowrap card-table bg-white"
    >
      <thead>
        <tr>
          <th
            v-for="(column, index) in columns"
            :key="column"
            :class="{ 'text-start': index <= 1 }"
            :width="index == 0 ? 'auto' : '1%'"
          >
            {{ column }}
          </th>
          
        </tr>
      </thead>
      <tbody class="list">
        <tr v-for="item in showingItems" v-bind:key="item.id" class="cursor-pointer">
          <td @click="itemPressed(item)" class="text-start">
            <strong>{{item.title}}</strong>
          </td>
          <td @click="itemPressed(item)">{{item.views}}</td>
          <td @click="itemPressed(item)">{{item.createdAt ? $moment(item.createdAt).format('MMM DD, YYYY') : '---'}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import BrochuresController from "./BrochuresController";
export default BrochuresController;
</script>