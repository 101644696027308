<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-12 my-5">
        <div class="text-center">
          <slot name="icon"></slot>
          <h1 class="display-4 my-3">
            {{ title || "No items found" }}
          </h1>
          <p class="text-muted mb-3">
            {{ msg || "There are no items to show" }}
          </p>
          <slot name="button"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Emptystate",
  props: {
    title: String,
    msg: String,
  },
};
</script>


