import Container from "@/components/Container.vue";
import EmptyState from "@/components/EmptyState.vue";
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/Sidebar.vue";
import moment from "moment";
import { email, required } from "vuelidate/lib/validators";
import LoadingContainer from "@/components/LoadingContainer.vue";



export default {
    name: 'Team',
    mounted() {
        this.loadingAll = true
        this.getData()
    },
    data() {
        return {
            loadingAll: true,

            showingItems: [],
            columns: [
                'Name',
                'Email',
                'Location',
                'Created',
                'Role',
            ],
            walletModal: null,
            allSelected: false,
            filtersCount: 0,
            selectedTab: 0,
            items: [],
            filters: {
                match: {
                    min: 20,
                    max: 100
                },
                school_type: {
                    public: false,
                    private: false
                },
                location: {
                    us: false,
                    other_countries: false
                },

            },

        };
    },
    methods: {
        getData() {
            this.$teamService.getMembers()
                .then(res => {
                    const it = res.data.data.map(r => {
                        return {
                            ...r,
                            created: moment(r.createdAt).format('MMM, DD YYYY'),
                            locationsString: r.locations.join(', ')
                        }
                    })
                    this.items = it
                    this.showingItems = it
                }).catch(e => console.log(e)).finally(() => this.loadingAll = false)
        },
        handleAdd() {
            this.$router.push({ path: this.$paths.team.add })
        },
        toggleSelectAll() {
            this.allSelected = !this.allSelected
            this.items = this.items.map(r => { return { ...r, selected: this.allSelected } })
        },
        clearSelected() {
            this.allSelected = false
            this.items = this.items.map(r => { return { ...r, selected: false } })
        },
        toggleSelectItem(item) {
            this.items = this.items.map(r => {
                return { ...r, selected: r.id == item.id ? !item.selected : r.selected }
            })
            this.allSelected = false
        },
        itemPressed(item) {
            this.$router.push({ path: `${this.$paths.team.detail}/${item._id}` })
        },
        toggleWalletModal() {
            this.walletModal.toggle()
        },
        filterChanged() {
            const schoolCount = Object.keys(this.filters.school_type).filter(s => this.filters.school_type[s]).length
            const locationCount = Object.keys(this.filters.location).filter(s => this.filters.location[s]).length
            const matchCount = (this.filters.match.min != 20 || this.filters.match.max != 100) ? 1 : 0
            this.filtersCount = schoolCount + locationCount + matchCount
        },
        goToAddCredits() {
            this.walletModal.toggle()
            this.$router.push({ path: this.$paths.wallet.add_credits })
        },
        searchString(e) {
            const it = this.$helper.searchString(this.items, e.target.value)
            this.showingItems = it.filter((ftr) => !ftr.hidden)
        }
    },
    validations: {
        email: {
            required,
            email,
        },
    },
    components: {
    },
}