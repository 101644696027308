<template>
  <div>
    <Sidebar />
    <NavBar current="Edit Availability" :link="$paths.agenda.list" linkName="Diary" />
    <!-- <LoadingContainer v-if="loadingAll" /> -->
    <Container col="col-12 col-lg-10 col-xl-10 ">
      <div class="row d-flex justify-content-center mt-6">
        <Availability v-on:saveAvailability="saveAvailability" :existingAgenda="agenda"  />
      </div>
    </Container>
  </div>
</template>

<script>
import EditAvailabilityController from "./EditAvailabilityController";
export default EditAvailabilityController;
</script>
