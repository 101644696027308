<template>
  <div>
    <div
      v-if="uploading"
      class="border border-start-0 border-end-0 d-flex align-items-center py-3"
    >
      <i class="fas fa-file-image text-primary display-3"></i>
      <div class="w-100 mx-4">
        <div class="d-flex justify-content-between">
          <strong> {{ fileName }} </strong>
          <strong class="text-primary">{{ percentage }}%</strong>
        </div>
        <div class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            :style="`width: ${percentage}%`"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>

      <i class="fas fa-times display-4 text-light" @click="cancelUpload"></i>
    </div>
    <div class="form-control-custom mb-5" v-else>
      <input
        ref="input"
        type="file"
        name="image"
        :accept="accept"
        @change="setImage"
        :style="{ width: customFileInputWidth + 'px' }"
      />

      <div class="text" v-if="!cropImg">
        <i class="fas fa-file-upload display-4 mb-3"></i>
        <h2 class="h2">
          <span v-if="accept == 'image/*'"> Drag and drop image here, or </span>
          <span v-else> Drag and drop PDF(s) here, or </span>
          <span class="text-primary"> Browse </span>
        </h2>
        <span>Max File Size: 2mb</span>
      </div>

      <img
        :src="cropImg"
        v-if="cropImg && accept == 'image/*'"
        width="200"
        :height="aspectRatio == 1 ? 200 : 115"
        class="rounded"
      />
      <img
        v-if="cropImg && accept == '.pdf'"
        src="@/assets/img/pdf_placeholder.png"
        width="200"
        height="200"
        class="rounded"
      />
    </div>
    <ModalComponent title="Crop Image" id="imageModal" v-on:modalClosed="cropClosed">
      <template v-slot:body>
        <vue-cropper
          :modal="false"
          :movable="false"
          :zoomable="false"
          ref="cropper"
          :src="imgSrc"
          alt="Source Image"
          :aspect-ratio="aspectRatio"
          :minContainerWidth="550"
          :minContainerHeight="$helper.cropMinHeight"
        >
        </vue-cropper>
      </template>
      <template v-slot:footer>
        <button type="button" @click="cropImage" class="btn btn-primary">
          Crop & Upload
        </button>
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import ImageCropperController from "./ImageCropperController";
export default ImageCropperController;
</script>

<style scoped>
input[type="file"] {
  opacity: 0;
  height: 200px;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}
.form-control-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  opacity: 0.9;
  transition: 0.2s;
  width: 100%;
  height: 200px;
  border: 2px #ecebeb dashed;
}
.form-control-custom .text {
  position: absolute;
  color: #a19f9f;
  text-align: center;
}
.btn-white:hover,
.btn-white:focus {
  background-color: #fff;
  border-color: #ed6125 !important;
}
</style>
