<template>
  <div>
    <img
      v-if="src && !showPlaceholder"
      :src="src"
      alt=""
      @error="imgError"
      class="rounded-circle me-2"
      :style="`width: ${width}; height: ${height}`"
    />
    <div v-if="!src || showPlaceholder">
      <slot name="placeholder"> </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivateField",
  props: {
    src: String,
    width: {
      type: String,
      default: "32px",
    },
    height: {
      type: String,
      default: "32px",
    },
  },
  data() {
    return {
      showPlaceholder: false,
    };
  },
  methods: {
    imgError() {
      this.showPlaceholder = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
input,
.input-group-text {
  background-color: #ececec;
}
</style>
