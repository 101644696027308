<template>
  <div :class="['radio-group', { horizontal }]" >
    <div :key="index" v-for="(option, index) in options" class="radio-item">
      <BaseFormRadio
        class="radio-option"
        :hasImage="hasImage"
        :checked="(typeof value != 'boolean' ? value === (option[keyValue] || option) : value.toString() === (option[keyValue].toString() || option.toString()))"
        @change="select($event, option)"
        :id="`${_uid}`"
      >
        <template #image v-if="hasImage">
          <template v-if="option.slice(-5) === '.webm'">
            <video class="radio-image" autoplay loop muted playsinline>
              <source :src="option" type="video/webm;codecs=vp9">
            </video>
          </template>
          <template v-else>
            <img class="radio-image" :src="option" />
          </template>
        </template>
        <template #label v-else>
          {{option[keyName] || option}}
        </template>
      </BaseFormRadio>
    </div>
  </div>
</template>
<script>
import BaseFormRadio from './BaseFormRadio'

export default {
  name: 'BaseFormRadioGroup',
  components: {
    BaseFormRadio
  },
  props: {
    value: {
      type: [Object, String, Number, Boolean]
    },
    options: {
      type: Array,
      required: true
    },
    keyName: {
      type: String,
      default: 'name'
    },
    keyValue: {
      type: String,
      default: 'value'
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    hasImage: {
      type: Boolean,
      default: null,
    }
  },
  data() {
    return {}
  },
  methods: {
    select(event, option) {
      if (event.srcElement.checked) {
        if (option.hasOwnProperty(this.keyValue)) {
          return this.$emit('input', option[this.keyValue])
        }
        return this.$emit('input', option)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.radio-group {
  width: 100%;
  margin-top: 20px;

  &.horizontal {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    justify-content: space-evenly;

    .radio-option + .radio-option {
      margin-left: 20px;
    }
    .radio-item{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .radio-image{
    object-fit: cover;
  }
}
</style>
