import NetworkService from '@/services/NetworkService';
import moment from 'moment';

export default class AgendaService {
    constructor() {
        this.network = new NetworkService()
        this.status = {
            'AWAITING_ACCEPT': 'Waiting confirmation',
            'ACCEPTED': 'Accepted',
            'DONE': 'Done',
            'CANCELLED': 'Cancelled',
            'RESCHEDULED': 'Rescheduled',
        }
    }
    fixRescheduledAppointment(appointment) {
        // let lastRescheduleId = appointment._id

        if (
            (appointment.parent && (appointment.status == 'AWAITING_ACCEPT' || (appointment.lastRechedule && appointment.lastRechedule.status == 'AWAITING_ACCEPT'))) || 
            (appointment.lastRechedule && appointment.lastRechedule.status == 'CANCELLED')
            ) {
            return false
        } else {
            return true
        }
    }
    getAgenda() {
        return this.network.get(this.network.endpoints.Agenda.list)
    }
    createAgenda(params) {
        return this.network.post(this.network.endpoints.Agenda.list, params)
    }
    getAppointments(date = '') {
        return new Promise((resolve, reject) => {
            this.network.get(`${this.network.endpoints.Agenda.appointments}/${date}`).then(r => {
                let items = r.data.data.filter((ap) => {
                    return this.fixRescheduledAppointment(ap)
                }).map(r => {
                    return {
                        ...r,
                        lastRescheduleId: r.lastRechedule ? r.lastRechedule._id : r._id
                    }
                })
                resolve({ data: { data: items } })
            }).catch(e => reject(e))
        })
    }
    getAvailableDates(date) {
        return this.network.get(`${this.network.endpoints.Agenda.availableDates}/${date}`)
    }
    showAppointment(id) {
        const url = `${this.network.endpoints.Agenda.appointments}/${id}`
        return new Promise((resolve, reject) => {
            this.network.get(url).then(r => {
                const it = r.data.data
                const item = {
                    ...it,
                    lastRescheduleId: it.lastRechedule ? it.lastRechedule._id : it._id
                }
                resolve({ data: { data: item } })
            }).catch(e => reject(e))
        })
    }
    getRequests(params) {
        return this.network.get(this.network.endpoints.Agenda.requests)
    }
    showRequest(id) {
        const url = `${this.network.endpoints.Agenda.requests}/${id}`
        return this.network.get(url)
    }
    completeAppointment(appointmentId) {
        return this.network.put(`${this.network.endpoints.Agenda.appointments}/${appointmentId}/done`, {})
    }
    makeAppointmentDecision(appointmentId, params) {
        return this.network.put(`${this.network.endpoints.Agenda.appointments}/${appointmentId}/decision`, params)
    }
    async checkAgoraToken(appointmentId) {
        const res = await this.network.makeExternalRequest('get', `${process.env.VUE_APP_PH_BASE_URL}${this.network.endpoints.Calls}?appointment_id=${appointmentId}`)
        let ap = res.data.data
        if (ap.length > 0) {
            ap = ap[0]
            return { token: ap.agora_token, channel: ap.agora_channel, date: ap.created_at }
        } else {
            const res2 = await this.network.makeExternalRequest('post', `${process.env.VUE_APP_PH_BASE_URL}${this.network.endpoints.Calls}`, { appointment_id: appointmentId })
            ap = res2.data.data
            return { token: ap.agora_token, channel: ap.agora_channel, date: ap.created_at }
        }
    }
    cancelAppointment(appointmentId) {
        return this.network.delete(`${this.network.endpoints.Agenda.appointments}/${appointmentId}`)
    }
}
