import moment from "moment";
import { email, required } from "vuelidate/lib/validators";



export default {
    name: 'Messages',
    mounted() {
        this.loadingAll = true
        this.getData()
    },
    computed: {
        userSession() {
          return this.$store.state.userSession;
        },
      },
    data() {
        return {
            loadingAll: false,
            columns: [
                'Name',
                'Last message',
                'Match',
            ],
            walletModal: null,
            allSelected: false,
            filtersCount: 0,
            selectedTab: 0,
            items: [],
            showingItems: [],
        };
    },
    methods: {
        async getData() {
            console.log(this.userSession)
            try {
                const res = await this.$messagesService.getChats()
                const it = res.data.data.map(r => {
                    console.log((r.lastMessage && r.lastMessage.length && r.lastMessage[0].user == this.userSession.id))
                    return {
                        ...r,
                        created: moment(r.createdAt).format('MMM, DD YYYY'),
                        studentName: `${r.student.firstname} ${r.student.lastname}`,
                        lastMessageIsMine: (r.lastMessage && r.lastMessage.length && r.lastMessage[0].user == this.userSession.id)
                    }
                })
                this.$set(this, 'items', it)
                this.$set(this, 'showingItems', it)
            } catch (e) {
                console.log(e)
                this.$set(this, 'items', [])
                this.$set(this, 'showingItems', [])
            } finally {
                this.loadingAll = false

            }

        },
        itemPressed(item) {
            this.$router.push({ path: `${this.$paths.messages.chat}/${item._id}` })
        },
        searchString(e) {
            const it = this.$helper.searchString(this.items, e.target.value)
            this.$set(this, 'showingItems', it.filter((ftr) => !ftr.hidden))
        }
    },
    validations: {
        email: {
            required,
            email,
        },
    },
    components: {
    },
}