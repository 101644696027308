<template>
  <div class="videos">
    <EmptyState
      title="No Videos"
      msg="It looks like you have no videos"
      v-if="items.length == 0"
    >
      <template v-slot:button>
        <router-link
          tag="button"
          :to="$paths.university.addVideo"
          type="button"
          class="btn btn-primary"
        >
          <i class="fas fa-plus-circle"></i>
          Add Video
        </router-link>
      </template>
    </EmptyState>
    <div class="col-12" v-if="items.length > 0">
      <div class="row px-0">
        <div class="col-4" v-for="(item, index) in items" v-bind:key="item.id">
          <div class="">
            <div class="card-body p-0 position-relative">
              <div class="img-preview-container position-absolute">
                <button
                  class="btn btn-white"
                  @click.prevent="deleteItem(index)"
                >
                  <span
                    v-if="loadingDelete[index]"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span v-else class="fas fa-trash"></span>
                </button>
              </div>
              <iframe
                width="100%"
                height="250"
                :src="item.embededUrl"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                style="border-radius: 15px"
              ></iframe>
              <p>{{ item.title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VideosController from "./VideosController";
export default VideosController;
</script>
