import Container from '@/components/Container'
import ModalComponent from '@/components/ModalComponent'
import NavBar from '@/components/NavBar'
import Sidebar from '@/components/Sidebar'
import { Modal } from 'bootstrap'
import { email, required } from 'vuelidate/lib/validators'

export default {
    mounted() {
        setTimeout(() => {
            this.calculateWidth()
        }, 1000);
    },
    created() {
        window.addEventListener("resize", this.calculateWidth);
    },
    data() {
        return {
            errorMessage: '',
            loading: false,
            form: {
                name: '',
                email: '',
                jobTitle: '',
                permissions: '',
                locations: [],
            },
            roles: [
                { id: 'admin', label: 'Admin', },
                { id: 'recruter', label: 'Recruter' }
            ],
            locations: [
                { label: 'Europe', checked: false },
                { label: 'Asia', checked: false },
                { label: 'South America', checked: false },
                { label: 'North America', checked: false },
            ],
            locationSelectModal: null,
            customSelectWidth: 400,
            permissionLabel: 'Select permission'
        }
    },
    components: {
    },
    validations: {
        form: {
            name: { required },
            email: { required, email },
            jobTitle: { required },
            permissions: { required },
            locations: { required }
        }
    },
    methods: {
        async save() {
            this.errorMessage = ''
            this.loading = true
            try {
                const res = await this.$teamService.createMember({ ...this.form })
                this.$eventBus.$emit('showToast', { title: 'New Member Created', msg: `${this.form.name} was created successfully.` })
                this.$router.push({ path: this.$paths.team.list })
            } catch (e) {
                this.errorMessage = e.message
            } finally {
                this.loading = false
            }
        },
        openSelectLocationModal() {
            this.locationSelectModal = new Modal(document.getElementById('location_select_modal'))
            this.locationSelectModal.toggle()
        },
        selectLocation(index) {
            this.locations[index].checked = !this.locations[index].checked
        },
        doneSelectLocations() {
            this.form.locations = this.locations.filter(elem => elem.checked).map(elem => elem.label).join(', ')
            this.$v.form.locations.$touch()
            this.locationSelectModal.toggle()
        },
        calculateWidth() {
            const width = this.$refs.nameInput?.clientWidth
            this.customSelectWidth = width
        },
        setPermission(e) {
            this.form.permissions = e.id
            this.permissionLabel = e.label
        },
    }
}