import NetworkService from '@/services/NetworkService';

export default class SocialService {
    constructor() {
        this.network = new NetworkService()
    }
    getPosts() {
        return this.network.get(this.network.endpoints.Social.posts)
    }
    deletePost(id) {
        return this.network.delete(`${this.network.endpoints.Social.posts}/${id}`)
    }
    getMyPosts() {
        return this.network.get(this.network.endpoints.Social.myPosts)
    }
    async createPost(params) {
        const res = await this.network.createSignedUrl('post', params.image)
        return this.network.post(this.network.endpoints.Social.myPosts, { description: params.description, image: res.signedUrl, timezone: params.timezone })
    }
    getPostComments(postId) {
        const url = `${this.network.endpoints.Social.myPosts}/${postId}/comments`
        return this.network.get(url)
    }
    getStudent(id) {
        const url = `${this.network.endpoints.Audience.students}/${id}`
        return this.network.get(url)
    }
    getPostLikes(postId, params) {
        const url = `${this.network.endpoints.Social.myPosts}/${postId}/likes`
        return this.network.get(url, '', params)
    }
    async createReply(commentId, data) {
        const url = `${this.network.endpoints.Social.myPosts}/comments/${commentId}/replies`
        return this.network.post(url, data)
    }

}
