<template>
  <div class="signin">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-md-5 col-lg-6 col-xl-5 px-lg-6 mb-5 mt-7">
          <img src="@/assets/img/logo.jpg" alt="" class="small-logo mb-6" />
          <div class="my-5">
            <h1 class="header-title mb-4">Sign up</h1>
            <h4 class="text-primary">3. Email confirmation</h4>
          </div>
          <div v-if="success">
            <p>
              <span class="ms-1 fa-3x fas fa-check-circle text-primary"></span>
            </p>
            <h1 class="header-title mb-4">You are all set</h1>
            <small>Thousande of students are waiting to know your university</small>
            <button class="btn w-100 btn-primary my-3" @click="handleGetStarted()">
              Get started
            </button>
          </div>
          <div v-if="!success">
            <p>
              <span class="ms-1 fa-3x fas fa-envelope-open-text text-primary"></span>
            </p>
            <small>We sent a confirmation link to</small>
            <h4 class="mt-2 mb-5">{{ email }}</h4>
            <div class="row">
              <div class="col text-center">
                <span class="text-danger">{{ confirmationErrorMessage }}</span>
              </div>
            </div>
            <button class="btn w-100 btn-primary my-3" @click="handleConfirmation()">
              <span
                v-if="loadingConfirmation"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              I have verified my email
            </button>
            <p class="">
              <small class="text-dark">
                Didn't get the confirmation email?
                <a href="#" @click.prevent="handleResendEmail()">
                  <span v-if="!resend" class="ms-1 fas fa-redo"></span>
                  <span v-if="!resend"> Resend</span>
                  <span v-else class="text-success">We sent you a new email!</span>
                </a>
              </small>
            </p>
          </div>
        </div>
        <div class="col-12 col-md-7 col-lg-6 col-xl-7 d-none d-lg-block">
          <div
            id="bgimg"
            class="bg-cover h-100 min-vh-100 mt-n1 me-n3"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SignupConfirmationController from "./SignupConfirmationController";
export default SignupConfirmationController;
</script>

<style scoped>
#bgimg {
  background-image: url("../../../assets/img/register_bg.png");
}
</style>
