import { minLength, required, email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
export default {
  loadingAll: false,
  name: "Account",
  mounted() {
    this.loadingAll = true;
    this.getAccount();
  },
  data() {
    return {
      loadingAll: false,
      countryPhoneCodes: this.$helper.countryPhoneCodes,
      account: {},
      tab: "info",
      form: {
        password: "",
        newPassword: "",
      },
      dataForm: {
        email: "",
        phone: "",
        phoneCode: "",
      },
      selectedTab: 0,
      errorMessage: "",
      loading: false,

      loadingDeleteAccount: false,
      deleteAccountErrorMessage: "",
      isEditing: false,
      loadingEditing: false,
      eventLabel: "Select country",
    };
  },
  validations: {
    form: {
      password: { required },
      newPassword: { required, minLength: minLength(8) },
    },
    dataForm: {
      email: { required, email },
      phone: { required, minLength: minLength(10) },
      phoneCode: { required },
    },
  },
  methods: {
    ...mapActions(["syncSessionWithApi"]),
    async getAccount() {
      try {
        const res = await this.$userService.getAccount();
        let institution = res.data.data.profile.institution;
        const ac = {
          ...res.data.data,
          locationString: `${institution?.location.city}, ${institution?.location.region} - ${institution?.location.country}`,
        };

        console.log("asdasdasdsa", ac);
        this.$set(this, "account", ac);
        this.$set(this.dataForm, "email", ac.email);

        const phn = this.account?.profile?.institution?.contact?.phone || null;
        if (this.account?.profile?.institution?.contact?.phone) {
          const p = phn.split(" ");
          const ctry = this.countryPhoneCodes.filter(
            (r) => r.phoneCode == p[0]
          );
          if (ctry.length) {
            this.setPhoneCountry(ctry[0]);
          }
          if (p[1]) {
            this.$set(this.dataForm, "phone", p[1]);
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingAll = false;
      }
    },
    async changePassword() {
      try {
        this.loading = true;
        this.errorMessage = "";
        const res = await this.$userService.updatePassword(this.form);
        this.$eventBus.$emit("showToast", {
          title: "Password changed",
          msg: `your password has been changed successfully.`,
        });
        this.form.password = "";
        this.form.newPassword = "";
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.loading = false;
      }
    },
    async deleteAccount() {
      try {
        this.loadingDeleteAccount = true;
        this.$eventBus.$emit("showConfirmationAlert", {
          message: "Are you sure you want to delete your account? All your data will be lost for good",
          confirmCallback: async () => {
            const res = await this.$userService.deleteUserAccount(
              this.account._id
            );
            this.$router.push({ path: this.$paths.signin });
          }
        })
        // if (
        //   confirm(
        //     "Are you sure you want to delete your account? All your data will be lost for good"
        //   )
        // ) {
        //   const res = await this.$userService.deleteUserAccount(
        //     this.account._id
        //   );
        //   this.$router.push({ path: this.$paths.signin });
        // }
      } catch (e) {
        this.deleteAccountErrorMessage = e.message;
      } finally {
        this.loadingDeleteAccount = false;
      }
    },
    setPhoneCountry(country) {
      this.$set(this.dataForm, "phoneCode", country.phoneCode);
      this.$set(this, "eventLabel", `${country.countryName}`);
    },
    searchCountry(e) {
      let ct = this.$helper.searchString(
        this.$helper.countryPhoneCodes,
        e.target.value
      );
      this.$set(
        this,
        "countryPhoneCodes",
        ct.filter((ftr) => !ftr.hidden)
      );
    },
    disableEditing() {
      this.isEditing = false;
    },
    enableEditing() {
      this.isEditing = true;
    },
    async handleSave() {
      try {
        this.loadingEditing = true;
        // console.log(this.account)
        // return
        const p = {
          email: this.dataForm.email,
          phone: `${this.dataForm.phoneCode} ${this.dataForm.phone}`,
          name: this.account.name,
          location: this.account.profile.institution.location,
        };
        const res = await this.$userService.updateAccount(p);
        this.$eventBus.$emit("showToast", {
          title: "Account updated",
          msg: `Your account was updated successfully.`,
        });
        this.getAccount();
        this.syncSessionWithApi();
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingEditing = false;
        this.isEditing = false;
      }
    },
  },
  components: {},
};
