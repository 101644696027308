import EmptyState from "@/components/EmptyState.vue";

export default {
  name: "Videos",
  props: {
    items: Array
  },
  data() {
    return {
      loadingDelete: []
    }
  },
  mounted() {
  },
  methods: {
    async deleteItem(index) {

      this.$eventBus.$emit("showConfirmationAlert", {
        message: "Are you sure you want to remove this video permanently?",
        confirmCallback: async () => {
          try {
            this.$set(this.loadingDelete, index, true)
            this.loadingDelete[index] = true
            const id = this.items[index]._id
            const res = await this.$universityService.deleteVideo(id)
            this.$eventBus.$emit('showToast', { title: 'Video deleted', msg: `The video was deleted successfully` })
            this.items.splice(index, 1)
          } catch (e) {
            console.log(e)
          } finally {
            this.$set(this.loadingDelete, index, false)
          }
        },
      });

      // if (confirm('Are you sure you want to remove this video permanently ?')) {
      //   try {
      //     this.$set(this.loadingDelete, index, true)
      //     this.loadingDelete[index] = true
      //     // TODO: REVISAR
      //     const id = this.items[index]._id
      //     const res = await this.$universityService.deleteVideo(id)
      //     this.$eventBus.$emit('showToast', { title: 'Video deleted', msg: `The video was deleted successfully` })
      //     this.items.splice(index, 1)
      //   } catch (e) {
      //     console.log(e)
      //   } finally {
      //     this.$set(this.loadingDelete, index, false)
      //   }
      // }
    }
  },
  components: {
  },
};