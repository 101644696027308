import EmptyState from "@/components/EmptyState.vue";
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/Sidebar.vue";
import { Modal } from 'bootstrap';
import moment from "moment";
import { email, required } from "vuelidate/lib/validators";
import LoadingContainer from "@/components/LoadingContainer.vue";
import ReadOnlyInput from "@/components/ReadOnlyInput.vue";

export default {
  name: "Audience",
  mounted() {
    // this.walletModal = new Modal(document.getElementById('walletModal'))
    // this.walletModal.toggle()
    this.loadingAll = true
    this.getScholarship()

  },
  data() {
    return {
      loadingDelete: false,
      loadingAll: false,
      walletModal: null,
      allSelected: false,
      filtersCount: 0,
      selectedTab: 0,
      scholarshipId: this.$route.params.id,
      scholarship: {},
      applications: [],
      showingApplications: [],
      columns: {
        overview: [
          'Student',
          'Date',
          'Match',
        ]
      },
      filters: {
        match: {
          min: 20,
          max: 100
        },
        school_type: {
          public: false,
          private: false
        },
        location: {
          us: false,
          other_countries: false
        },

      }
    };
  },
  methods: {
    async getScholarship() {
      try {
        const res = await this.$universityService.showScholarship(this.scholarshipId)
        res.data.data.applications?.map(r => {
          r.studentName = `${r.user.profile.firstname} ${r.user.profile.lastname}`
        })
        let s = res.data.data

        if (s.availableSpaces == 'variable-range') {
          s.formatted_deadline = `${moment(s.datesAvailability.dateRange[0]).format('MMMM[, ]YYYY')} - ${moment(s.datesAvailability.dateRange[1]).format('MMMM[, ]YYYY')}`
        } else {
          s.formatted_deadline = moment(s.datesAvailability.deadline).format('MMMM[, ]YYYY')
        }

        this.$set(this, 'scholarship', s)
        this.$set(this, 'applications', s.applications)
        this.$set(this, 'showingApplications', s.applications)
      } catch (e) {
        console.log('ERROR', e)
      } finally {
        this.loadingAll = false
      }
    },
    toggleSelectAll() {
      this.allSelected = !this.allSelected
      this.items = this.items.map(r => { return { ...r, selected: this.allSelected } })
    },
    clearSelected() {
      this.allSelected = false
      this.items = this.items.map(r => { return { ...r, selected: false } })
    },
    toggleSelectItem(item) {
      this.items = this.items.map(r => {
        return { ...r, selected: r.id == item.id ? !item.selected : r.selected }
      })
      this.allSelected = false
    },
    itemPressed(item) {
      // console.log('item', item.user._id)
      // return
      if(item.user._id) {
        this.$router.push({ path: `${this.$paths.students.detail}/${item.user._id}` })
      }

    },
    toggleWalletModal() {
      this.walletModal.toggle()
    },
    filterChanged() {
      const schoolCount = Object.keys(this.filters.school_type).filter(s => this.filters.school_type[s]).length
      const locationCount = Object.keys(this.filters.location).filter(s => this.filters.location[s]).length
      const matchCount = (this.filters.match.min != 20 || this.filters.match.max != 100) ? 1 : 0
      this.filtersCount = schoolCount + locationCount + matchCount
    },
    async updateStatus(isActive = false) {
      this.scholarship.isActive = isActive
      const params = {
        isActive: isActive,
      }
      const res = await this.$universityService.updateScholarshipStatus(this.scholarshipId, params)
    },
    goToAddCredits() {
      this.walletModal.toggle()
      this.$router.push({ path: this.$paths.wallet.add_credits })
    },
    searchString(e) {
      const it = this.$helper.searchString(this.applications, e.target.value)
      this.$set(this, 'showingApplications', it.filter((ftr) => !ftr.hidden))
    },
    async deleteItem() {
      this.$eventBus.$emit("showConfirmationAlert", {
        message: "Are you sure you want to remove this scholarship permanently?",
        confirmCallback: async () => {
          try {
            this.loadingDelete = true
            const res = await this.$universityService.deleteScholarship(this.scholarshipId)
            this.$eventBus.$emit('showToast', { title: 'Scholarship deleted', msg: `The scholarship was deleted successfully` })
            this.$router.back()
          } catch (e) {
            console.log(e)
          } finally {
            this.loadingDelete = false
          }
        },
      });
      // if (confirm('Are you sure you want to remove this scholarship permanently ?')) {
      //   try {
      //     this.loadingDelete = true
      //     const res = await this.$universityService.deleteScholarship(this.scholarshipId)
      //     this.$eventBus.$emit('showToast', { title: 'Scholarship deleted', msg: `The scholarship was deleted successfully` })
      //     this.$router.back()
      //   } catch (e) {
      //     console.log(e)
      //   } finally {
      //     this.loadingDelete = false
      //   }
      // }
    },
    hasDeadline() {
      return this.scholarship.datesAvailability.deadline || this.scholarship.datesAvailability.dateRange.length
    }

  },

  validations: {
    email: {
      required,
      email,
    },
  },
  components: {
    ReadOnlyInput
  },
};