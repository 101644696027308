import { required, email } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
export default {
  name: 'SignupConfirmation',
  data() {
    return {
      email: this.$route.query.email,
      success: false,
      confirmationErrorMessage: '',
      loadingConfirmation: false,
      resend: false

    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  methods: {
    ...mapActions([
      'syncSessionWithApi'
    ]),
    togglePassword() {
      this.passwordFieldType = this.passwordFieldType == 'password' ? 'text' : 'password'
    },
    async handleResendEmail() {
      try {
        const res = await this.$userService.resendEmail(this.email)
        this.resend = true
      } catch (e) {

      }
    },
    async handleConfirmation() {
      try {
        this.confirmationErrorMessage = ''
        this.loadingConfirmation = true
        this.resend = false
        const login = await this.$userService.signin(this.$route.query)
        await this.syncSessionWithApi({ token: login.data.token })
        this.success = true

      } catch (e) {
        this.success = false
        this.confirmationErrorMessage = e.message
      } finally {
        this.loadingConfirmation = false
      }
    },
    handleGetStarted() {
      this.$router.push(this.$paths.onboarding)
    }
  }
};