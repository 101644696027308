import { required } from 'vuelidate/lib/validators';
import Availability from '../Availability';

export default {
    mounted() {
        this.loadingAll = true
        this.getAgenda()
    },
    data() {
        return {
            agenda: null,
            loadingAll: false,
            actionDisabled: true,
            canEnterTheRoom: false,
            loading: false,
            loadingCheck: false,
            loadingNewDate: false,
            availableMonths: [],
            availableDays: [],
            availableTimes: [],
            selectedDay: 0,
            item: {},
            date: '',
            errorMessage: '',
            errorMessageDate: '',
            timeRemaining: null,
            eventLabel: {
                month: '',
                time: '',
                day: ''
            },
            form: {
                date: '',
                time: '',
                month: ''
            },
            newDateModal: null
        }
    },
    validations: {
        form: {
            day: {
                required,
            },
            time: {
                required,
            },
            month: {
                required,
            },
        }
    },
    methods: {
        async getAgenda() {
            try {
                const res = await this.$agendaService.getAgenda();
                const agenda = res.data.data
                this.$set(this, 'agenda', agenda)
            } catch (e) {
            } finally {
                this.loadingAll = false;
            }
        },
        async goToMeeting() {
            this.$router.push({ path: `${this.$paths.meeting}/${this.item._id}` })
        },
        saveAvailability() {
            this.$eventBus.$emit('showToast', { title: 'Agenda Updated', msg: `Your availability was updated successfully.` })
            this.$router.push({ path: this.$paths.agenda.list })
        }
    },
    components: {
        Availability
    },
}