<template>
  <div class="col-8">
    <h1 class="header-title mb-3">Availability</h1>
    <strong
      >Please add the time slots you are available for each day of the week below so that
      students can book appointments with you.</strong
    >
    <div
      v-for="(wd, weekIndex) in weekdays"
      :key="wd.id"
      class="py-4"
      :class="{ 'border-bottom': weekIndex != 6 }"
    >
      <div class="row">
        <div class="col-12 justify-content-between d-flex align-items-center">
          <strong>{{ wd.label }}</strong>
          <div class="d-flex align-items-center">
            <!-- <strong v-if="wd.hours.length > 0 && wd.open" class="me-3">
                    <i class="fas fa-copy text-primary"></i>
                    Copy {{ wd.label }}
                  </strong> -->

            <div class="dropdown" v-if="wd.open && wd.hours.length > 0">
              <button
                class="btn btn-link me-3"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="fas fa-copy text-primary"></i>

                <span class="text-dark"> Duplicate {{ wd.label }} slots </span>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a
                    class="dropdown-item"
                    href="#"
                    @click.prevent="selectEveryday(weekIndex)"
                    >Everyday</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="#"
                    @click.prevent="selectWorkingDays(weekIndex)"
                    >Working days</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="#"
                    @click.prevent="openWeekdaysModal(weekIndex)"
                  >
                    Personalize</a
                  >
                </li>
              </ul>
            </div>

            <i
              @click="openWeekday(weekIndex)"
              class="fas display-6 text-primary cursor-pointer"
              :class="{
                'fa-plus-circle': !wd.open,
                'fa-minus-circle': wd.open,
              }"
            ></i>
          </div>
        </div>
      </div>
      <div v-if="wd.open">
        <div
          class="row mt-3 align-items-end"
          v-for="(hour, hourIndex) in wd.hours"
          :key="hourIndex"
        >
          <div class="col-12 col-md">
            <div class="form-group mb-0">
              <label class="form-label"> Available from </label>
              <input
                type="text"
                name=""
                class="form-control"
                placeholder="08:00"
                :value="hour.start"
                disabled
              />
            </div>
          </div>
          <div class="col-12 col-md">
            <div class="form-group mb-0">
              <label class="form-label"> To </label>
              <input
                type="text"
                name=""
                :id="`end-${hourIndex}`"
                class="form-control"
                placeholder="18:00"
                :value="hour.end"
                disabled
              />
            </div>
          </div>
          <div class="col-12 col-md-auto">
            <button
              class="btn btn-danger btn-del"
              @click="deleteHour(weekIndex, hourIndex)"
            >
              <i class="fas fa-trash display-6"></i>
            </button>
          </div>
        </div>

        <form @submit.prevent="addHour($event, weekIndex)">
          <div class="row mt-3 align-items-end">
            <div class="col-12 col-md">
              <div class="form-group mb-0">
                <label class="form-label"> Available from </label>
                <input
                  type="text"
                  name="start"
                  :id="`start${weekIndex}`"
                  class="form-control"
                  placeholder="08:00"
                  v-mask="$helper.timeMask"
                  v-model="wd.start"
                  @input="hourStartChanged($event, weekIndex)"
                />
              </div>
            </div>
            <div class="col-12 col-md">
              <div class="form-group mb-0">
                <label class="form-label"> To </label>
                <input
                  type="text"
                  name="end"
                  :id="`end${weekIndex}`"
                  class="form-control"
                  placeholder="12:00"
                  v-mask="$helper.timeMask"
                  v-model="wd.end"
                />
              </div>
            </div>
            <div class="col-12 col-md-auto">
              <button
                :disabled="!checkDateIsValid(wd.start, wd.end)"
                class="btn btn-primary"
              >
                Add
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <span class="text-danger text-center">{{ errorMessage }}</span>
      </div>
    </div>
    <div class="d-grid gap-2 mt-4">
      <button
        class="btn btn-primary"
        @click="save"
        :disabled="weekdays.filter((r) => r.hours.length > 0).length == 0 || loading"
      >
        <span
          v-if="loading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Save and continue
      </button>
    </div>

    <ModalComponent id="weekdays_modal" title="Copy Intervals">
      <template v-slot:body>
        <p>
          <strong>Select the days you would like to use the same interval</strong>
        </p>
        <ul class="list-group list-group-flush">
          <li
            class="list-group-item cursor-pointer"
            v-for="(weekday, index) in weekdays"
            :key="index"
            @click.prevent="selectWeekday(index)"
          >
            <div class="row">
              <div class="col">
                <strong class="text-dark">
                  {{ weekday.label }}
                </strong>
              </div>
              <div class="col-auto">
                <a class="form-check">
                  <i
                    v-if="!weekday.checked"
                    class="fas fa-square me-2 text-primary-shadow"
                  ></i>
                  <i
                    v-if="weekday.checked"
                    class="fas fa-check-square text-primary me-2"
                  ></i>
                </a>
              </div>
            </div>
          </li>
        </ul>
        <div class="d-grid gap-2">
          <button class="btn btn-primary mt-5" @click="doneSelectWeekday">Done</button>
        </div>
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
export default {
  mounted() {
    this.weekdays = this.weekdays.map((elem) => ({
      ...elem,
      open: elem.hours.length > 0,
    }));
  },
  props: {
    timeZone: {
      type: String,
      default: null,
    },
    existingAgenda: [],
  },
  watch: {
    existingAgenda(val) {
      console.log(val);
      this.timeZone = val.timezone;
      let agenda = [...this.weekdays];
      val.weekdays.map((w, weekIndex) => {
        this.openWeekday(w.day);
        w.timeRanges.map((hour) => {
          this.addHour(null, w.day, hour.start, hour.end);
        });
      });
    },
  },
  data() {
    return {
      weekdaysModal: null,
      currentWeekdayIndex: null,
      errorMessage: "",
      loading: false,
      weekdays: [
        {
          id: 2,
          open: false,
          checked: false,
          label: "Monday",
          start: "",
          end: "",
          hours: [],
        },
        {
          id: 3,
          open: false,
          checked: false,
          label: "Tuesday",
          start: "",
          end: "",
          hours: [],
        },
        {
          id: 4,
          open: false,
          checked: false,
          label: "Wednesday",
          start: "",
          end: "",
          hours: [],
        },
        {
          id: 5,
          open: false,
          checked: false,
          label: "Thursday",
          start: "",
          end: "",
          hours: [],
        },
        {
          id: 6,
          open: false,
          checked: false,
          label: "Friday",
          start: "",
          end: "",
          hours: [],
        },
        {
          id: 7,
          open: false,
          checked: false,
          label: "Saturday",
          start: "",
          end: "",
          hours: [],
        },
        {
          id: 1,
          open: false,
          checked: false,
          label: "Sunday",
          start: "",
          end: "",
          hours: [
            // { id: 1, start: '08:00', end: '18:00' }
          ],
        },
      ],
    };
  },
  components: {},
  methods: {
    openWeekday(i) {
      this.weekdays[i].open = !this.weekdays[i].open;
    },
    addHour(submitEvent, i, startValue = null, endValue = null) {
      const start = submitEvent?.target.elements.start.value || startValue;
      const end = submitEvent?.target.elements.end.value || endValue;
      if (start == "" || end == "") {
        return;
      }
      console.log("index", i);
      let w = [...this.weekdays];
      w[i].hours.push({
        start: start,
        end: end,
      });
      w[i].start = "";
      w[i].end = "";
      console.log(JSON.stringify(w[i]));
      this.$set(this, "weekdays", w);
    },
    deleteHour(weekIndex, hourIndex) {
      const h = [...this.weekdays[weekIndex].hours];
      h.splice(hourIndex, 1);
      this.weekdays[weekIndex].hours = h;
      // this.weekdays[weekIndex].hours.splice(hourIndex, 1);
    },
    openWeekdaysModal(index) {
      this.currentWeekdayIndex = index;
      this.weekdaysModal = new Modal(document.getElementById("weekdays_modal"));
      this.weekdaysModal.toggle();
    },
    selectWeekday(index) {
      this.weekdays[index].checked = !this.weekdays[index].checked;
    },
    doneSelectWeekday() {
      let hours = [...this.weekdays[this.currentWeekdayIndex].hours];
      this.weekdays = this.weekdays
        .map((elem) => (elem.checked ? { ...elem, hours } : elem))
        .map((elem) => ({
          ...elem,
          checked: false,
          open: elem.hours.length > 0,
        }));
      this.currentWeekdayIndex = null;
      this.weekdaysModal.toggle();

      console.log(this.weekdays);
    },
    selectEveryday(index) {
      let hours = [...this.weekdays[index].hours];
      this.weekdays = this.weekdays
        .map((elem) => ({ ...elem, hours }))
        .map((elem) => ({ ...elem, open: elem.hours.length > 0 }));
    },

    selectWorkingDays(index) {
      let hours = [...this.weekdays[index].hours];
      console.log("HOURS", hours);
      let d = this.weekdays
        .map((elem, i) => (i != 0 && i != 6 ? { ...elem, hours } : elem))
        .map((elem) => ({ ...elem, open: elem.hours.length > 0 }));
      this.weekdays = d;
    },
    hourStartChanged(e, index) {
      console.log("index", index);
      console.log("value", e.target.value.length);
      // console.log(document.getElementById(`start${index}`).value)
      if (document.getElementById(`start${index}`).value.length == 5) {
        document.getElementById(`end${index}`).focus();
      }
    },

    checkDateIsValid(start, end) {
      try {
        if (start.length < 5 || end.length < 5) return false;
        const date1 = `${this.$moment().format("YYYY-MM-DD")} ${start}`;
        const date2 = `${this.$moment().format("YYYY-MM-DD")} ${end}`;
        return this.$moment(date1).isBefore(this.$moment(date2));
      } catch (e) {
        return false;
      }
    },

    async save() {
      try {
        this.errorMessage = "";
        this.loading = true;
        const days = this.weekdays
          .map((w, index) => {
            return {
              day: `${index}`,
              timeRanges: w.hours.map((h) => {
                return {
                  start: h.start,
                  end: h.end,
                };
              }),
            };
          })
          .filter((r) => r.timeRanges.length > 0);

        const params = {
          weekdays: days,
          autoAcceptAppointments: false,
          timezone: this.timeZone,
        };

        const res = await this.$agendaService.createAgenda(params);
        this.$emit("saveAvailability", {});
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
