<template>
  <div class="base-input-tag mt-2">
    <div
      @click="focusNewTag()"
      :class="{
      'wrapper--active': isInputActive
    }"
      class="wrapper"
    >
      <span v-for="(tag, index) in innerTags" :key="index" class="input-tag">
        <span>{{ getObjectName(tag) }}</span>
        <a @click.prevent.stop="remove(index)" class="remove"></a>
      </span>
      <input
        ref="inputtag"
        :placeholder="placeholder"
        type="text"
        v-model="newTag"
        @keydown.delete.stop="removeLastTag"
        @keydown="addNew"
        @focus="handleInputFocus"
        @blur="handleInputBlur"
        class="new-tag form-control"
      />
    </div>
    <div class="options" v-if="autocomplete.length > 0 && isInputActive">
      <div class="option" v-if="allOption && autocomplete.length > 0" @click="addAll">All</div>
      <div
        v-for="(option, index) in autocomplete"
        :key="index"
        class="option"
        @mousedown="addNew($event, option)"
      >{{ getObjectName(option) }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseFormInputTagsAutocomplete',

  props: {
    value: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    addTagOnKeys: {
      type: Array,
      default: () => {
        return [
          13, // Return
          188, // Comma ','
          9 // Tab
        ]
      }
    },
    beforeAdding: {
      type: Function
    },
    options: {
      type: Array,
      default: () => []
    },
    allOption: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      newTag: '',
      innerTags: [...this.value],
      isInputActive: false,
      autocomplete: [],
      isOptionsObject: this.verifyOptionsType()
    }
  },

  watch: {
    value() {
      this.innerTags = [...this.value]
    },
    newTag(value) {
      this.filter(value)
    },
    options() {
      this.isOptionsObject = typeof this.options[0] == 'object' ? true : false;
    }
  },

  methods: {
    getObjectName(object) {
      let name = object;

      if (this.isOptionsObject) {
        name = object.name ? object.name : object;

        let find = this.options.find((option) => option.value == name);
        name = find ? find.name : name;
      }

      return name;
    },
    verifyOptionsType() {
      if(this.options.length > 0) {
        this.isOptionsObject = typeof this.options[0] == 'object' ? true : false;
      }
    },
    addAll() {
      let allOptions = [];

      for(const option of this.options) {
        if (typeof option == 'object') {
          if (option.value != 'none') {
            allOptions.push(option.value)
          }
        } else {
          if (option != 'none') {
            allOptions.push(option.value)
          }
        }
      }

      this.$emit('input', allOptions)
    },
    focusNewTag() {
      if (!this.$refs.inputtag) {
        return
      }
      this.$refs.inputtag.focus()
      this.isInputActive = true
      this.filter()
    },

    handleInputFocus() {
      this.isInputActive = true
    },

    handleInputBlur() {
      setTimeout(() => {
        this.isInputActive = false
      }, 150)
    },

    filter(value = '') {
      this.verifyOptionsType()
      if (this.isInputActive) {
        this.autocomplete = this.options.filter((x) => {
          return (
            this.isOptionsObject ? 
            (
              x.value.toLowerCase().includes(value.toLowerCase()) &&
              !this.innerTags.find((tag) => (tag.value ? tag.value : tag) == x.value)
            )
            :
            (
              x.toLowerCase().includes(value.toLowerCase()) &&
              !this.innerTags.find((tag) => tag == x)
            )
          )

        })
      }
    },

    async addNew(e, option) {
      const keyShouldAddTag = e
        ? this.addTagOnKeys.indexOf(e.keyCode) !== -1
        : true

      if (keyShouldAddTag) {
        option = this.autocomplete[0]
      }

      const tag = this.beforeAdding ? await this.beforeAdding(option) : option

      if (tag.value != 'none') {
        let index = this.innerTags.findIndex(tag => tag == 'none')
        if (index !== -1) {
          this.innerTags = this.innerTags.slice(0, index)
        }
      }

      if (tag && (this.innerTags.indexOf(tag) === -1 && this.innerTags.indexOf(tag.value) === -1)) {
        this.innerTags.push(tag)
        this.newTag = ''
        this.$emit('input', this.formatValue())

        e && e.preventDefault()
      }
      this.focusNewTag()
    },

    formatValue() {
      let newValue = [];
      let noneFilter = this.innerTags.filter((tag) => tag.value == 'none')

      if (noneFilter.length) {
        newValue = ['none'];
      } else {
        for(const option of this.innerTags) {
          newValue.push((typeof option == 'object' ? option.value : option));
        }
      }

      return newValue;
    },

    remove(index) {
      this.innerTags.splice(index, 1)
      this.$emit('input', this.formatValue())
    },

    removeLastTag() {
      if (this.newTag) {
        return
      }
      this.innerTags.pop()
      this.$emit('input', this.formatValue())
    }
  }
}
</script>
<style lang="scss" scoped>
.base-input-tag {
  position: relative;
  .wrapper {
    background-color: #fff;
    border-radius: 4px;
    border: solid 1px #cfcfcf;
    overflow: hidden;
    padding-left: 2px;
    padding-top: 2px;
    cursor: text;
    text-align: left;
    -webkit-appearance: textfield;
    display: flex;
    flex-wrap: wrap;
    min-height: 32px;
    .input-tag {
      background-color: #eeeeee;
      border-radius: 4px;
      color: #212220;
      display: inline-block;
      font-size: 12px;
      font-weight: 300;
      margin-bottom: 2px;
      margin-right: 2px;
      padding: 3px;
      .remove {
        cursor: pointer;
        font-weight: bold;
        color: #FE5101;
        padding: 0 7px;
        &:hover {
          text-decoration: none;
        }
        &:empty::before {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='8' height='8'%3E%3Cpath fill='%23ed6125' fill-rule='evenodd' d='M6 4.294L2.025.32A1.086 1.086 0 0 0 .492.313l-.18.18A1.087 1.087 0 0 0 .32 2.025L4.294 6 .32 9.975a1.087 1.087 0 0 0-.006 1.532l.18.18c.42.421 1.11.416 1.532-.007L6 7.706l3.975 3.974a1.086 1.086 0 0 0 1.533.007l.18-.18a1.087 1.087 0 0 0-.007-1.532L7.706 6l3.975-3.975a1.087 1.087 0 0 0 .006-1.532l-.18-.18A1.086 1.086 0 0 0 9.976.32L6 4.294z'/%3E%3C/svg%3E");
        }
      }
    }
    .new-tag {
      background: transparent;
      border: none;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 6px;
      margin-top: 1px;
      outline: none;
      padding: 4px;
      padding-left: 0;
      flex-grow: 1;
      height: 20px;
      &:focus {
        outline: none;
      }
    }
  }
  .options {
    width: 100%;
    position: absolute;
    background-color: #fff;
    border-radius: 4px;
    border: solid 1px #cfcfcf;
    z-index: 3;
    max-height: 206px;
    overflow-y: scroll;
    cursor: pointer;
    .option {
      padding: 5px;
      &:hover {
        background-color: #cfcfcf;
      }
    }
  }
}
</style>
