import EmptyState from "@/components/EmptyState.vue";
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/Sidebar.vue";
import { Modal } from 'bootstrap';
import { email, required } from "vuelidate/lib/validators";

export default {
  name: "Brochures",
  props: {
    items: [],
  },
  watch: {
    items: function (val) {
      this.$set(this, 'showingItems', val)
    }
  },
  mounted() {
    this.$set(this, 'showingItems', this.items)
   },
  data() {
    return {
      showingItems: [],
      allSelected: false,
      filtersCount: 0,
      columns: [
        'Name',
        'Views',
        'Created',
      ],
      filters: {
        match: {
          min: 20,
          max: 100
        },
        school_type: {
          public: false,
          private: false
        },
        location: {
          us: false,
          other_countries: false
        },

      }
    };
  },
  methods: {
    itemPressed(item) {
      this.$router.push({ path: `${this.$paths.university.brochure.detail}/${item._id}`})
    },
    searchString(e) {
      const it = this.$helper.searchString(this.items, e.target.value, 'title')
      this.showingItems = it.filter((ftr) => !ftr.hidden)
    }
  },
  components: {
  },
};