<template>
  <div class="container">
    <LoadingContainer style="padding-left: 0" v-if="loadingAll" />
    <div v-else class="row justify-content-center align-items-center vh-100">
      <div v-if="success" class="col-12 col-md-5 col-xl-4 my-5">
        <div class="text-center">
          <h1 class="display-2 text-uppercase text-muted mb-4">
            <span class="fas fa-check-circle text-success"></span>
          </h1>
          <h1 class="display-4 mb-3">Your email has been successfully verified</h1>
          <p class="text-muted mb-4">You can sign in with your Novva account</p>
          <router-link tag="a" :to="$paths.signin" class="btn btn-lg btn-primary">
            Sign in
          </router-link>
        </div>
      </div>
      <div v-else class="col-12 col-md-5 col-xl-4 my-5">
        <div class="text-center">
          <h1 class="display-2 text-uppercase text-muted mb-4">
            <span class="fas fa-times-circle text-danger"></span>
          </h1>
          <h1 class="display-4 mb-3">There was an error verifying your account</h1>
          <p class="text-muted mb-4">Invalid token or email already verified</p>
        </div>
      </div>
      <div class="col-12">
        <div class="text-center">
          <!-- <p class="text-muted mb-4 opacity-50" >
            <img
              src="@/assets/img/logo.jpg"
              width="70"
              height="70"
              class="rounded"
            />
          </p> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AccountActivationController from "./AccountActivationController";
export default AccountActivationController;
</script>
<style scoped></style>
