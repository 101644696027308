<template>
  <nav class="navbar navbar-expand-lg navbar-light fixed-top px-4">
    <div class="container-fluid">
      <router-link class="navbar-brand" :to="$paths.home">
        <img src="@/assets/img/logo.jpg" alt="" />
      </router-link>

      <nav aria-label="breadcrumb" class="me-auto ms-4">
        <ol class="breadcrumb">
          <router-link
            v-if="link"
            tag="li"
            :to="link"
            class="breadcrumb-item d-flex align-items-center"
          >
            <a>{{ linkName }}</a>
          </router-link>
          <router-link
            v-if="sublink"
            tag="li"
            :to="sublink"
            class="breadcrumb-item d-flex align-items-center"
          >
            <a>{{ sublinkName }}</a>
          </router-link>
          <li
            class="breadcrumb-item active d-flex align-items-center"
            aria-current="page"
          >
            {{ current }}
          </li>
        </ol>
      </nav>

      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0 align-items-center">
          <div v-if="showFullOptions">
            <router-link
              v-if="userSession.credits == 0"
              :to="$paths.wallet.add_credits"
              tag="li"
              class="nav-item"
            >
              <button class="btn btn-primary btn-sm py-1 me-3">
                <span class="fas fa-coins display-6"></span> Add credits
              </button>
            </router-link>
            <router-link
              v-else
              :to="$paths.wallet.list"
              tag="strong"
              class="cursor-pointer"
            >
              <span class="fas fa-coins"></span> {{ userSession.credits }} credits
            </router-link>
          </div>

          <div id="navbar-divider" class="mx-4"></div>

          <li v-if="showFullOptions" class="nav-item dropdown">
            <a
              class="nav-link"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div
                v-if="notifications && notifications.length"
                id="notification-badge"
              ></div>
              <span class="fas fa-bell text-muted display-6"></span>
            </a>
            <ul
              v-if="notifications && notifications.length"
              class="dropdown-menu dropdown-menu-end py-3 notifications-menu"
              aria-labelledby="navbarDropdown"
            >
              <router-link
                v-for="(item, index) in notifications"
                v-bind:key="'not-' + index"
                tag="div"
                :to="notificationLinks[item.type] ? notificationLinks[item.type] : $paths.notification"
              >
                <li>
                  <a class="dropdown-item d-flex">
                    <i
                      class="fas text-primary me-3 mt-2"
                      :class="[$userService.notificationIcons[item.type]]"
                    ></i>
                    <span>
                      <strong class="text-dark">{{ item.title }}</strong>
                      <p>{{ item.message }}</p>
                    </span>
                  </a>
                </li>
                <li v-if="index < notifications.length - 1">
                  <hr class="dropdown-divider mx-4" />
                </li>
              </router-link>
            </ul>
            <ul
              v-else
              class="dropdown-menu dropdown-menu-end py-3 notifications-empty"
              aria-labelledby="navbarDropdown"
            >
              <p class="text-muted mb-0 text-center">
                <span class="fas fa-bell text-muted display-6"></span>
                You have no notifications
              </p>
            </ul>
          </li>
        </ul>
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item dropdown">
            <a
              class="nav-link"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div class="d-flex flex-row align-items-center">
                <div
                  v-if="userSession && userSession.image"
                  id="name-badge"
                  class="rounded-circle d-flex justify-content-center align-items-center"
                >
                <img class="h-100 w-100 rounded-circle" :src="userSession.image" alt="">
                </div>
                <div
                  v-else
                  id="name-badge"
                  class="rounded-circle d-flex justify-content-center align-items-center"
                >
                  <h4 class="text-white mb-0">{{ userSession.name.charAt(0) }}</h4>
                </div>
                <h4 class="text-dark mb-0 px-3">{{ userSession.name }}</h4>
                <small class="text-dark fas fa-chevron-down"></small>
              </div>
            </a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
              <router-link v-if="showFullOptions" tag="li" :to="$paths.account"
                ><a class="dropdown-item" href="#">Account</a></router-link
              >
              <router-link v-if="showFullOptions" tag="li" :to="$paths.wallet.list"
                ><a class="dropdown-item" href="#">Wallet</a></router-link
              >
              <li v-if="showFullOptions"><hr class="dropdown-divider" /></li>

              <router-link tag="li" :to="$paths.signin"
                ><a class="dropdown-item">
                  <strong> Logout </strong>
                </a></router-link
              >
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { notificationLinks } from '@/utils/NotificationConstants'

export default {
  name: "NavBar",
  mounted() {
    this.notificationLinks = notificationLinks
    this.getNotifications();
  },
  props: {
    link: String,
    linkName: String,
    sublink: String,
    sublinkName: String,
    current: String,
    showFullOptions: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    userSession() {
      console.log(this.$store.state.userSession)
      return this.$store.state.userSession;
    },
  },
  data() {
    return {
      notifications: [],
      notificationLinks: {}
    };
  },
  methods: {
    async getNotifications() {
      try {
        const res = await this.$userService.getNotifications();
        // console.log(res.data.data);
        this.$set(this, "notifications", res.data.data);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navbar-brand-img,
.navbar-brand > img {
  max-height: 2.5rem !important;
  border-radius: 8px;
}
#notification-badge {
  width: 8px;
  height: 8px;
  border-radius: 6px;
  background-color: red;
  position: absolute;
  margin-left: 9px;
  margin-top: 2px;
}
#navbar-divider {
  width: 1px;
  height: 30px;
  background: lightgray;
}
.navbar {
  z-index: 1031;
}
#name-badge {
  width: 25px;
  height: 25px;
  background-color: gray;
}
.notifications-menu {
  height: 400px;
  overflow-y: scroll;
}
</style>
