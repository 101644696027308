<template>
  <div>
    <ModalComponent
      :title="title === null || title === undefined ? 'Confirm action' : title"
      id="confirmAlertModal"
      v-on:modalClosed="alertClosed"
    >
      <template v-slot:body>
        {{ message === null || message === undefined ? 'Are you sure you want to continue ?' : message }}
      </template>
      <template v-slot:footer>
        <button type="button" @click="cancelAction" class="btn btn-outline-secondary w-15">
          No
        </button>
        <button type="button" @click="confirmAction" class="btn btn-primary w-15">
          Yes
        </button>
      </template>
    </ModalComponent>
  </div>
</template>
<script>
import ConfirmationAlertController from "./ConfirmationAlertController";
export default ConfirmationAlertController;
</script>
<style scoped></style>
