<template>
  <div>
    <Sidebar />
    <NavBar current="New Brochure" :link="$paths.university.list" linkName="Resources" />
    <Container col="col-12 col-lg-10 col-xl-8">
      <div class="header mt-md-4">
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col">
              <h1 class="header-title">New Brochure</h1>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="form-group">
          <label class="form-label">Title</label>
          <input
            type="text"
            v-model.trim="$v.form.title.$model"
            :class="{ 'is-invalid': $v.form.title.$error }"
            placeholder="Enter brochure title"
            class="form-control"
          />
          <div class="invalid-feedback">
            <small>Please, fill up this field correctly</small>
          </div>
        </div>

        <div class="form-group">
          <label for="" class="form-label">Upload File</label>
          <ImageCropper
            :accept="'application/pdf'"
            :customFileInputWidth="customFileInputWidth"
            v-on:cropEnd="cropEnd"
          />
        </div>

        <div class="form-group">
          <p>
            Upload documents that will be available to students in the <strong>NOVVA Connect APP</strong>. 
            <br />
            Upload documents, such as brochures, program lists, academic calendars, pricing sheets, application forms, etc. Documents must be in PDF. format.
            <br />
            <strong>IMPORTANT:</strong> For faster navigation please  ensure that all brochures are smaller than 10MB.
            Resize your PDFs at www.pdfcompressor.com
          </p>
        </div>

        <div class="row">
          <div class="col-12 text-center">
            <span class="text-danger text-center">{{ errorMessage }}</span>
          </div>
        </div>

        <div class="d-grid gap-2 mt-4">
          <button
            class="btn btn-primary mt-3"
            @click="save"
            :disabled="$v.$invalid || loading"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Save and continue
          </button>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import AddBrochureController from "./AddBrochureController";
export default AddBrochureController;
</script>

<style scoped>
input[type="file"] {
  opacity: 0;
  height: 200px;
  width: 724px;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}
.form-control-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  opacity: 0.9;
  transition: 0.2s;
  width: 100%;
  height: 200px;
  border: 2px #ecebeb dashed;
}
.form-control-custom .text {
  position: absolute;
  color: #a19f9f;
  text-align: center;
}
</style>
