import EmptyState from "@/components/EmptyState.vue";
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/Sidebar.vue";
import { Modal } from 'bootstrap';
import { email, required } from "vuelidate/lib/validators";
import moment from 'moment'
export default {
  name: "Scholarships",
  props: {
    items: [],
  },
  watch: {
    items: function (val) {
      console.log('asdasdasdasdasdasd', val)
      this.$set(this, 'showingItems', val)
    }
  },
  mounted() {
    this.$set(this, 'showingItems', this.items)
  },
  data() {
    return {
      showingItems: [],
      filtersCount: 0,
      filters: {
        match: {
          min: 20,
          max: 100
        },
        school_type: {
          public: false,
          private: false
        },
        location: {
          us: false,
          other_countries: false
        },

      },
      allSelected: false,
      columns: [
        'Scholarship',
        'Spaces',
        '',
        'Applications',
        'Deadline',
        'Status ',
      ],
    };
  },
  methods: {
    formatDate(item) {
      console.log(item)
      return moment(item.deadline).format('MMMM,YYYY')
    },
    getPercentage(item) {
      if (item.applicationsCount == 0) return '0'
      return `${(item.applicationsCount * 100) / item.datesAvailability.availableSpaces}`
    },
    itemPressed(item) {
      console.log(item)
      this.$router.push({ path: `${this.$paths.university.scholarship.detail}/${item._id}` })
    },
    searchString(e) {
      const it = this.$helper.searchString(this.items, e.target.value, 'name')
      this.showingItems = it.filter((ftr) => !ftr.hidden)
    }
  },
  components: {
  },
};