<template>
  <div>
    <Sidebar />
    <NavBar
      current="New Scholarship/Bursary"
      :link="$paths.university.list"
      linkName="Resources"
    />
    <div class="main-content">
      <div class="container-fluid bg-white vh-100">
        <div class="row">
          <div class="col-12 col-md-6 justify-content-center offset-md-3 pt-5">
            <h1 class="header-title">New Scholarship/Bursary</h1>
            <p class="mt-4">
              One of the most valuable resources you can provide us with, allowing us to match your institution's scholarships to the best-fit students using the NOVVA Connect App.
            </p>
            <p>
              Scholarships are oftem front of mind for students, parents and high school staff in the NOVVA network, so to maximise meaningful engagement we recommend you add 1 by 1 all that you offer!
            </p>
          </div>
          <div class="row align-items-center">
            <div class="col-12 col-md-6 justify-content-center offset-md-3">
              <ul class="nav nav-tabs header-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    v-if="!isInternational()"
                    class="nav-link"
                    :class="{ active: step == 1 }"
                    @click.prevent="setStep(1)"
                    id="audience-tab"
                    type="button"
                    role="tab"
                    :disabled="!canAccess(1)"
                    data-bs-toggle="tab"
                    data-bs-target="#audience"
                    aria-controls="audience"
                    aria-selected="true"
                  >
                    Audience
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    @click.prevent="setStep(2)"
                    class="nav-link"
                    :class="{ active: step == 2 }"
                    id="type-tab"
                    type="button"
                    role="tab"
                    :disabled="!canAccess(2)"
                    data-bs-toggle="tab"
                    data-bs-target="#type"
                    aria-controls="type"
                    aria-selected="false"
                  >
                    Type
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    @click.prevent="setStep(3)"
                    class="nav-link"
                    :class="{ active: step == 3 }"
                    id="details-tab"
                    type="button"
                    role="tab"
                    :disabled="!canAccess(3)"
                    data-bs-toggle="tab"
                    data-bs-target="#details"
                    aria-controls="details"
                    aria-selected="false"
                  >
                    Details
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    @click.prevent="setStep(4)"
                    class="nav-link"
                    :class="{ active: step == 4 }"
                    id="amount-tab"
                    type="button"
                    role="tab"
                    :disabled="!canAccess(4)"
                    data-bs-toggle="tab"
                    data-bs-target="#amount"
                    aria-controls="amount"
                    aria-selected="false"
                  >
                    Amount
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    @click="(event) => setStep(5)"
                    class="nav-link"
                    :class="{ active: step == 5 }"
                    id="dates-availability-tab"
                    type="button"
                    role="tab"
                    :disabled="!canAccess(5)"
                    data-bs-toggle="tab"
                    data-bs-target="#dates-availability"
                    aria-controls="dates-availability"
                    aria-selected="false"
                  >
                    Dates & Availability
                  </a>
                </li>
              </ul>
              <div class="tab-content pt-3" id="myTabContent">
                <div
                  v-if="!isInternational()"
                  class="table-responsive col-12 col-md-8 justify-content-center offset-md-2 mt-5 tab-pane fade"
                  :class="{ 'show active': step == 1 }"
                  id="audience"
                  role="tabpanel"
                  aria-labelledby="audience-tab"
                >
                  <p><strong> Who is it suitable for? </strong></p>
                  <BaseFormRadioGroup
                    v-model.trim="$v.form.suitableFor.$model"
                    :options="[
                      {value: 'domestic-students', name: 'Domestic students'},
                      {value: 'international-students', name: 'International students'},
                    ]"
                    horizontal
                  />

                  <button
                    :disabled="validateAudience()"
                    class="btn btn-primary w-100 mt-5"
                    @click="nextStep(2)"
                  >
                    Continue
                  </button>
                </div>
                <div
                  class="col-12 col-md-8 justify-content-center offset-md-2 mt-5 tab-pane fade"
                  :class="{ 'show active': step == 2 }"
                  id="type"
                  role="tabpanel"
                  aria-labelledby="type-tab"
                >

                  <p><strong> Please select the type (1 only) </strong></p>
                  <select class="form-control" v-model.trim="$v.form.type.$model">
                    <option value="" class="text-muted">Select a scholarship type</option>
                    <option 
                      v-for="(type, index) in typeOptions"
                      :value="type.value"
                      :key="`option-${index}`"
                      :selected="type.value == $v.form.type"
                    >
                      {{ type.name }}
                    </option>
                  </select>

                  <div v-if="form.type == 'athletic-sports'" class="mt-3">
                    <label>Select applicable sports</label>
                    <BaseFormInputTagsAutocomplete
                      v-model="form.applicableSports"
                      :options="applicableSportsOptions"
                      all-option
                    />
                  </div>

                  <div v-if="form.type == 'gifted-talented'" class="mt-3">
                    <label>Select applicable areas/options</label>
                    <BaseFormInputTagsAutocomplete
                      v-model="form.applicableAreas"
                      :options="applicableAreasOptions"
                      all-option
                    />

                    <div v-if="form.applicableAreas.includes('other')">
                      <label>Other (Please enter here)</label>
                      <input
                        type="text"
                        v-model.trim="$v.form.applicableAreasOther.$model"
                        :class="{ 'is-invalid': $v.form.applicableAreasOther.$error }"
                        class="form-control"
                      />
                      <div class="invalid-feedback">
                        <small>Please, fill up this field correctly</small>
                      </div>
                    </div>
                  </div>

                  <div v-if="form.type == 'location-based'" class="mt-3">
                    <label>Locations that qualify <span class="text-muted">(start typing and wait for options to appear)</span></label>
                    <div id="input-locations">
                      <div class="mt-2" v-for="i in countInputs" :key="i">
                        <BaseInputLocation
                          type="location"
                          :country="form.suitableFor == 'domestic-students' ? country : null"
                          :id="`map-${i}`"
                          placeholder="Type your city and wait for the options"
                          class="input-location"
                          :class="{ 'is-invalid': $v.form.locations.$error }"
                          @input="addressChanged"
                        />
                      </div>
                      <span class="text-danger">{{ locationErrorMsg }}</span>

                    </div>
                    <p class="button-new mt-3" @click="addNewInputLocation()">
                      <span class="fa fa-plus-circle"></span>
                      New Location
                    </p>
                  </div>

                  <div v-if="form.type == 'merit-academic'">
                    <div class="mt-3">
                      <label>Select the exams/tests that meet the eligibility criteria for this scholarship</label>

                      <BaseFormExams 
                        v-model="form.exams" 
                        :label="'Select the exams/tests that meet the eligibility criteria for this scholarship'" 
                        :exams="examsOptions"
                      />
                    </div>
                  </div>

                  <div v-if="form.type == 'needs-based'" class="mt-3">
                    <label>Select subtype</label>

                    <select class="form-control" v-model.trim="$v.form.needsBasedSubtype.$model">
                      <option 
                        v-for="(subtype, index) in needsBasedSubtypeOptions"
                        :value="subtype.value"
                        :key="`option-${index}`"
                        :selected="subtype.value == $v.form.needsBasedSubtype"
                      >
                        {{ subtype.name }}
                      </option>
                    </select>
                  </div>

                  <div v-if="form.type == 'minorities-ethnicity'" class="mt-3">
                    <label>Select applicable minority/ethnicity criteria</label>
                    <select class="form-control" v-model.trim="$v.form.ethnicityCriteria.$model">
                      <option value="" class="text-muted">Select a scholarship type</option>
                      <option 
                        v-for="(option, index) in ethnicityCriteriaOptions"
                        :value="option.value"
                        :key="`option-${index}`"
                        :selected="option.value == $v.form.ethnicityCriteria"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>

                  <button
                    :disabled="validateType()"
                    class="btn btn-primary w-100 mt-5"
                    @click="nextStep(3)"
                  >
                    Continue
                  </button>

                </div>
                <div
                  class="col-12 col-md-8 justify-content-center offset-md-2 mt-5 tab-pane fade"
                  :class="{ 'show active': step == 3 }"
                  id="details"
                  role="tabpanel"
                  aria-labelledby="type-tab"
                >
                  <p><strong> Details </strong></p>
                  <div class="form-group">
                    <label>Title</label>
                    <input
                      type="text"
                      v-model.trim="$v.form.title.$model"
                      :class="{ 'is-invalid': $v.form.title.$error }"
                      placeholder="Scholarship title"
                      class="form-control"
                    />
                    <div class="invalid-feedback">
                      <small>Please, fill up this field correctly</small>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Description</label>

                    <quill-editor
                      v-model="$v.form.description.$model"
                      ref="refQuillEditor"
                      :options="quillOptions"
                    ></quill-editor>
                    <div class="invalid-feedback">
                      <small>Please, fill up this field correctly</small>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Link to more information</label>
                    <input
                      type="text"
                      v-model.trim="$v.form.linkInformation.$model"
                      :class="{ 'is-invalid': $v.form.linkInformation.$error }"
                      placeholder="Add a link"
                      class="form-control"
                    />
                    <div class="invalid-feedback">
                      <small>Please, fill up this field correctly</small>
                    </div>
                  </div>
                  <div class="form-group col-12 col-md-8 offset-md-2">
                  <hr/>
                </div>
                <div
                  v-for="(item, index) in $v.form.requirements.$each.$iter"
                  v-bind:key="`requirement-${index}`"
                >
                  <div class="text-end" v-if="index > 0">
                    <button class="btn btn-danger" @click.prevent="removeRequirement(index)">
                      <span class="fas fa-trash-alt"></span>
                    </button>
                  </div>
                  <div class="form-group">
                    <label>Requirement title</label>
                    <input
                      type="text"
                      v-model.trim="item.title.$model"
                      :class="{ 'is-invalid': item.title.$error }"
                      placeholder="Requirement title"
                      class="form-control"
                    />
                    <div class="invalid-feedback">
                      <small>Please, fill up this field correctly</small>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Requirement description</label>
                    <input
                      type="text"
                      v-model.trim="item.description.$model"
                      :class="{ 'is-invalid': item.description.$error }"
                      placeholder="Requirement description"
                      class="form-control"
                    />
                    <div class="invalid-feedback">
                      <small>Please, fill up this field correctly</small>
                    </div>
                  </div>
                  <hr class="mt-5" />
                </div>
                <div class="form-group">
                  <button
                    @click="addRequirement()"
                    class="btn btn-link btn-white border-0 p-0"
                  >
                    <span class="fas fa-plus-circle"></span> Add requirement
                  </button>
                </div>
                <button
                  :disabled="validateDetails()"
                  class="btn btn-primary w-100 mt-5"
                  @click="nextStep(4)"
                >
                  Continue
                </button>
                </div>
                <div
                  class="col-12 col-md-8 justify-content-center offset-md-2 mt-5 tab-pane fade"
                  :class="{ 'show active': step == 4 }"
                  id="amount"
                  role="tabpanel"
                  aria-labelledby="amount-tab"
                >
                  <p><strong> Please enter the awardable amount </strong></p>
                  <BaseFormRadioGroup
                    v-model.trim="$v.form.awardableAmount.$model"
                    :options="awardableAmountOptions"
                    horizontal
                  />

                  <div v-show="form.awardableAmount == 'up-to'">
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <label>Currency</label>
                        <select class="form-control" v-model.trim="$v.form.awardable.currency.$model">
                          <option value="" class="text-muted">Pick the currency</option>
                          <option 
                            v-for="(currency, index) in awardableAmountCurrencyOptions"
                            :value="currency.value"
                            :key="`option-${index}`"
                            :selected="currency.value == $v.form.awardable.currency"
                          >
                            {{ currency.name }}
                          </option>
                        </select>
                      </div>

                      <div v-if="form.awardable.currency" class="col-12 col-md-6">
                        <label>Up to</label>

                        <currency-input
                          v-model.trim="$v.form.awardable.upTo.$model"
                          :class="[
                            'currency-input',
                            $v.form.awardable.upTo.$invalid ? 'currency-input-error' : null
                          ]"
                          :currency="form.awardable.currency"
                          placeholder="Enter the amount"
                        />
                        <div class="invalid-feedback">
                          <small>Please, fill up this field correctly</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="form.awardableAmount == 'variable'">
                    <p class="mt-4">
                      If the amount awarded has no cap and varies, please indicate the average amount generally awarded.
                    </p>
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <label>Currency</label>
                        <select class="form-control" v-model.trim="$v.form.awardable.currency.$model">
                          <option value="" class="text-muted">Pick the currency</option>
                          <option 
                            v-for="(currency, index) in awardableAmountCurrencyOptions"
                            :value="currency.value"
                            :key="`option-${index}`"
                            :selected="currency.value == $v.form.awardable.currency"
                          >
                            {{ currency.name }}
                          </option>
                        </select>
                      </div>

                      <div v-if="form.awardable.currency" class="col-12 col-md-6">
                        <label>Average amount</label>

                        <currency-input
                          v-model.trim="$v.form.awardable.average.$model"
                          :class="[
                            'currency-input',
                            $v.form.awardable.average.$invalid ? 'currency-input-error' : null
                          ]"
                          :currency="form.awardable.currency"
                          placeholder="Enter the amount"
                        />
                        <div class="invalid-feedback">
                          <small>Please, fill up this field correctly</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="form.awardableAmount == 'fixed'">
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <label>Currency</label>
                        <select class="form-control" v-model.trim="$v.form.awardable.currency.$model">
                          <option value="" class="text-muted">Pick the currency</option>
                          <option 
                            v-for="(currency, index) in awardableAmountCurrencyOptions"
                            :value="currency.value"
                            :key="`option-${index}`"
                            :selected="currency.value == $v.form.awardable.currency"
                          >
                            {{ currency.name }}
                          </option>
                        </select>
                      </div>

                      <div v-if="form.awardable.currency" class="col-12 col-md-6">
                        <label>Amount</label>
                        <currency-input
                          v-model.trim="$v.form.awardable.amountValue.$model"
                          :class="[
                            'currency-input',
                            $v.form.awardable.amountValue.$invalid ? 'currency-input-error' : null
                          ]"
                          :currency="form.awardable.currency"
                          placeholder="Enter the amount"
                        />
                        <div class="invalid-feedback">
                          <small>Please, fill up this field correctly</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    :disabled="validateAmount()"
                    class="btn btn-primary w-100 mt-5"
                    @click="nextStep(5)"
                  >
                    Continue
                  </button>
                </div>
                <div
                  class="col-12 col-md-8 justify-content-center offset-md-2 mt-5 tab-pane fade"
                  :class="{ 'show active': step == 5 }"
                  id="dates-availability"
                  role="tabpanel"
                  aria-labelledby="dates-availability-tab"
                >
                  <p><strong> 4. Please enter deadlines and available spaces </strong></p>
                  <BaseFormRadioGroup
                    v-model.trim="$v.form.availableSpaces.$model"
                    :options="[
                      {value: 'ongoing', name: 'Ongoing'},
                      {value: 'variable-range', name: 'Variable/Range'},
                      {value: 'specific', name: 'Specific'},
                    ]"
                    horizontal
                  />

                  <div v-show="form.availableSpaces == 'ongoing'" class="mt-3">
                    <div class="col-12 col-md-6 mt-3">
                      <label>Available Spaces</label>
                      <input
                        type="text"
                        v-model.trim="$v.form.datesAvailability.availableSpaces.$model"
                        :class="{ 'is-invalid': $v.form.datesAvailability.availableSpaces.$error }"
                        placeholder="999"
                        class="form-control"
                        v-mask="'####'"
                      />
                      <div class="invalid-feedback">
                        <small>Please, fill up this field correctly</small>
                      </div>
                    </div>
                  </div>
                  <div v-show="form.availableSpaces == 'variable-range'"  class="mt-3">
                    <div class="row">
                      <div class="col-12 col-md-6 mt-3">
                        <label>Date range</label>
                        <p>
                          <date-picker
                            v-model.trim="$v.form.datesAvailability.dateRange.$model"
                            lang="en"
                            type="date"
                            range
                            placeholder="Select a date"
                            class="p-1"
                          >
                          </date-picker>
                        </p>
                        <input type="hidden" :class="{ 'is-invalid': $v.form.datesAvailability.dateRange.$error }" />
                        <div class="invalid-feedback">
                          <small>Please, select a date in the future</small>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 mt-3">
                        <label>Spaces</label>
                        <input
                          type="text"
                          v-model.trim="$v.form.datesAvailability.availableSpaces.$model"
                          :class="{ 'is-invalid': $v.form.datesAvailability.availableSpaces.$error }"
                          placeholder="999"
                          class="form-control"
                          v-mask="'####'"
                        />
                        <div class="invalid-feedback">
                          <small>Please, fill up this field correctly</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="form.availableSpaces == 'specific'"  class="mt-3">
                    <div class="row">
                      <div class="col-12 col-md-6 mt-3">
                        <label>Deadline</label>
                        <p>
                          <date-picker
                            v-model.trim="$v.form.datesAvailability.deadline.$model"
                            lang="en"
                            type="date"
                            placeholder="Select a date"
                          >
                          </date-picker>
                        </p>
                        <input type="hidden" :class="{ 'is-invalid': $v.form.datesAvailability.deadline.$error }" />
                        <div class="invalid-feedback">
                          <small>Please, select a date in the future</small>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 mt-3">
                        <label>Spaces</label>
                        <input
                          type="text"
                          v-model.trim="$v.form.datesAvailability.availableSpaces.$model"
                          :class="{ 'is-invalid': $v.form.datesAvailability.availableSpaces.$error }"
                          placeholder="999"
                          class="form-control"
                          v-mask="'####'"
                        />
                        <div class="invalid-feedback">
                          <small>Please, fill up this field correctly</small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    class="btn btn-primary w-100 mt-5"
                    @click="handleContinue()"
                    :disabled="validateDatesAvailability() || !canFinish()"
                  >
                    <span
                      v-if="loading"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Finish and Add
                  </button>
                </div>
              </div>
            </div>
            <!-- END STEP 3 -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddScholarshipController from "./AddScholarshipController";
export default AddScholarshipController;
</script>

<style scoped>
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border: none;
}
.border-muted {
  border-color: #d9d9d9 !important;
}
.mx-datepicker {
  width: 100%;
  padding-top: 2px;
}
.button-new {
  color: #FE5101;
  font-weight: bold;
  cursor: pointer;
}
.fa-plus-circle {
  color: #FE5101;
}
.currency-input {
  display: block;
  border-radius: 6px;
  border: 1px solid #d0cfcf;
  padding: 0 15px;
  height: 40px;
  color: #434040;
}
.currency-input-error {
  border: 1px solid red;
}
</style>
