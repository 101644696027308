<template>
  <div :style="{ width: `${width - 24}px`, height: `${height}px` }">
    <div
      class="slider px-5"
      :style="{ width: `${width - 24}px`, height: `${height}px` }"
    >
      <span class="arrow-left">
        <i
          class="fas fa-arrow-circle-left display-5 text-primary"
          @click="toLeft"
        ></i>
      </span>
      <span class="arrow-right">
        <i
          class="fas fa-arrow-circle-right display-5 text-primary"
          @click="toRight"
        ></i>
      </span>
      <div
        class="slider-inner"
        ref="inner"
        :style="`grid-template-columns: repeat(${getRepeatNumber()}, 1fr);`"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      innerSlider: document.querySelector(".slider-inner"),
      left: 0,
      arrowSize: 36,
    };
  },
  props: {
    length: Number,
    width: Number,
    gap: Number,
    itemWidth: Number,
    height: Number,
    extraItems: Number,
    itemMargin: {
      type: Number,
      default: 12
    }
  },
  methods: {
    getRepeatNumber() {
      let length = this.extraItems
        ? this.length + this.extraItems
        : this.length;
      if (this.itemWidth * length < this.width) {
        return parseInt(this.width / this.itemWidth);
      } else {
        return length;
      }
    },
    toRight() {
      let length = this.extraItems
        ? this.length + this.extraItems
        : this.length;
      let g = this.gap * this.itemWidth;
      let size = length * this.itemWidth - this.width + (length * this.itemMargin);
      if (this.left < -size + g) {
        this.$refs.inner.style.left = `-${size + this.arrowSize * 3}px`;
      } else {
        this.left = this.left - g;
        this.$refs.inner.style.left = `${this.left}px`;
      }
    },
    toLeft() {
      let g = this.gap * this.itemWidth;
      if (this.left == 0) {
        this.$refs.inner.style.left = `${this.arrowSize}px`;
      } else {
        this.left = this.left + g;
        this.$refs.inner.style.left = `${this.left}px`;
      }
    },
  },
};
</script>

<style scoped>
.slider {
  position: absolute;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.slider-inner {
  position: absolute;
  top: 0;
  left: 40px;
  height: 100%;
  width: 100%;
  display: grid;
  user-select: none;
  transition: 0.3s ease-in;
}
.arrow-left,
.arrow-right {
  position: absolute;
  z-index: 9999;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8);
  height: 100%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px 5px rgba(255, 255, 255, 0.8);
}
.arrow-left {
  left: 0px;
}
.arrow-right {
  right: 0px;
}
</style>