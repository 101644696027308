<template>
  <div class="middle">
    <div class="multi-range-slider">
      <input type="range" id="input-left" min="0" max="100" value="25" />
      <input type="range" id="input-right" min="0" max="100" value="75" />
      <div class="slider">
        <div class="track bg-secondary"></div>
        <div class="range bg-primary"></div>
        <div class="thumb left bg-white"></div>
        <div class="thumb right bg-white"></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.middle {
  position: relative;
  /* width: 50%; */
  /* max-width: 500px; */
  /* height: 50px; */
  /* background-color: green; */
}

.slider {
  position: relative;
  z-index: 1;
  height: 6px;
  margin: 0 15px;
}

.slider > .track {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 5px;
}

.slider > .range {
  position: absolute;
  z-index: 2;
  left: 25%;
  right: 25%;
  top: 0;
  bottom: 0;
  border-radius: 5px;
}

.slider > .thumb {
  position: absolute;
  z-index: 3;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  top: -9px;
  -webkit-box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.41);
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.41);
}

.slider > .thumb-left {
  left: 22%;
  transform: translate(-15px, -10px);
}
.slider > .thumb-right {
  right: 22%;
  transform: translate(15px, -10px);
}

input[type="range"] {
  position: absolute;
  -webkit-appearance: none;
  pointer-events: none;
  z-index: 2;
  height: 10px;
  width: 100%;
  opacity: 0;
}

input[type="range"]::-webkit-slider-thumb {
  pointer-events: all;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 0 none;
  background-color: red;
  -webkit-appearance: none;
}
</style>

<script>
export default {
  props: {},
  mounted() {
    this.inputLeft = document.getElementById("input-left");
    this.inputRight = document.getElementById("input-right");

    this.thumbLeft = document.querySelector(".slider > .thumb.left");
    this.thumbRight = document.querySelector(".slider > .thumb.right");
    this.range = document.querySelector(".slider > .range");

    this.inputLeft.addEventListener("input", this.setLeftValue);
    this.inputRight.addEventListener("input", this.setRightValue);

    this.inputLeft.addEventListener("mouseup", this.onDrop);
    this.inputRight.addEventListener("mouseup", this.onDrop);

    this.setLeftValue();
    this.setRightValue();
  },
  data() {
    return {
      inputLeft: null,
      inputRight: null,
      thumbLeft: null,
      thumbRight: null,
      range: null,
    };
  },
  methods: {
    setLeftValue() {
      var input = this.inputLeft;
      var min = parseInt(input.min);
      var max = parseInt(input.max);

      input.value = Math.min(parseInt(input.value), parseInt(this.inputRight.value) - 11);
      var percent = ((input.value - min) / (max - min)) * 100;
      this.thumbLeft.style.left = `${percent - 3.3}%`;
      this.range.style.left = `${percent - 3.3}%`;

      this.$emit("onDrag", {
        min: parseInt(this.inputLeft.value),
        max: parseInt(this.inputRight.value),
      });
    },
    setRightValue() {
      var input = this.inputRight;
      var min = parseInt(input.min);
      var max = parseInt(input.max);
      input.value = Math.max(parseInt(input.value), parseInt(this.inputLeft.value) + 11);
      var percent = ((input.value - min) / (max - min)) * 100;
      this.thumbRight.style.right = `${100 - percent - 3.3}%`;
      this.range.style.right = `${100 - percent - 3.3}%`;

      this.$emit("onDrag", {
        min: parseInt(this.inputLeft.value),
        max: parseInt(this.inputRight.value),
      });
    },
    onDrop() {
      this.$emit("onDrop", {
        min: parseInt(this.inputLeft.value),
        max: parseInt(this.inputRight.value),
      });
    },
  },
};
</script>
