<template>
  <div class="audience">
    <Sidebar />
    <NavBar
      current="Brochure Detail"
      :link="$paths.university.list"
      linkName="Resources"
    />
    <LoadingContainer v-if="loadingAll" />
    <div class="main-content" v-else>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-8">
            <div class="header mt-md-5">
              <div class="header-body border-0">
                <div class="row align-items-center">
                  <div class="col">
                    <h6 class="header-pretitle">Brochure Detail</h6>
                    <h1 class="header-title">{{item.title}}</h1>
                  </div>
                  <div class="col-auto">
                    <!-- <button type="button" class="btn btn-primary">
                      Edit
                      <i class="ms-2 fas fa-pencil-alt"></i>
                    </button> -->
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col">
                    <ul
                      class="nav nav-tabs header-tabs"
                      id="myTab"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation">
                        <a
                          @click.prevent="selectedTab = 0"
                          class="nav-link active"
                          id="overview-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#overview"
                          type="button"
                          role="tab"
                          aria-controls="overview"
                          aria-selected="true"
                        >
                          Overview
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          @click.prevent="selectedTab = 1"
                          class="nav-link"
                          id="details-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#details"
                          type="button"
                          role="tab"
                          aria-controls="details"
                          aria-selected="false"
                        >
                          Details
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="col-auto">
                      <button
                        type="button"
                        class="btn btn-danger"
                        :disabled="loadingDelete"
                        @click.prevent="deleteItem()"
                      >
                        <span
                          v-if="loadingDelete"
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Delete
                        <i class="ms-2 fas fa-trash"></i>
                      </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="tab-content" id="myTabContent">
              <!-- OVERVIEW -->
              <div
                class="table-responsive mt-5 tab-pane fade show active"
                id="overview"
                role="tabpanel"
                aria-labelledby="overview-tab"
              >
                <div class="row">
                  <div class="col-3">
                    <div class="card card-overview border-2">
                      <div class="card-header border-0">
                        <h2 class="card-header-title">Views</h2>
                      </div>
                      <div class="card-body pt-0"></div>
                      <div class="card-footer border-0">
                        <span class="h2 fas fa-eye text-primary"></span>
                        <span class="h3 ms-2">{{item.views}}</span>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col">
                    <div class="card card-overview border-2">
                      <div class="card-header border-0">
                        <h2 class="card-header-title">Deadline</h2>
                      </div>
                      <div class="card-body pt-0"></div>
                      <div class="card-footer border-0">
                        <span class="h3 fas fa-calendar-alt text-primary"></span>
                        <span class="h3 ms-2">December 2021</span>
                      </div>
                    </div>
                  </div> -->
                </div>

                <h2 class="h2 my-4">Students views</h2>
                <EmptyState
                  title="No View"
                  msg="This brochure has no views"
                  v-if="item.views == 0"
                >
                  <template v-slot:icon>
                    <i class="fas fa-eye fa-3x text-primary"></i>
                  </template>
                </EmptyState>
                <table
                  v-else
                  class="
                    table table-sm table-hover table-nowrap
                    card-table
                    bg-white
                  "
                >
                  <thead>
                    <tr>
                      <th
                        v-for="(column, index) in columns.overview"
                        :key="column"
                        :class="{ 'text-start': index == 0 }"
                        :width="index != 0 ? '1%' : 'auto'"
                      >
                        {{ column }}
                      </th>
                      <!-- <th width="1%">
                        <a href="#" class="text-muted"> Action </a>
                      </th> -->
                      <!-- <th width="1%"></th> -->
                    </tr>
                  </thead>
                  <tbody class="list">
                    <tr
                      v-for="st in item.students"
                      v-bind:key="st._id"
                      class="cursor-pointer"
                    >
                      <td @click="itemPressed(st)" class="text-start">
                        <strong>[Student Name]</strong>
                      </td>
                      <td @click="itemPressed(st)">October 4</td>
                      <td @click="itemPressed(st)">
                        <span class="ms-auto badge bg-primary-soft">99%</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- DETAILS TAB -->

              <div
                class="table-responsive mt-5 tab-pane fade"
                id="details"
                role="tabpanel"
                aria-labelledby="details-tab"
              >
                <div class="row" style="height: 1000px">
                  <div class="col-12">
                    <object
                      :data="item.file"
                      type="application/pdf"
                      class="w-100 h-100"
                    >
                      <iframe
                        style="width: 100%"
                        :src="`https://docs.google.com/viewer?url=${item.file}&embedded=true`"
                      ></iframe>
                    </object>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BrochureDetailController from "./BrochureDetailController";
export default BrochureDetailController;
</script>
<style>
.card.card-overview {
  border-radius: 25px;
  height: 150px;
}
</style>
