import { email, required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';


export default {
  mounted() {
    this.$store.commit('clearSession')

  },
  name: 'Signin',
  data() {
    return {
      errorMessage: '',
      loading: false,
      form: {
        email: '',
        password: '',
        // email: 'usuario.teste@novva.tech',
        // password: 'q1w2e3r4',
      },
      passwordFieldType: 'password'
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    }
  },
  methods: {
    ...mapActions([
      'syncSessionWithApi'
    ]),
    togglePassword() {
      this.passwordFieldType = this.passwordFieldType == 'password' ? 'text' : 'password'
    },
    async signin() {
      this.errorMessage = ''
      this.loading = true
      try {
        const res = await this.$userService.signin({ ...this.form })
        await this.syncSessionWithApi({ token: res.data.token })
        this.$router.push(this.$paths.home)
      } catch (e) {
        if (e.message == "user's e-mail has not been verified yet") {
          this.$router.push({ path: this.$paths.signup.confirmation, query: this.form })
        }
        this.errorMessage = e.message
      } finally {
        this.loading = false
      }
    }
  }
};