<template>
  <div>
    <Sidebar />
    <NavBar current="Appointment" :link="$paths.agenda.list" linkName="Diary" />
    <LoadingContainer v-if="loadingAll" />
    <Container col="col-12 col-lg-10 col-xl-8" v-else>
      <div class="header mt-md-4 border-bottom">
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col">
              <h1 class="header-title">Appointment</h1>
            </div>
            <div class="col-auto">
              <button
                v-if="item.status != 'CANCELLED'"
                class="btn btn-outline-secondary"
                @click.prevent="cancelAppointment()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="pb-5 border-bottom" v-if="item.dateTime">
        <div class="row">
          <div class="col d-flex align-items-center">
            <i class="fas fa-calendar display-4 text-primary me-2"></i>
            <h2 class="h2 mb-0 me-4">{{ date }}</h2>
            <i class="fas fa-clock display-4 text-primary me-2"></i>
            <h2 class="h2 mb-0">
              {{ `${item.dateTime.start} to ${item.dateTime.end}` }}
            </h2>
          </div>
          <div class="col-auto d-flex align-items-center">
            <p class="mb-0 text-muted">
              {{ $agendaService.status[item.status] }}
            </p>
          </div>
        </div>
      </div>

      <div
        class="py-5 border-bottom"
        v-if="item.lastRechedule && item.status == 'RESCHEDULED'"
      >
        <div class="row">
          <div class="col-12">
            <h2 class="h2">New Agenda</h2>
          </div>
          <div class="col d-flex align-items-center">
            <i class="fas fa-calendar display-4 text-primary me-2"></i>
            <h2 class="h2 mb-0 me-4">{{ rescheduleDate }}</h2>
            <i class="fas fa-clock display-4 text-primary me-2"></i>
            <h2 class="h2 mb-0">
              {{
                `${item.lastRechedule.dateTime.start} to ${item.lastRechedule.dateTime.end}`
              }}
            </h2>
          </div>
          <div class="col-auto d-flex align-items-center">
            <p>
              {{ $agendaService.status[item.lastRechedule.status] }}
            </p>
          </div>
          <div class="col-12">
            <p
              v-if="
                item.lastRechedule.accept.institution &&
                !item.lastRechedule.accept.student
              "
              class="pt-4 text-muted"
            >
              <span class="fas fa-exclamation-circle text-warning"></span> You
              requested a new date for the appointment. Wait for them to accept
              or suggest yet another date
            </p>
            <p
              v-if="
                !item.lastRechedule.accept.institution &&
                item.lastRechedule.accept.student
              "
              class="pt-4 text-muted"
            >
              <span class="fas fa-exclamation-circle text-warning"></span>
              Student suggested a new date for the appointment. You can accept
              or suggest another date.
            </p>
          </div>
        </div>
      </div>

      <div class="py-5 border-bottom" v-if="item.student">
        <div class="row">
          <div class="col-12">
            <h2 class="h2">Meeting with</h2>
          </div>
          <div class="col d-flex align-items-center">
            <ImagePlaceholder
              :src="item.student.image"
              :width="'52px'"
              :height="'52px'"
            >
              <template v-slot:placeholder>
                <i class="fas fa-user-circle display-2 text-primary me-3"></i>
              </template>
            </ImagePlaceholder>
            <!-- <i class="fas fa-user-circle display-2 text-primary me-3"></i> -->
            <div>
              <h2 class="h2 mb-0">
                {{ item.student.name }}
                <span v-if="item.match" class="ms-2 badge bg-primary-soft"
                  >{{ item.match.value }}%</span
                >
              </h2>
              <p v-if="item.student.location" class="mb-0">
                {{ item.student.location.region }},
                {{ item.student.location.city }}
              </p>
            </div>
          </div>
          <div class="col-auto d-flex align-items-center">
            <router-link :to="`${$paths.students.detail}/${item.student._id}`"
              >See Full Profile</router-link
            >
          </div>
        </div>
      </div>

      <!-- <button @click="goToMeeting" class="btn btn-primary">
        <span
          v-if="loadingCheck"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        <i class="fas fa-video me-2"></i>
        Enter waiting room teste
      </button> -->

      <div class="py-5">
        <div class="row" v-if="item.status == 'ACCEPTED'">
          <div class="col-12">
            <div class="d-grid gap-2">
              <button
                disabled
                class="btn btn-primary"
                v-if="item.appointmentIsInTheFuture"
              >
                {{
                  $helper.getRemainingTime(
                    `${item.dateTime.date} ${item.dateTime.start}`
                  )
                }}
              </button>
              <!-- v-if="canEnterTheRoom" -->
              <button
                @click="goToMeeting"
                class="btn btn-primary"
                v-if="canEnterTheRoom"
              >
                <span
                  v-if="loadingCheck"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <i class="fas fa-video me-2"></i>
                Enter waiting room
              </button>
              <button disabled class="btn btn-primary" v-if="actionDisabled">
                <i class="fas fa-video me-2"></i>
                Appointment unavailable
              </button>
            </div>
          </div>
        </div>
        <div
          class="row"
          v-if="canSuggestOtherDay"
        >
          <div
            class="col-12 col-md-6"
            v-if="
              item.status == 'AWAITING_ACCEPT' ||
              (item.status == 'RESCHEDULED' && item.accept.student)
            "
          >
            <div class="d-grid gap-2">
              <button @click.prevent="otherDateClicked" class="btn btn-custom">
                Suggest other day
              </button>
            </div>
          </div>
          <div
            class="col-12 col-md-6"
            v-if="
              item.appointmentIsInTheFuture &&
              (item.status == 'AWAITING_ACCEPT' ||
              (item.status == 'RESCHEDULED' && !item.accept.institution))
            "
          >
            <div class="d-grid gap-2">
              <button
                class="btn btn-primary"
                :disabled="loadingAccept"
                @click="acceptAppointment"
              >
                <span
                  v-if="loadingAccept"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
    </Container>
    <ModalComponent title="Suggest other day" id="newDateModal">
      <template v-slot:body>
        <form @submit.prevent="submitNewDate">
          <div class="form-group">
            <label class="form-label required"> Month </label>
            <button
              id="btnGroupDrop1"
              type="button"
              class="
                btn btn-white
                text-dark
                fw-bold
                dropdown-toggle
                text-start
                d-flex
                justify-content-between
                align-items-center
              "
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style="
                width: 100%;
                height: 41px;
                border: 1px solid #d0cfcf;
                border-radius: 0.375rem !important;
                font-weight: 400 !important;
              "
            >
              {{ eventLabel.month }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
              <li
                class="dropdown-item cursor-pointer"
                v-for="(e, i) in availableMonths"
                :key="i"
                @click="setMonth(i)"
              >
                {{ e.label }}
              </li>
            </ul>
          </div>
          <div class="form-group">
            <label class="form-label required"> Day </label>
            <button
              id="btnGroupDrop1"
              type="button"
              :disabled="loading"
              class="
                btn btn-white
                text-dark
                fw-bold
                dropdown-toggle
                text-start
                d-flex
                justify-content-between
                align-items-center
              "
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style="
                width: 100%;
                height: 41px;
                border: 1px solid #d0cfcf;
                border-radius: 0.375rem !important;
                font-weight: 400 !important;
              "
            >
              <span
                v-if="loading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span v-else>
                {{ eventLabel.day }}
              </span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
              <li
                class="dropdown-item cursor-pointer"
                v-for="(e, i) in availableDays"
                :key="i"
                @click="setDay(i)"
              >
                {{ e.label }}
              </li>
            </ul>
          </div>
          <div class="form-group">
            <label class="form-label required"> Hour </label>
            <button
              :disabled="loading"
              id="btnGroupDrop1"
              type="button"
              class="
                btn btn-white
                text-dark
                fw-bold
                dropdown-toggle
                text-start
                d-flex
                justify-content-between
                align-items-center
                loading
              "
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style="
                width: 100%;
                height: 41px;
                border: 1px solid #d0cfcf;
                border-radius: 0.375rem !important;
                font-weight: 400 !important;
              "
            >
              <span
                v-if="loading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span v-else>
                {{ eventLabel.time }}
              </span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
              <li
                class="dropdown-item cursor-pointer"
                v-for="(e, i) in availableTimes"
                :key="i"
                @click="setTime(i)"
              >
                {{ e.label }}
              </li>
            </ul>
          </div>
          <div class="py-5">
            <div class="row">
              <div class="col-12 text-center">
                <p class="text-danger">{{ errorMessageDate }}</p>
              </div>
            </div>
          </div>

          <small class="text-muted"
            >You must fill all the required fields
            <small class="text-danger">*</small> to continue</small
          >
          <!-- :disabled="$v.form.$invalid" -->
          <button type="submit" class="btn w-100 btn-primary my-3">
            <span
              v-if="loadingNewDate"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Save and continue
          </button>
        </form>
      </template>
      <template v-slot:footer>
        <!-- <button type="button" @click="cropImage" class="btn btn-primary">
          Publish
        </button> -->
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import AppointmentController from "./AppointmentController";
export default AppointmentController;
</script>

<style scoped>
.btn-custom {
  color: #ed6125;
  background-color: #fad8c8;
  border-color: #fad8c8;
}

.btn-check:checked + .btn-custom:focus,
.btn-check:active + .btn-custom:focus,
.btn-custom:active:focus,
.btn-custom.active:focus,
.show > .btn-custom.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.15rem rgb(240 121 70 / 50%);
}

.btn-custom:hover {
  background-color: #ffba9a;
  border-color: #ffba9a;
}

.btn-check:checked + .btn-custom,
.btn-check:active + .btn-custom,
.btn-custom:active,
.btn-custom.active,
.show > .btn-custom.dropdown-toggle {
  background-color: #ffa57b;
  border-color: #ffa57b;
}
</style>
