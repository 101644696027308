<template>
  <div class="profileviews">
    <div class="">
      <div class="">
        <BarChart :chartData="chartData" />
      </div>
    </div>
  </div>
</template>
<script>
import ProfileViewsController from "./ProfileViewsController";
export default ProfileViewsController;
</script>
<style scoped></style>
