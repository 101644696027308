import MultiRangeSlider from "@/components/MultiRangeSlider.vue";
import { email, required } from "vuelidate/lib/validators";

export default {
  name: "Audience",
  watch: {
    $route(to, from) {
      this.selectedTab = (to.query.tab || 0)
    }
  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
  },
  mounted() {
    this.loadingAll = true
    this.getItems()
    this.selectedTab = this.$route.query.tab || 0
  },
  data() {
    return {
      loadingAll: false,
      allSelected: false,
      filtersCount: 0,
      selectedTab: 0,
      totalCost: 0,
      columns: {
        requests: [
          'Name',
          'Location',
          'School Type',
          'Match',
          'Cost to accept ',
        ],
        followers: [
          'Name',
          'Location',
          'School Type',
          'Match',
        ]
      },
      loadings: [],
      loadingAccept: false,
      requests: [],
      followers: [],
      showingRequests: [],
      showingFollowers: [],
      filters: {
        match: {
          min: 20,
          max: 100
        },
        school_type: {
          public: false,
          private: false
        },
        location: {
          us: false,
          other_countries: false
        },

      }
    };
  },
  methods: {
    getItems() {
      this.getRequests()
      this.getFollowers()
      this.totalCost = 0
      this.allSelected = false
    },
    getRequests() {
      Promise.all([this.$audienceService.getRequests(), this.$audienceService.getFollowers()]).then((values) => {
        let requests = values[0].data.data
        let followers = values[1].data.data


        requests.map(r => {
          r.userName = `${r.user.profile.firstname} ${r.user.profile.lastname}`
        })
        this.$set(this, 'requests', requests)
        this.$set(this, 'showingRequests', requests)



        followers.map(r => {
          r.userName = `${r.user.profile.firstname} ${r.user.profile.lastname}`
        })
        this.$set(this, 'followers', followers)
        this.$set(this, 'showingFollowers', followers)


      }).catch(e => console.log(e)).finally(() => this.loadingAll = false)
    },
    getFollowers() {
      this.$audienceService.getFollowers()
        .then(res => {
          res.data.data.map(r => {
            r.userName = `${r.user.profile.firstname} ${r.user.profile.lastname}`
          })
          this.$set(this, 'followers', res.data.data)
          this.$set(this, 'showingFollowers', res.data.data)
        }).catch(e => console.log(e))
    },

    calculateTotalCost() {
      const t = this.requests
        .filter((r) => r.selected)
        .reduce((a, b) => a + b.costToAccept, 0)
      this.totalCost = t
    },

    toggleSelectAll() {
      this.allSelected = !this.allSelected
      this.requests = this.requests.map(r => { return { ...r, selected: this.allSelected } })
      this.$set(this, 'showingRequests', this.requests)
      this.calculateTotalCost()
    },
    clearSelected() {
      this.allSelected = false
      this.requests = this.requests.map(r => { return { ...r, selected: false } })
      this.$set(this, 'showingRequests', this.requests)
      this.calculateTotalCost()
    },
    toggleSelectItem(item) {
      let req = this.requests.map(r => {
        return { ...r, selected: r._id == item._id ? !item.selected : r.selected }
      })
      this.$set(this, 'requests', req)
      this.$set(this, 'showingRequests', req)
      this.allSelected = false
      this.calculateTotalCost()
    },
    itemPressed(item) {

      this.$router.push({ path: `${this.$paths.students.detail}/${item.user._id}`, query: { request: JSON.stringify(item) } })
    },
    filterChanged() {
      const schoolCount = Object.keys(this.filters.school_type).filter(s => this.filters.school_type[s]).length
      const locationCount = Object.keys(this.filters.location).filter(s => this.filters.location[s]).length
      const matchCount = (this.filters.match.min != 20 || this.filters.match.max != 100) ? 1 : 0
      this.filtersCount = schoolCount + locationCount + matchCount
    },
    matchProgressDrag(values) {
      this.filters.match = { ...values }
    },
    matchProgressDrop(values) {
      // faz a chamada da api
      console.log('dropou', values)
    },
    searchString(e) {
      const req = this.$helper.searchString(this.requests, e.target.value)
      const foll = this.$helper.searchString(this.followers, e.target.value)
      console.log(foll)
      this.$set(this, 'showingRequests', req.filter((ftr) => !ftr.hidden))
      this.$set(this, 'showingFollowers', foll.filter((ftr) => !ftr.hidden))
    },
    async acceptRequests(index = -1) {
      try {
        if (this.userSession.credits < this.totalCost || this.userSession.credits == 0) {
          this.$eventBus.$emit("toggleWalletModal");
          return
        }
        let ids = []
        if (index > -1) {
          ids = [this.requests[index]._id]
          this.$set(this.loadings, index, true)
        } else {
          ids = this.requests.filter(f => f.selected).map(g => g._id)
        }
        this.loadingAccept = true
        const res = await this.$audienceService.acceptRequests(ids)
        this.getItems()
        this.$eventBus.$emit('showToast', { title: `${ids.length} Follower(s) Accepted`, msg: `${this.totalCost} credits were deducted from your wallet.` })
      } catch (e) {

      } finally {
        this.loadingAccept = false
        if (index > -1) {
          this.$set(this.loadings, index, false)
        }
      }
    },
    handleSelectTab(index) {
      this.$router.push({ path: `${this.$paths.audience}`, query: { tab: index } }).catch(e => { })
    },
  },

  validations: {
    email: {
      required,
      email,
    },
  },
  components: {
    MultiRangeSlider
  },
};