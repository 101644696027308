
export default {
  name: "ProfileViews",
  mounted() { },
  data() {
    return {
    }
  },
  methods: {
  },
  components: {
  },
};