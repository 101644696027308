const notificationLinks = {
  "CREDITS_PURCHASE": 'wallet',
  "REQUEST_ACCEPT": 'audience',
  "POST_COMMENT": 'community',
  "STUDENT_START_CHAT": 'messages',
}


module.exports = {
  notificationLinks
}
