import EmptyState from "@/components/EmptyState.vue";
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/Sidebar.vue";
import { Modal } from 'bootstrap';
import { email, required } from "vuelidate/lib/validators";
import LoadingContainer from "@/components/LoadingContainer.vue";


export default {
  name: "Brochure Detail",
  mounted() {
    this.loadingAll = true
    this.getData()
  },
  data() {
    return {
      loadingDelete: false,
      loadingAll: false,
      brochureId: this.$route.params.id,
      walletModal: null,
      allSelected: false,
      filtersCount: 0,
      selectedTab: 0,
      columns: {
        overview: [
          'Student',
          'Date',
          'Match',
        ]
      },

      item: {},
      filters: {
        match: {
          min: 20,
          max: 100
        },
        school_type: {
          public: false,
          private: false
        },
        location: {
          us: false,
          other_countries: false
        },

      }
    };
  },
  methods: {
    async getData() {
      try {
        const res = await this.$universityService.showBrochure(this.brochureId)
        let b = res.data.data.brochure
        b.views = res.data.data.views
        this.$set(this, 'item', b)
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingAll = false
      }
    },
    itemPressed(item) {
      this.$router.push({ path: `${this.$paths.students.detail}/${item.id}` })
    },
    async deleteItem() {
      this.$eventBus.$emit("showConfirmationAlert", {
        message: "Are you sure you want to remove this brochure permanently?",
        confirmCallback: async () => {
          try {
            this.loadingDelete = true
            const res = await this.$universityService.deleteBrochure(this.brochureId)
            this.$eventBus.$emit('showToast', { title: 'Brochure deleted', msg: `The brochure was deleted successfully` })
            this.$router.back()
          } catch (e) {
            console.log(e)
          } finally {
            this.loadingDelete = false
          }
        },
      });
      // if (confirm('Are you sure you want to remove this brochure permanently ?')) {
      //   try {
      //     this.loadingDelete = true
      //     const res = await this.$universityService.deleteBrochure(this.brochureId)
      //     this.$eventBus.$emit('showToast', { title: 'Brochure deleted', msg: `The brochure was deleted successfully` })
      //     this.$router.back()
      //   } catch (e) {
      //     console.log(e)
      //   } finally {
      //     this.loadingDelete = false
      //   }
      // }
    }
  },

  validations: {
    email: {
      required,
      email,
    },
  },
  components: {
  },
};