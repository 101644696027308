import Container from "@/components/Container.vue";
import EmptyState from "@/components/EmptyState.vue";
import ImageCropper from "@/components/ImageCropper/ImageCropper";
import { Modal } from "bootstrap";
import ModalComponent from "@/components/ModalComponent";
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/Sidebar.vue";
import "cropperjs/dist/cropper.css";
import VueCropper from "vue-cropperjs";
import { required } from 'vuelidate/lib/validators';


export default {
    mounted() {
        this.weekdaysModal = new Modal(document.getElementById("weekdays_modal"));
        setTimeout(() => {
            this.calculateWidth()
        }, 1000);
        this.getCollections()
    },
    created() {
        window.addEventListener("resize", this.calculateWidth);
    },
    data() {
        return {
            newCollectionTitle: '',
            newCollectionErrorMessage: '',
            loadingCreatingCollection: false,
            errorMessage: '',
            loading: false,
            form: {
                collection: '',
                fileImage: '',
                // imageArray: ['https://picsum.photos/200/300', 'https://picsum.photos/200/300', 'https://picsum.photos/200/300', 'https://picsum.photos/200/300', 'https://picsum.photos/200/300']
                imageArray: []
            },
            albums: [],
            percentage: 0,
            uploading: false,
            counter: null,
            fileName: '',
            eventLabel: 'Select Photo Album',
            customSelectWidth: 724,
            customFileInputWidth: 724,
            loadingPic: false
        }
    },
    validations: {
        newCollectionTitle: { required },
        form: {

            collection: { required },
            // fileImage: { required }
        }
    },
    components: {
        VueCropper,
        ImageCropper
    },
    methods: {
        async getCollections() {
            try {
                const res = await this.$universityService.getAlbums()
                this.$set(this, 'albums', res.data.data || [])
                
            } catch (e) {
                this.$set(this, 'albums', [])
                console.log(e)
            }
        },
        setIframe() {
            this.embed = this.video_link.replace('watch?v=', 'embed/').split('&')[0]
            this.hasLink = true
        },
        calculateWidth() {
            const width = this.$refs.titleInput?.clientWidth
            this.customSelectWidth = width
            this.customFileInputWidth = width
        },
        openNewAlbumModal() {
            this.weekdaysModal.toggle();
        },
        async createCollection() {
            try {
                this.$set(this, 'loadingCreatingCollection', true)
                this.newCollectionErrorMessage = ''
                if (this.album && this.albums.filter(r => r.title.toLowerCase() == this.newCollectionTitle.toLowerCase()).length > 0) {
                    this.newCollectionErrorMessage = 'You already have a collection with the same title'
                    return
                }
                const res = await this.$universityService.createCollection({ title: this.newCollectionTitle })
                this.weekdaysModal.toggle();
                const newItem = { _id: res.data.data, title: this.newCollectionTitle }
                this.$set(this, 'albums', [...this.albums, newItem])
                this.setCollection(newItem)
            } catch (e) {
                console.log(e)
                this.newCollectionErrorMessage = e.message
            } finally {
                this.$set(this, 'loadingCreatingCollection', false)
            }
        },
        setCollection(e) {
            this.form.collection = e._id
            this.eventLabel = e.title
        },
        async save() {
            this.errorMessage = ''
            this.loading = true
            try {

                const res = await this.$universityService.createPhoto({ ...this.form })
                this.$eventBus.$emit('showToast', { title: 'Photo Uploaded', msg: `${this.form.title} was uploaded successfully.` })
                this.$router.push({ path: `${this.$paths.university.list}`, query: { tab: 2 } }).catch(e => { })
                
            } catch (e) {
                this.errorMessage = e.message
            } finally {
                this.loading = false
            }

        },
        cropEnd(image) {
            this.loadingPic = true
            let imgUrl = this.form.imageArray
            this.$universityService.createPhotoUrl({fileImage: image}).then(r => {
                imgUrl.push(r.signedUrl)
            }).finally(() => {
                this.loadingPic = false
            })
            this.$eventBus.$emit('clearImageCropper')
        },
        removeImage(index) {
            this.form.imageArray.splice(index, 1)
        }
    }
}