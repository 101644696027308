import NavBar from "@/components/NavBar";
import Sidebar from "@/components/Sidebar";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { required, minLength } from 'vuelidate/lib/validators';
import { isExpirationDateValid } from '@/utils/Validators';
import { Toast } from 'bootstrap'
import FinancialService from '@/services/FinancialService'
import { mapActions } from 'vuex';



export default {
    mounted() {
        this.getPacks()

    },
    data() {
        return {
            // STEPS
            errorMessage: '',
            loading: false,
            form: {
                creditAmount: 0,
                paymentDetails: {
                    holder: "",
                    cardNumber: "",
                    expiration: "",
                    cvv: ""
                },
                billingAddress: {
                    address: "",
                    city: "",
                    state: "",
                    zipcode: ""
                }

            },
            step: 1,
            percentage: 50,
            paymentMethod: 'credit_card',
            prices: []
        };
    },
    components: {
    },
    validations: {
        form: {
            creditAmount: { required },
            paymentDetails: {
                holder: { required },
                cardNumber: {
                    required,
                    minLength: minLength(16)
                },
                expiration: { required, minLength: minLength(7), isExpirationDateValid },
                cvv: { required }
            },
            billingAddress: {
                address: { required },
                city: { required },
                state: { required },
                zipcode: { required }
            }

        },
    },
    methods: {
        ...mapActions([
            'syncSessionWithApi'
          ]),
        async getPacks() {
            const res = await this.$financialService.getPacks()
            let it = res.data.data
            this.$set(this, 'prices', res.data.data)
            this.handleItemPressed(it[0])
        },
        nextStep(s) {
            this.step = s
            this.percentage = this.percentage + 50
        },
        handleItemPressed(item) {
            this.$set(this, 'prices', this.prices.map(r => { return { ...r, selected: r._id == item._id } }))
            const selected = this.prices.filter(r => r.selected)[0]
            this.$set(this.form, 'creditAmount', selected.credits)
            this.$set(this.form, 'creditPack', selected._id)
        },
        async handleCreateDeposit() {
            this.errorMessage = ''
            this.loading = true
            try {
                const res = await this.$financialService.createDeposit({ ...this.form })
                this.syncSessionWithApi()
                this.$eventBus.$emit('showToast', { title: 'Credits Added', msg: `${this.form.creditAmount} credits were added to your wallet.` })
                this.$router.push({ path: this.$paths.wallet.list })
            } catch (e) {
                this.errorMessage = e.message
            } finally {
                this.loading = false
            }
        }
    },
};