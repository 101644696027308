import BarChart from '@/components/BarChart.vue';
import moment from "moment";
export default {
  name: "ProfileViews",
  mounted() {
    this.setupStatsCards()
  },
  
  data() {
    return {
      requestsToFollow: 0,
      chartData: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        bgColors: this.getBgColors(),
        values: [321, 1000, 23, 5, 545, 93, 923, 805, 333, 12, 677, 222]
      }

    }
  },
  methods: {
    setupStatsCards() {
      this.$agendaService.getRequests().then(r => {
        this.requestsToFollow = r.data.data.filter(r => r.status == 'pending' || r.status == '').length
      }).catch(e => { })
    },
    getBgColors() {
      const month = moment().format('M')
      var arr = []
      for (let index = 0; index < 12; index++) {
        arr.push(month == (index + 1) ? '#ed6125' : '#ffeee7')
      }
      return arr
    }
  },
  components: {
    BarChart
  },
};


