<template>
  <div>
    <Sidebar />
    <NavBar current="Member Detail" :link="$paths.team.list" linkName="Team" />
        <LoadingContainer v-if="loadingAll" />
    <Container col="col-12 col-lg-10 col-xl-8" v-else>
      <div class="header mt-md-4 border-bottom">
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="header-pretitle">Member Detail</h6>
              <h1 class="header-title">{{ item.name }}</h1>
            </div>
            <div class="col-auto">
              <button class="btn btn-outline-secondary me-3">
                <i class="fas fa-trash"></i> Delete
              </button>
              <button class="btn btn-primary">
                <i class="fas fa-pencil-alt"></i> Edit
              </button>
            </div>
          </div>
        </div>
      </div>
      <form>
        <div class="row justify-content-between align-items-center mb-5">
          <div class="col">
            <div class="row align-items-center">
              <div class="col-auto">
                <div class="avatar avatar-xl">
                  <img
                    class="avatar-img rounded-circle"
                    :src="item.image"
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label"> Job Title </label>
              <input
                type="email"
                class="form-control"
                disabled
                value="Director"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label class="form-label"> Email </label>
              <input
                type="text"
                class="form-control"
                disabled
                :value="item.email"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label class="form-label"> Permissions </label>
              <input
                type="text"
                class="form-control"
                disabled
                :value="item.role"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label class="form-label"> Location </label>
              <input
                type="text"
                class="form-control"
                disabled
                :value="item.locations.join(', ')"
              />
            </div>
          </div>
        </div>
      </form>
    </Container>
  </div>
</template>

<script>
import MemberDetailController from "./MemberDetailController";
export default MemberDetailController;
</script>

<style>
</style>