<template>
  <div class="base-input-lo-wrapper">
    <input
      :id="id"
      type="text"
      class="form-control"
      ref="ipt"
      :value="autoValue"
      :placeholder="placeholder"
      autocomplete="off"
    />
  </div>
</template>
<script>
export default {
  name: 'BaseInputLocation',

  props: {
    placeholder: {
      type: String,
      default: ''
    },

    value: {
      type: Object
    },

    hasError: {
      type: Boolean,
      default: false
    },

    country: {
      type: String,
    }
  },

  data() {
    return {
      auto: '',
      autoValue: '',
      id: ''
    }
  },

  computed: {
    isValueEmpty() {
      return !this.value || Object.entries(this.value).length === 0
    }
  },

  methods: {
    autocompleteInit() {
      this.auto = new window.google.maps.places.Autocomplete(
        this.$refs['ipt'],
        {
          types: ['(regions)']
        }
      )

      if (this.country) {
        this.auto.setComponentRestrictions({
          country: [this.country.toLowerCase()],
        });
      }
      
      this.auto.setFields(['address_component', 'geometry'])
      this.auto.addListener('place_changed', () => {
        const plcs = this.auto.getPlace()
        const plc = {}

        plcs.address_components.forEach(item => {
          switch (item.types[0]) {
            case 'country':
              plc.country = item.long_name
              plc.countryInitials = item.short_name
              break
            case 'administrative_area_level_1':
              plc.region = item.long_name
              break
            case 'colloquial_area':
              plc.city = item.long_name
              break
            case 'locality':
              plc.city = item.long_name
              // for a city-state like Monaco or Singapore, we don't have the
              // administrative_area_level_1, Google only returns country and locality,
              // so the last should be used as region as well.
              if (!plc.region) {
                plc.region = item.long_name
              }
              break
            case 'administrative_area_level_2':
              if (!plc.city) {
                plc.city = item.long_name
              }
              break
            case 'administrative_area_level_3':
              if (!plc.region) {
                plc.region = item.long_name
              }
              break
          }
        })

        if (plcs.geometry.location) {
          plc.coordinates = {}
          plc.coordinates.lat = plcs.geometry.location.lat()
          plc.coordinates.lng = plcs.geometry.location.lng()
        }

        this.autoValue = `${plc.city ? plc.city + ', ' : ''}${
          plc.region ? plc.region + ', ' : ''
        }${plc.country}`

        this.$emit('input', plc)
      })
    },
    setGoogleAutocompleteNo() {
      try {
        const isOpera =
          !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0
        const isEdge = /Edg/.test(navigator.userAgent)
        const isChrome =
          /Google Inc/.test(navigator.vendor) && !isEdge && !isOpera

        if (!isChrome) return

        const autocompleteInput = document.getElementById(this.id)

        const observerHack = new MutationObserver(() => {
          observerHack.disconnect()
          autocompleteInput.setAttribute('autocomplete', 'no')
        })

        observerHack.observe(autocompleteInput, {
          attributes: true,
          attributeFilter: ['autocomplete']
        })
      } catch (e) {
        console.error('BaseInputLocation/setGoogleAutocompleteNo', e)
      }
    },
  },

  mounted() {
    const self = this

    if (!this.isValueEmpty) {
      this.autoValue = `${this.value.city}, ${
        this.value.region != '' ? this.value.region + ',' : ''
      } ${this.value.country}`
    }

    this.setGoogleAutocompleteNo()

    if (window.google) {
      this.autocompleteInit()
    } else {
      // Add an event listener
      window.document.addEventListener(
        'google-autocomplete-loaded',
        function() {
          self.autocompleteInit()
        }
      )
    }
  },

  created() {
    let result = ''
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < 20; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    this.id = result
  },

  watch: {
    value(value) {
      if (value) {
        this.autoValue = `${value.city}, ${
          value.region != '' ? value.region + ',' : ''
        } ${value.country}`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.base-input-lo-wrapper {
  width: 100%;

  .base-input-lo {
    // @include input-container-style;
    height: 60px;
    width: 100%;
    padding: 0 20px;

    &-error {
      border: 1px solid red;
    }
  }
}
</style>
