<template>
  <div class="card align-items-center text-center">
    <div class="card-body">
      <!-- Heading -->
      <span class="fas fa-lock fa-2x text-muted mb-3"></span>

      <!-- Text -->
      <p class="small text-muted mb-0">
        To see content, you need to accept
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivateContent",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .card {
    background-color: #ececec;
  }
</style>
