import AppointmentsCalendar from "@/components/AppointmentsCalendar/AppointmentsCalendar.vue";
import BarChart from '@/components/BarChart.vue';
import MonthSelector from "@/components/MonthSelector.vue";
import moment from "moment";
import { email, required } from "vuelidate/lib/validators";
import Appointments from "./Appointments/Appointments.vue";
import ProfileViews from "./ProfileViews/ProfileViews.vue";
import RequestsToFollow from "./RequestsToFollow/RequestsToFollow.vue";


export default {
  name: "Overview",
  mounted() {
    this.loadingAll = true
    this.setupTasks()
    this.getOverview()
    this.getAppointments()
    this.getAgenda()
  },
  data() {
    return {
      loadingAll: false,
      chartData: null,
      chartValues: {

      },
      currentDate: moment().format('YYYY-MM'),
      newFollowers: {
      },
      scholarshipApplications: {
      },
      usedCredits: {
      },
      posts: {
      },
      requestsToFollow: {
      },
      appointmentRequests: {
      },
      appointments: [],
      selectedTab: 0,
      tasks: {
        registration: false,
        availability: false,
        post: false,
        scholarship: false,
        brochure: false,
        credits: false,
      },
    }
  },
  methods: {

    getBgColors() {
      const month = moment().format('M')
      var arr = []
      for (let index = 0; index < 12; index++) {
        arr.push(month == (index + 1) && this.currentDate == moment().format('YYYY-MM') ? '#ed6125' : '#ffeee7')

      }
      return arr
    },
    getOverview() {
      this.$universityService.getOverview(this.currentDate).then(r => {
        this.overviewData = r.data.data
        this.setupOverview()
      }).catch(e => { })
    },
    setupOverview() {

      // this.overviewData.map(r => {
      //   console.log(r.date, moment(r.date).format('YYYY-MM'), this.currentDate, this.currentDate == moment(r.date).format('YYYY-MM'))
      // })



      let data = [...this.overviewData].filter(r => {
        return r.date.includes(this.currentDate)
      })
      if (data.length > 0) {
        data = data[0]
        this.requestsToFollow = data.requestsToFollow
        this.posts = data.posts
        this.newFollowers = data.newFollowers
        this.appointmentRequests = data.appointmentRequests
        this.scholarshipApplications = data.scholarshipApplications
        this.usedCredits = data.usedCredits
      } else {
        let d = {
          total: 0,
          partial: 0
        }
        this.requestsToFollow = d
        this.posts = d
        this.newFollowers = d
        this.scholarshipApplications = d
        this.usedCredits = d
        this.appointmentRequests = d
      }

      this.setupCharts()

    },
    setupCharts() {
      let v = {
        appointments: [],
        views: [],
        requests: []
      }

      this.overviewData.map(r => {
        v.appointments.push(r.appointmentRequests.partial)
        v.requests.push(r.requestsToFollow.partial)
        v.views.push(r.newFollowers.partial) //TODO: profile views nao ta vindo do back 
      })

      this.$set(this, 'chartValues', v)
      this.handleSelectTab(this.selectedTab)
    },
    async getAgenda() {
      try {
        const r = await this.$agendaService.getAgenda()
        this.tasks.availability = true
        this.getAppointments()
      } catch (e) {
        this.tasks.availability = false
      }
    },
    getAppointments() {
      this.$agendaService.getAppointments(moment().format('YYYY/MM/DD'),).then(r => {
        const appointments = r.data.data.filter(r => moment(r.date).format('DD-MM') == moment().format('DD-MM') && r.status != 'AWAITING_ACCEPT').map(r => {
          return {
            ...r,
            fromHour: moment(r.date).format('HH:mm'),
            toHour: moment(r.date).add(1, 'hours').format('HH:mm')
          }

        })
        this.$set(this, 'appointments', appointments)
      }).catch(e => { })
    },
    setupTasks() {
      this.$universityService.getProfile().then(r => {
        this.tasks.registration = r.data.data.institution.location ? true : false
        this.profileData = r.data.data
        this.tasks.scholarship = r.data.data.scholarships.length > 0
        this.tasks.post = r.data.data.postsCount > 0
        this.tasks.brochure = r.data.data.brochures.length > 0

        this.$financialService.getDeposits().then(r => {
          this.tasks.credits = r.data.data.length > 0
          this.loadingAll = false
        })
      }).catch(e => { })

    },
    handleSelectTab(index) {
      this.selectedTab = index
      let chartData = {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        bgColors: this.getBgColors(),
        values: []
      }
      switch (index) {
        case 0:
          //     // profile views 
          this.$set(this, 'chartData', {
            ...chartData,
            values: this.chartValues.views,
          })

          break;
        case 1:
          // requests
          this.$set(this, 'chartData', {
            ...chartData,
            values: this.chartValues.requests,
          })
          break;
        case 2:
          // appointments
          this.$set(this, 'chartData', {
            ...chartData,
            values: this.chartValues.appointments,
          })
          break;
        default: return
      }
    },
    dateChanged(date) {
      this.currentDate = date.date
      this.setupOverview()
    }
  },

  validations: {
    email: {
      required,
      email,
    },
  },
  components: {
    ProfileViews,
    RequestsToFollow,
    Appointments,
    AppointmentsCalendar,
    MonthSelector,
    BarChart,
  },
};