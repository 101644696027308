import moment from "moment"

function firstAndLastName(value) {
    if (!value) return false
    const v = value.split(" ")
    if (v.length < 2) return false
    const first = v[0].trim()
    const last = v[1].trim()
    return (first.length > 2 && last.length > 2)
}

function isDateValid(date, format = 'MM/DD/YYYY') {
    return date && moment(date, `${format}`, true).isValid()
}

function isDateFuture(date, format = 'MM/DD/YYYY') {
    const a = moment(date);
    console.log('DAAATA', a)
    var b = moment().format('MM/DD/YYYY');
    const r = a.diff(b, 'days')
    return isDateValid(date) && r >= 0
}

function isExpirationDateValid(date) {
    const a = moment(date, 'MM/YYYY', true);
    var b = moment().format();
    const r = a.diff(b, 'month')
    console.log(r)
    return isDateValid(date, 'MM/YYYY') && r >= 0
}


export { firstAndLastName, isDateValid, isDateFuture,isExpirationDateValid }