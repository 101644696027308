import EmptyState from "@/components/EmptyState.vue";
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/Sidebar.vue";
import { email, required } from "vuelidate/lib/validators";
import Brochures from "./Brochures/Brochures";
import Photos from "./Photos/Photos";
import Scholarships from "./Scholarships/Scholarships";
import LoadingContainer from "@/components/LoadingContainer.vue";
import Videos from "./Videos/Videos";

export default {
  name: "Audience",
  watch: {
    $route(to, from) {
      this.selectedTab = (to.query.tab || 0)
    }
  },
  mounted() {
    this.loadingAll = true
    this.getData()
    this.selectedTab = this.$route.query.tab || 0
    if (this.selectedTab == 2) {
      this.$eventBus.$emit('onTabPhotosSelected')
    }
  },
  data() {
    return {
      loadingAll: false,
      walletModal: null,
      allSelected: false,
      filtersCount: 0,
      selectedTab: 0,
      actionNames:
        [
          {
            name: 'Add Scholarship',
            path: this.$paths.university.scholarship.add
          },
          {
            name: 'Add Brochure',
            path: this.$paths.university.brochure.add
          },
          {
            name: 'Add Photo',
            path: this.$paths.university.addPhoto
          },
          {
            name: 'Add Video',
            path: this.$paths.university.addVideo
          },
        ],
      columns: {
        requests: [
          'Name',
          'Location',
          'School Type',
          'Match',
          'Cost to accept ',
        ],
        followers: [
          'Name',
          'Location',
          'School Type',
          'Match',
        ]
      },

      scholarships: [],
      brochures: [],
      photos: [],
      videos: [],
      filters: {
        match: {
          min: 20,
          max: 100
        },
        school_type: {
          public: false,
          private: false
        },
        location: {
          us: false,
          other_countries: false
        },

      }
    };
  },

  methods: {
    getData() {
      this.getScholarships()
      this.getBrochures()
      this.getPhotos()
      this.getVideos()

      setTimeout(() => {
        this.loadingAll = false
      }, 800);
    },
    getPhotos() {
      this.$universityService.getPhotos()
        .then(res => {
          this.$set(this, 'photos', res.data.data ? res.data.data.filter((s) => s.photos.length > 0) : [])
          console.log(res.data.data)

        }).catch(e => console.log(e))
    },
    getVideos() {
      this.$universityService.getVideos()
        .then(res => {
          const v = res.data.data.map(r => {
            let embededUrl = this.$helper.getEmbededUrl(this.$helper.youtubeParser(r.url))
            return {
              ...r,
              embededUrl
            }
          })
          this.$set(this, 'videos', v)
        }).catch(e => console.log(e))
    },
    getScholarships() {
      this.$universityService.getScholarships()
        .then(res => {
          this.$set(this, 'scholarships', res.data.data)
        }).catch(e => console.log(e))
    },
    getBrochures() {
      this.$universityService.getBrochures()
        .then(res => {
          console.log('brochures', res.data.data)
          this.$set(this, 'brochures', res.data.data)
        }).catch(e => {
          this.$set(this, 'brochures', [])
          console.log(e)
        })
    },
    toggleSelectAll() {
      this.allSelected = !this.allSelected
      this.items = this.items.map(r => { return { ...r, selected: this.allSelected } })
    },
    clearSelected() {
      this.allSelected = false
      this.items = this.items.map(r => { return { ...r, selected: false } })
    },
    toggleSelectItem(item) {
      this.items = this.items.map(r => {
        return { ...r, selected: r.id == item.id ? !item.selected : r.selected }
      })
      this.allSelected = false
    },
    itemPressed(item) {
      this.$router.push({ path: `${this.$paths.students.detail}/${item.id}` })

    },
    toggleWalletModal() {
      this.walletModal.toggle()
    },
    filterChanged() {
      const schoolCount = Object.keys(this.filters.school_type).filter(s => this.filters.school_type[s]).length
      const locationCount = Object.keys(this.filters.location).filter(s => this.filters.location[s]).length
      const matchCount = (this.filters.match.min != 20 || this.filters.match.max != 100) ? 1 : 0
      this.filtersCount = schoolCount + locationCount + matchCount
    },
    goToAddCredits() {
      this.walletModal.toggle()
      this.$router.push({ path: this.$paths.wallet.add_credits })

    },
    handleSelectTab(index) {
      this.$router.push({ path: `${this.$paths.university.list}`, query: { tab: index } }).catch(e => { })
      if (index == 2) {
        this.$eventBus.$emit('onTabPhotosSelected')
      }
    }
  },

  validations: {
    email: {
      required,
      email,
    },
  },
  components: {
    Scholarships,
    Photos,
    Videos,
    Brochures,
  },
};