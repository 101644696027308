import NetworkService from '@/services/NetworkService';

export default class MessagesService {
    constructor() {
        this.network = new NetworkService()
    }
    getChats() {
        return this.network.get(this.network.endpoints.Chats)
    }
    getChatMessages(chatId) {
        const url = `${this.network.endpoints.Chats}/${chatId}/messages`
        return this.network.get(url)
    }
    sendMessage(params) {
        const url = `${this.network.endpoints.Messages}`
        return this.network.post(url, params)
    }
    readMessages(chatId) {
        const url = `${this.network.endpoints.Chats}/${chatId}/read`
        return this.network.put(url, {})
    }
}
