import Sidebar from '../../components/Sidebar.vue'
import NavBar from '../../components/NavBar.vue'
import Container from '../../components/Container.vue'
import { minLength, required, sameAs } from 'vuelidate/lib/validators';
import LoadingContainer from "@/components/LoadingContainer.vue";

export default {
    loadingAll: false,
    name: 'Account',
    mounted() {
    },
    data() {
        return {
            token: this.$route.params.token,
            form: {
                password: '',
                passwordConfirmation: '',
            },
            errorMessage: '',
            loading: false,
        }
    },
    validations: {
        form: {
            password: { required, minLength: minLength(8) },
            passwordConfirmation: { required, sameAsPassword: sameAs('password') },
        }
    },
    methods: {
        async changePassword() {
            try {
                this.loading = true
                this.errorMessage = ''
                const res = await this.$userService.recoverPassword(this.form, this.token)
                this.$eventBus.$emit('showToast', { title: 'Password updated', msg: `You can log in with your new password` })
                this.$route.push(this.$paths.signin)
            } catch (e) {
                this.errorMessage = e.message
            }
            finally {
                this.loading = false
            }
        }
    },
    components: {
    }
}