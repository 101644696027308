<template>
  <div class="overflow-hidden">
    <Sidebar />
    <NavBar current="Diary" />
    <LoadingContainer v-if="loadingAll" />
    <Container col="col-12" v-else>
      <div class="row appointment-content" v-if="hasStarted">
        <div class="top-header row align-items-center mt-5 position-fixed">
          <div class="col-6">
            <h1 class="header-title">Diary</h1>
          </div>
          <div class="col-2">
            <MonthSelector class="w-80" v-on:monthSelectorChanged="monthChanged" />
          </div>
          <div class="col-4 d-flex justify-content-end pr-0">
            <router-link
              :to="$paths.agenda.edit"
              tag="a"
              class="btn w-60 btn-link bg-primary-soft my-3"
            >
              Edit my Availability
            </router-link>
          </div>
        </div>
        <div class="header mt-7">
          <div class="header-body border-0">
            <div class="row align-items-center">
              <div class="col">
                <ul
                  class="nav nav-tabs header-tabs"
                  id="myTab"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <a
                      @click.prevent="handleSelectTab(0)"
                      class="nav-link"
                      :class="{ active: selectedTab == 0 }"
                      id="appointments-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#appointments"
                      type="button"
                      role="tab"
                      aria-controls="appointments"
                      aria-selected="true"
                    >
                      Appointments
                      <span
                        class="ms-auto badge"
                        :class="{
                          'bg-primary-soft': selectedTab == 0,
                          'bg-secondary-soft': selectedTab != 0,
                        }"
                        >{{ appointmentItems.length }}</span
                      >
                    </a>
                  </li>

                  <li class="nav-item" role="presentation">
                    <a
                      @click.prevent="handleSelectTab(1)"
                      class="nav-link"
                      :class="{ active: selectedTab == 1 }"
                      id="requests-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#requests"
                      type="button"
                      role="tab"
                      aria-controls="requests"
                      aria-selected="true"
                    >
                      Appointment Requests
                      <span
                        class="ms-auto badge"
                        :class="{
                          'bg-primary-soft': selectedTab == 1,
                          'bg-secondary-soft': selectedTab != 1,
                        }"
                        >{{ requestsShowingItems.length }}</span
                      >
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-8 position-relative" ref="columnWidth">
          <div class="tab-content" id="myTabContent">
            <!-- APPOINTMENTS  -->
            <div
              class="table-responsive tab-pane fade"
              :class="{ 'show active': selectedTab == 0 }"
              id="appointments"
              role="tabpanel"
              aria-labelledby="appointments-tab"
            >
              <!-- <Slider
                :length="days.length"
                :width="columnWidth"
                :height="70"
                :gap="3"
                :itemMargin="16"
                :itemWidth="50"
                :extraItems="0"
              >
                <div v-for="(day, i) in days" :key="i" @click="selectDay(i)">
                  <div
                  style="margin-right: 16px"
                    class="
                      item
                      slider-img
                      d-flex
                      justify-content-center
                      align-items-center
                      display-5
                      
                      cursor-pointer
                    "
                    :class="{
                      'bg-light': !day.selected,
                      'bg-primary': day.selected,
                      'text-dark': !day.selected,
                      'text-white': day.selected,
                    }"
                  >
                    <strong>
                      {{ day.day }}
                    </strong>
                  </div>
                  <div class=" text-center mt-1" style="margin-right: 16px">
                    <strong
                      :class="{
                        'text-muted': !day.selected,
                        'text-primary': day.selected,
                      }"
                    >
                      {{ day.date | capitalize }}
                    </strong>
                  </div>
                </div>
              </Slider> -->
              <EmptyState
                title="No Appointments"
                msg="It looks like you have no appointments"
                v-if="appointmentItems.length == 0"
              >
                <template v-slot:icon>
                  <i class="fas fa-calendar fa-3x text-primary"></i>
                </template>
              </EmptyState>
              <!-- </Carousel> -->
              <!-- </div> -->

              <table
                v-if="appointmentItems.length > 0"
                class="
                  table table-sm table-hover table-nowrap
                  card-table
                  bg-white
                  mh-5
                  overflow-scroll
                "
              >
                <thead>
                  <tr>
                    <th
                      v-for="(column, index) in columns"
                      :key="column"
                      :class="{ 'text-start': index == 2 }"
                    >
                      {{ column }}
                    </th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr
                    v-for="item in appointmentItems"
                    :key="item.id"
                    class="cursor-pointer"
                    @click="itemPressed(item)"
                  >
                    <td width="1%">
                      <strong>{{ $moment(item.dateTime.date).format('MMM DD') }}</strong>
                    </td>
                    <td width="1%">
                      <strong>{{ item.dateTime.start }}</strong>
                    </td>
                    <td class="not-first">
                      <div v-if="item.student" class="d-flex align-items-center">
                        <ImagePlaceholder :src="item.student.image">
                          <template v-slot:placeholder>
                            <i
                              class="
                                fas
                                fa-user-circle
                                display-4
                                text-primary
                                me-2
                              "
                            ></i>
                          </template>
                        </ImagePlaceholder>
                        <span class="my-auto">{{ item.student.name }}</span>
                        <span
                          v-if="item.match"
                          class="badge bg-primary-soft ms-2 my-auto"
                          >{{ item.match.value }}% match</span
                        >
                      </div>
                    </td>
                    <td width="1%">
                      <strong
                        :class="{
                          'text-danger': item.status == 'CANCELLED',
                          'text-warning': item.status == 'ACCEPTED',
                          'text-muted': item.status == 'DONE',
                        }"
                        >{{
                          item.status == "ACCEPTED" && item.isBefore
                            ? $helper.getRemainingTime(
                                `${item.dateTime.date} ${item.dateTime.start}`
                              )
                            : $agendaService.status[item.status]
                        }}</strong
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- REQUESTS -->
            <div
              class="table-responsive mt-2 tab-pane fade"
              :class="{ 'show active': selectedTab == 1 }"
              id="requests"
              role="tabpanel"
              aria-labelledby="requests-tab"
            >
              <div class="search-table-form">
                <div class="row align-items-center">
                  <div class="col">
                    <form>
                      <div
                        class="
                          input-group
                          input-group-flush
                          input-group-merge
                          input-group-reverse
                        "
                      >
                        <input
                          class="form-control list-search"
                          type="search"
                          @input="searchString"
                          placeholder="Search"
                        />
                        <span class="input-group-text">
                          <i class="fas fa-search ps-3"></i>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <EmptyState
                title="No Requests"
                msg="It looks like you have no requests"
                v-if="requestItems.length == 0"
              >
                <template v-slot:icon>
                  <i class="fas fa-users fa-3x text-primary"></i>
                </template>
              </EmptyState>
              <table
                v-if="requestItems.length > 0"
                class="
                  table table-sm table-hover table-nowrap
                  card-table
                  bg-white
                  overflow-scroll
                  mt-5
                "
              >
                <thead>
                  <tr>
                    <th
                      v-for="(column, index) in columns"
                      :key="column"
                      :class="{ 'text-start': index == 2 }"
                    >
                      {{ column }}
                    </th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr
                    v-for="(item, index) in requestsShowingItems"
                    :key="item.id"
                    class="cursor-pointer"
                  >
                    <td width="1%">
                      <strong>{{ $moment(item.dateTime.date).format('MMM DD') }}</strong>
                    </td>
                    <td width="1%">
                      <strong>{{ item.dateTime.start }}</strong>
                    </td>
                    <td class="not-first" @click="itemPressed(item)">
                      <div class="d-flex align-items-center">
                        <ImagePlaceholder :src="item.student.image">
                          <template v-slot:placeholder>
                            <i
                              class="
                                fas
                                fa-user-circle
                                display-4
                                text-primary
                                me-2
                              "
                            ></i>
                          </template>
                        </ImagePlaceholder>

                        <span class="my-auto">{{ item.student.name }}</span>
                        <span
                          v-if="item.match"
                          class="badge bg-primary-soft ms-2 my-auto"
                          >{{ item.match.value }}% match</span
                        >
                      </div>
                    </td>
                    <td width="1%">
                      <button
                        :disabled="loadings[index]"
                        @click.prevent="acceptAppointment(index)"
                        class="btn btn-sm btn-primary"
                      >
                        <span
                          v-if="loadings[index]"
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Accept
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-4 calendar-container">
          <AppointmentsCalendar
            :data="{ appointments: monthAppointments, date: selectedDate }"
            :enableDaySelection="true"
            v-on:calendarDayChanged="calendarDayChanged"
            :mainTitle="`Full month (${$moment(selectedDate || $moment()).format('MMMM')})`"
          />
        </div>
      </div>

      <div
        class="empty-container align-items-center d-flex"
        v-if="!hasStarted && availabilityStep == 0"
      >
        <EmptyState
          title="Diary"
          msg="Here you can manage all your appointments, requests, schedule and video calls"
        >
          <template v-slot:icon>
            <i class="fas fa-calendar fa-3x text-primary"></i>
          </template>
          <template v-slot:button>
            <button type="button" @click="getStarted()" class="btn btn-primary">
              Get started
            </button>
          </template>
        </EmptyState>
      </div>

      <div v-else>
        <div
          class="row d-flex justify-content-center mt-6"
          v-if="availabilityStep == 1"
        >
          <div class="col-8">
            <h1 class="header-title mb-3">Timezone</h1>
            <strong
              >Please select the time you will cover to make appointments with
              students</strong
            >
            <div class="form-group mb-6 mt-4 row">
              <div class="row"></div>
              <div class="col-12">
                <label class="form-label required"> Time Zone </label>
                <button
                  id="btnGroupDrop1"
                  type="button"
                  class="
                    btn btn-white
                    text-dark
                    fw-bold
                    dropdown-toggle
                    text-start
                    d-flex
                    justify-content-between
                    align-items-center
                  "
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style="
                    width: 100%;
                    height: 41px;
                    border: 1px solid #d0cfcf;
                    border-radius: 0.375rem !important;
                  "
                >
                  {{ timeZone || timeZones[0] }}
                </button>
                <ul
                  class="dropdown-menu dropdown-fixed-height"
                  aria-labelledby="btnGroupDrop1"
                  style="min-width: 450px"
                >
                  <li class="w-100 me-0 position-absolute" style="top: 0">
                    <input
                      @input="searchTimeZone"
                      class="form-control border-0 border-bottom ps-4"
                      type="text"
                      placeholder="Search..."
                    />
                  </li>
                  <li
                    class="dropdown-item cursor-pointer"
                    v-for="(e, i) in showingTimeZones"
                    :key="i"
                    :class="{ 'pt-5': i == 0 }"
                    @click="setTimeZone(e.name)"
                  >
                    {{ e.name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="d-grid gap-2 mt-4">
              <button class="btn btn-primary" @click="availabilityStep = 2">
                Save and continue
              </button>
            </div>
          </div>
        </div>
        <div
          class="row d-flex justify-content-center mt-6"
          v-if="availabilityStep == 2"
        >
          <Availability
            v-on:saveAvailability="saveAvailability"
            :timeZone="timeZone"
          />
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import AgendaController from "./AgendaController";
export default AgendaController;
</script>

<style scoped>
.empty-container {
  height: calc(100vh - 70px);
}
.btn-del {
  padding-left: 1.15rem;
  padding-right: 1.15rem;
}
#profile-right-container {
  position: fixed;
}
.days {
  overflow: scroll;
  width: -webkit-fill-available;
}
.slider-img,
.days .item {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.top-header {
  width: calc(100% - 250px - 2.25rem + calc(var(--bs-gutter-x) * .5));
}

#myTab {
  width: 50vw;
} 

@media screen and (min-width: 1780px) {
  .top-header {
    width: calc(100% - 250px - 2.25rem - calc(var(--bs-gutter-x) * 2));
  }
  #myTab {
    width: 55vw;
  } 
}
#myTabContent, .table-responsive { 
  max-height: 55vh;
  overflow-x: hidden;
}
.table-responsive {
  height: 50%;
  min-height: 50vh;
  overflow-x: none;
}

.appointment-content {
  height: 60vh;
}

.calendar-container {
  margin-top: -120px;
}


</style>
