<template>
  <div class="wallet">
    <Sidebar />
    <NavBar current="Wallet" />
    <LoadingContainer v-if="loadingAll"  />
    <div class="main-content" v-else>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="header mt-md-5">
              <div class="header-body border-0">
                <div class="row align-items-center">
                  <div class="col">
                    <h1 class="header-title">Wallet</h1>
                  </div>
                  <div class="col-auto">
                    <MonthSelector v-on:monthSelectorChanged="dateChanged" />
                  </div>
                  <div class="col-auto">
                    <router-link
                      :to="$paths.wallet.add_credits"
                      tag="a"
                      class="nav-item btn btn-primary me-3"
                    >
                      <span class="fas fa-coins display-6"></span> Add credits
                    </router-link>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col">
                    <ul
                      class="nav nav-tabs header-tabs"
                      id="myTab"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation">
                        <a
                          @click.prevent="handleSelectTab(0)"
                          class="nav-link"
                          :class="{'active': selectedTab == 0}"
                          id="deposits-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#deposits"
                          type="button"
                          role="tab"
                          aria-controls="deposits"
                          aria-selected="true"
                        >
                          Deposits
                          <span
                            class="ms-auto badge"
                            :class="{
                              'bg-primary-soft': selectedTab == 0,
                              'bg-secondary-soft': selectedTab != 0,
                            }"
                            >{{deposits.length || 0}}</span
                          >
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          @click.prevent="handleSelectTab(1)"
                          class="nav-link"
                          :class="{'active': selectedTab == 1}"
                          id="purchases-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#purchases"
                          type="button"
                          role="tab"
                          aria-controls="purchases"
                          aria-selected="false"
                        >
                          Purchases
                          <span
                            class="ms-auto badge"
                            :class="{
                              'bg-primary-soft': selectedTab == 1,
                              'bg-secondary-soft': selectedTab != 1,
                            }"
                            >{{purchases.length || 0}}</span
                          >
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="search-table-form">
              <div class="row align-items-center">
                <div class="col">
                  <form>
                    <div
                      class="
                        input-group
                        input-group-flush
                        input-group-merge
                        input-group-reverse
                      "
                    >
                      <input
                        class="form-control list-search"
                        type="search"
                        placeholder="Search"
                      />
                      <span class="input-group-text">
                        <i class="fas fa-search ps-3"></i>
                      </span>
                    </div>
                  </form>
                </div>
                <div class="col-auto">
                  <div class="dropdown me-3">
                    
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-content" id="myTabContent">
              <!-- DEPOSITS TAB -->
              <div
                class="table-responsive mt-5 tab-pane fade"
                :class="{'show active': selectedTab == 0}"
                id="deposits"
                role="tabpanel"
                aria-labelledby="deposits-tab"
              >
                <EmptyState
                  title="No Deposits"
                  msg="It looks like you have no deposits"
                  v-if="deposits.length == 0"
                >
                  <template v-slot:icon>
                    <i class="fas fa-coins fa-3x text-primary"></i>
                  </template>
                </EmptyState>
                <table
                  v-if="deposits.length > 0"
                  class="
                    table table-sm table-hover table-nowrap
                    card-table
                    bg-white
                  "
                >
                  <thead>
                    <tr>
                      <th
                        v-for="(column, index) in columns.deposits"
                        :key="column"
                        :class="{ 'text-start': index == 0 }"
                        :width="index != 0 ? '1%' : 'auto'"
                      >
                        {{ column }}
                      </th>
                      
                    </tr>
                  </thead>
                  <tbody class="list">
                    <tr
                      v-for="item in deposits"
                      v-bind:key="item.id"
                      class="cursor-pointer"
                    >
                      <td class="text-start">
                        <strong>{{item.user.name}}</strong>
                      </td>
                      <td>{{$moment(item.createdAt).format('MM-DD-YYYY')}}</td>
                      <td>{{ item.totalCredits }}</td>
                      <td>
                        <strong>Us${{ item.creditPack.amount }}</strong>
                      </td>
                      <td>
                        <span v-if="item.invoice.status == 'PAID'" class="ms-auto badge bg-success-soft"
                          ><strong>Paid</strong></span
                        >
                        <span v-else class="ms-auto badge bg-danger-soft"
                          ><strong>Pending</strong></span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- PURCHASES TAB -->

              <div
                class="table-responsive mt-5 tab-pane fade"
                :class="{'show active': selectedTab == 1}"
                id="purchases"
                role="tabpanel"
                aria-labelledby="purchases-tab"
              >
                <EmptyState
                  title="No Purchases"
                  msg="It looks like you have no purchases"
                  v-if="purchases.length == 0"
                >
                  <template v-slot:icon>
                    <i class="fas fa-receipt fa-3x text-primary"></i>
                  </template>
                </EmptyState>
                <table
                  v-if="purchases.length > 0"
                  class="
                    table table-sm table-hover table-nowrap
                    card-table
                    bg-white
                  "
                >
                  <thead>
                    <tr>
                      <th
                        v-for="(column, index) in columns.purchases"
                        :key="column"
                        :class="{ 'text-start': index == 0 }"
                        :width="index != 0 ? '1%' : 'auto'"
                      >
                        {{ column }}
                      </th>
                      
                    </tr>
                  </thead>
                  <tbody class="list">
                    <tr
                      v-for="item in purchases"
                      v-bind:key="item.id"
                      class="cursor-pointer"
                    >
                      <td class="text-start">
                        <strong>{{item.user.name}}</strong>
                      </td>
                      <td>{{$moment(item.createdAt).format('MM-DD-YYYY')}}</td>
                      <td>{{item.usedCredits}}</td>
                     
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WalletController from "./WalletController";
export default WalletController;
</script>
