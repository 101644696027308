import { required, email, minLength } from 'vuelidate/lib/validators';
import { firstAndLastName } from '@/utils/Validators';
import LoadingContainer from "@/components/LoadingContainer.vue";

export default {
  name: 'AccountActivation',
  mounted() {
    this.loadingAll = true
    this.checkToken()
  },
  data() {
    return {
      loadingAll: false,
      success: false
    };
  },
  methods: {
    async checkToken() {
      try {
        const res = await this.$userService.activateAccount(this.$route.params.token)
        this.success = true
      } catch (e) {
        this.success = false
      } finally {
        this.loadingAll = false
      }
    },
  },
  components: {
  }
};