<template>
  <div>
    <div class="row">
      <div class="col-12">
        <label
          :label="label"
          class="item"
        >
        </label>

          <template slot="tooltip">
            <p>
              Note: If you don't have your actual results please estimate what
              you expect to get. This will enhance the accuracy of the matching
              scores the NOVVA system will give you AND boost your scholarship
              options.
            </p>
          </template>
          <BaseSelectTags
            :id="'component-exams'"
            v-model="optionSelected"
            :is-object="true"
            :options="options"
          />
      </div>
    </div>

    <div class="row"
      v-if="examScoreList"
    >
        <div class="row">
          <div
            class="col-12 col-md-6 item mt-2"
             v-for="(item, i) in examScoreList"
            :key="i"
            :label="item.label"
          >
            <BaseSelect
              v-model="item.score"
              placeholder="Score"
              :options="item.options"
              :name="item.value"
              :label="item.name"
            />
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import BaseSelect from './BaseSelect'
import BaseSelectTags from './BaseSelectTags'
import BaseFormRadioGroup from './BaseFormRadioGroup'

export default {
  name: 'BaseFormExams',

  components: {
    BaseSelect,
    BaseSelectTags,
    BaseFormRadioGroup
  },

  props: {
    value: {
      type: [Array, Object],
      default: '',
      required: false
    },
    label: {
      type: String,
      default: ''
    },
    exams: {
      type: Array
    },
  },

  data() {
    return {
      val: [],
      options: [],
      valueOptions: [],
      examScoreList: [],
      examSelected: [],
      errorMessage: ''
    }
  },

  computed: {
    optionSelected: {
      get: function() {
        return this.examSelected
      },
      set: function(value) {
        let valueSelected = [...value]

        if (!valueSelected.includes(this.createNotApplicable().value)) {
          this.examSelected = valueSelected
          this.updateScoreList(valueSelected)
        } else {
          const notApplicableAdded = valueSelected.find(
            (x) => this.createNotApplicable().value === x
          )

          const lastAdded = valueSelected[valueSelected.length - 1]

          if (notApplicableAdded && notApplicableAdded === lastAdded) {
            this.examScoreList = []
            this.examSelected = [this.createNotApplicable().value]
          } else {
            // removing not applicable from exams selected
            const notApIdx = valueSelected.indexOf(
              this.createNotApplicable().value
            )
            if (notApIdx > -1) valueSelected.splice(notApIdx, 1)
            this.examSelected = valueSelected
            this.updateScoreList(valueSelected)
          }
        }
      }
    }
  },

  watch: {
    examScoreList: {
      handler(value) {
        const listAdd = value.map((item) => {
          const itemAdd = {
            exam: item.value,
            examName: item.name,
            score: item.score,
          }
          return itemAdd
        })
        this.$emit('input', listAdd)
      },
      deep: true
    }
  },

  created() {
    this.loadingOptions()
  },

  methods: {
    async loadingOptions() {
      this.options = this.parseToSelectOptions(this.exams)
      this.valueOptions = this.parseToValueOptions(this.exams)
    },

    parseToSelectOptions(options) {
      if (options) {
        return options.map((opt) => ({
          name: opt.key,
          value: opt._id
        }))
      }
      return []
    },

    parseToValueOptions(options) {
      if (options) {
        return options.map((opt) => ({
          examId: opt.id,
          values: opt.values
        }))
      }
      return []
    },

    createNotApplicable: () => ({
      value: 'NOT_APPLICABLE',
      name: 'Not Applicable'
    }),

    updateScoreList(valueSelected) {
      const valueAdd = valueSelected.find(
        (x) => !this.examScoreList.find((examScore) => examScore.value === x)
      )
      if (valueAdd) {
        const examAdd = this.options.find((option) => valueAdd === option.value)

        const scoreOptions = this.valueOptions.find(
          (option) => option.examId === examAdd.value
        ).values

        this.examScoreList.push({
          options: scoreOptions.map((opt) => {
            if (typeof opt === 'object') {
              return { name: opt.name, value: opt.value }
            } else {
              return { name: opt, value: opt }
            }
          }),
          name: examAdd.name,
          label: examAdd.name,
          value: examAdd.value,
          score: null,
          radioSelected: '',
          radioList: ['Actual', 'Expected']
        })
      } else {
        const valueRemove = this.examScoreList.find(
          (examScore) => !valueSelected.find((x) => examScore.value === x)
        )

        this.examScoreList = this.examScoreList.filter(
          (x) => x.value !== valueRemove.value
        )
      }
    },

    clearExams() {
      this.examSelected = []
      this.examScoreList = []
    }
  }
}
</script>

<style lang="scss" scoped>
.base-radio-group {
  padding: 0px 15px 0px;
  height: 60px;
}
</style>
