import EmptyState from "@/components/EmptyState.vue";
import MonthSelector from "@/components/MonthSelector.vue";
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/Sidebar.vue";
import { email, required } from "vuelidate/lib/validators";
import moment from 'moment'
import LoadingContainer from "@/components/LoadingContainer.vue";

export default {
  name: "Wallet",
  watch: {
    $route(to, from) {
      this.selectedTab = (to.query.tab || 0)
    }
  },
  mounted() {
    this.loadingAll = true
    this.getData()
    this.selectedTab = this.$route.query.tab || 0
  },
  data() {
    return {
      loadingAll: false,
      currentDate: this.$moment().format('YYYY/MM'),
      allSelected: false,
      filtersCount: 0,
      selectedTab: 0,
      columns: {
        deposits: [
          'User Name',
          'Date',
          'Credits',
          'Value',
          'Status',
        ],
        purchases: [
          'User Name',
          'Date',
          'Credits',
        ]
      },
      deposits: [],
      purchases: [],
      filters: {
        match: {
          min: 20,
          max: 100
        },
        school_type: {
          public: false,
          private: false
        },
        location: {
          us: false,
          other_countries: false
        },

      }
    };
  },
  methods: {
    async getData() {
      try {
        const r1 = await this.$financialService.getDeposits(this.currentDate)
        const r2 = await this.$financialService.getPurchases(this.currentDate)
        this.$set(this, 'deposits', r1.data.data)
        this.$set(this, 'purchases', r2.data.data)
      } catch (e) {

      } finally {
        this.loadingAll = false
      }
    },
    dateChanged(date) {
      console.log('date changed', JSON.stringify(date))
      this.currentDate = date.date.replace('-', '/')
      this.getData()

    },
    handleSelectTab(index) {
      this.$router.push({ path: `${this.$paths.wallet.list}`, query: { tab: index } }).catch(e => { })
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  components: {
    MonthSelector,
  },
};