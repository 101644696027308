export default {
    name: 'Notifications',
    mounted() {
        this.loadingAll = true
        this.getNotifications()
    },
    data() {
        return {
            loadingAll: false,
            postComments: false,
            followerRequest: false,
            appointmentRequest: false,
            items: [],
            filteredItems: []
        }
    },
    methods: {
        async getNotifications() {
            try {
                const res = await this.$userService.getNotifications();
                console.log(res.data.data);
                this.$set(this, "items", res.data.data);
                this.$set(this, "filteredItems", res.data.data);
            } catch (e) {
                console.log(e);
            } finally {
                this.loadingAll = false
            }
        },

        filterChanged() {
            console.log(this.postComments)
            if (!this.postComments && !this.followerRequest && !this.appointmentRequest) {
                this.$set(this, 'filteredItems', this.items)
                return
            }
            const items = this.items.filter(r => {
                return (
                    (this.postComments ? r.type == 'POST_COMMENT' : false) ||
                    (this.followerRequest ? r.type == 'FOLLOWER_REQUEST' : false) ||
                    (this.appointmentRequest ? r.type == 'APPOINTMENT_REQUEST' : false)
                )
            })
            this.$set(this, 'filteredItems', items)
        }
    },
    components: {
    }
}