<template>
  <div>
    <Sidebar />
    <NavBar current="Chat Detail" :link="$paths.messages.list" linkName="Messages" />
    <Container col="col-12 col-lg-10 col-xl-6">
      <div class="header mt-md-4 border-bottom">
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col-auto">
              <!-- Avatar -->
              <a href="#!" class="avatar">
                <img
                  :src="chatInfo.studentPicture"
                  alt="..."
                  class="avatar-img rounded-circle"
                />
              </a>
            </div>
            <div class="col ms-n2">
              <!-- Title -->
              <h4 class="mb-1 h4">{{ chatInfo.studentName }}</h4>

              <!-- Time -->
              <p class="card-text text-muted">
                <small>{{ chatInfo.studentLocation }}</small>
              </p>
            </div>
            <div class="col-auto">
              <!-- Dropdown -->
              <router-link
                tag="button"
                :to="{ path: `${$paths.students.detail}/${chatInfo.studentId}` }"
                class="btn btn-link"
              >
                See full profile
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <form>
        <div class="row">
          <div class="col-12 text-center">
            <small class="text-muted" v-if="chatInfo.firstMessage"
              >First message on
              {{ $moment(chatInfo.firstMessage).format("MMM DD, YYYY") }}
            </small>
          </div>
          <div id="main-chat-container" class="col-12 chat-container overflow-scroll">
            <div v-for="(message, index) in items" v-bind:key="'message' + index">
              <div
                v-if="message.user && message.user._id == userSession.id"
                class="message-container d-flex flex-column align-items-end"
              >
                <div class="chat-bubble right-bubble bg-primary">
                  <span class="text-left text-white">{{ message.message }}</span>
                </div>
                <div class="chat-footer">
                  <small class="text-muted me-2">{{
                    $moment(message.createdAt).format("HH:mm")
                  }}</small>
                  <span v-if="message.viewedAt" class="text-blue-link">
                    <small class="fas fa-check"></small>
                    <small
                      style="left: -9px; position: relative; right: 0px; top: 1px"
                      class="fas fa-check"
                    ></small>
                  </span>
                  <!-- <small class="fas fa-clock text-muted"></small> -->
                </div>
              </div>

              <div v-else class="message-container d-flex flex-column">
                <div class="d-flex flex-row align-items-end">
                  <ImagePlaceholder :src="chatInfo.studentPicture">
                    <template v-slot:placeholder>
                      <span class="fas me-2 fa-user-circle fa-2x text-primary"></span>
                    </template>
                  </ImagePlaceholder>

                  <div class="chat-bubble left-bubble bg-secondary-soft">
                    <span class="text-left text-dark">{{ message.message }}</span>
                  </div>
                </div>
                <div class="chat-footer">
                  <small class="text-muted me-2 ps-5">{{
                    $moment(message.createdAt).format("HH:mm")
                  }}</small>
                  <!-- <span v-if="message.viewedAt" class="text-blue-link">
                    <small class="fas fa-check"></small>
                    <small
                      style="left: -9px; position: relative; right: 0px; top: 1px"
                      class="fas fa-check"
                    ></small>
                  </span> -->
                  <!-- <small class="fas fa-clock text-muted"></small> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="input-group input-group-merge input-group mb-3">
              <textarea
                rows="5"
                class="form-control"
                v-model="textMessage"
                type="text"
                v-on:keyup.enter="handleSend()"
                :maxlength="maxTextLength"
                placeholder="Type..."
              />
              <div
                class="input-group-text text-dark align-items-start"
                id="inputGroupReverse"
              >
                <button class="btn btn-link" @click.prevent="handleSend()">Send</button>
              </div>
            </div>
          </div>
          <div class="col-12 text-end">
            <small class="text-muted"
              >Chars left: {{ maxTextLength - textMessage.length }}</small
            >
          </div>
        </div>
      </form>
    </Container>
  </div>
</template>

<script>
import ChatController from "./ChatController";
export default ChatController;
</script>

<style>
div.chat-container {
  height: calc(100vh - 400px);
}
div.chat-bubble {
  background-color: pink;
  max-width: 60%;
  border-radius: 30px;
  padding: 12px !important;
}
div.chat-bubble.right-bubble {
  border-bottom-right-radius: 0 !important;
}
div.chat-bubble.left-bubble {
  border-bottom-left-radius: 0 !important;
}
textarea {
  resize: none;
}
</style>
