import Sidebar from '@/components/Sidebar'
import NavBar from '@/components/NavBar'
import Container from '@/components/Container'
import LoadingContainer from "@/components/LoadingContainer.vue";


export default {
    name: 'ChatDetail',
    mounted() {
        this.loadingAll = true
        this.getItem()
    },
    data() {
        return {
            loadingAll: false,
            item: {},
            memberId: this.$route.params.id
        }
    },
    methods: {
        async getItem() {
            try {
                const res = await this.$teamService.showMember(this.memberId)
                this.item = res.data.data
            }catch(e) {
    
            } finally {
                this.loadingAll = false
            }
        },
    },
    components: {
    }
}