import { email, minLength, required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
export default {
  name: 'Signin',
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      loading: false,
      errorMessage: '',
      passwordFieldType: 'password'
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8)
      },
    }
  },
  methods: {
    ...mapActions([
      'syncSessionWithApi'
    ]),
    togglePassword() {
      this.passwordFieldType = this.passwordFieldType == 'password' ? 'text' : 'password'
    },
    async submit() {
      this.errorMessage = ''
      this.loading = true
      const formValues = {
        ...this.form,
        ...this.$route.query
      }
      try {
        const res = await this.$userService.register(formValues)
        this.$router.push({ path: this.$paths.signup.confirmation, query: { email: formValues.email, password: formValues.password } })
      } catch (e) {
        this.errorMessage = e.message
      } finally {
        this.loading = false
      }
    },
  }
};