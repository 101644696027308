import axios from 'axios';
import router from '@/router'
import paths from '@/router/paths'
import qs from 'qs'

export default class NetworkService {
    baseUrl = process.env.VUE_APP_API_BASE_URL

    endpoints = {
        //phurshell
        Calls: 'appointment-calls',

        //novva
        Overview: 'overview',
        SignedUrl: 'upload/signed-url',
        Chats: 'chats',
        Messages: 'chats/messages',
        Notifications: 'notifications',
        Agenda: {
            list: 'agenda',
            appointments: 'agenda/appointments',
            requests: 'agenda/requests',
            availableDates: 'agenda/free-times'
        },
        Audience: {
            followers: 'audience/followers',
            requests: 'audience/requests',
            students: 'audience/students'
        },
        Financial: {
            deposits: 'financial/wallet/deposits',
            purchases: 'financial/wallet/purchases',
            packs: 'financial/credit/packs',
        },
        Social: {
            posts: 'social/university/posts',
            myPosts: 'social/posts',
        },
        Team: {
            members: 'team/members'
        },
        User: {
            register: 'user/register',
            checkEmail: 'user/register/check-email',
            validateEmail: 'user/validate-email',
            updatePassword: 'user/update-password',
            signin: 'user/signin',
            forgotPassword: 'user/forgot-password',
            account: 'user/account',
            accountActivation: 'user/register/activate-account/',
            resendEmail: 'user/register/resend-email',
        },
        University: {
            videos: 'university/videos',
            scholarships: 'university/scholarships',
            scholarshipsFields: 'university/scholarships/fields/get',
            scholarshipsStatus: 'university/scholarships/status/put',
            profile: 'university/profile',
            photos: 'university/photos',
            brochures: 'university/brochures',
            steps: 'university/onboarding/profile/steps',
            onboarding: 'university/onboarding',
            albums: 'university/albums',
        },

    }


    constructor() { }

    createSignedUrl(type, file) {
        return new Promise((resolve, reject) => {
            const mimeType = file.file.type

            try {
                this.postEncoded(this.endpoints.SignedUrl, { type, mimeType }).then(res => {
                    this.makeExternalRequest('put', res.data.data, file.file, { 'Content-Type': mimeType }).then(res2 => {
                        resolve({ status: true, signedUrl: res.data.data.split('?')[0] })
                    })
                })
            } catch (e) {
                reject(e)
            }
        })
    }
    post(endPoint, params) {
        return axios.post(this.baseUrl + endPoint, params, { headers: this.headers() })
            .then(res => res)
            .catch(e => {
                this.handleError(e.response)
            })
    }
    get(endPoint, defaultToken = '', params = {}) {
        return axios.get(this.baseUrl + endPoint, {
            headers: this.headers('', defaultToken),
            params
        })
            .then(res => res)
            .catch(e => {
                this.handleError(e.response)
            })
    }
    put(endPoint, params) {
        return axios.put(this.baseUrl + endPoint, params, { headers: this.headers() })
            .then(res => res)
            .catch(e => {
                this.handleError(e.response)
            })
    }
    delete(endPoint, params = {}) {
        return axios.delete(this.baseUrl + endPoint, { data: params, headers: this.headers() })
            .then(res => res)
            .catch(e => {
                this.handleError(e.response)
            })
    }
    handleError(e) {
        if (e.status == 401 || e.data?.message == 'jwt must be provided') {
            router.push(paths.signin)
        }
        throw ({ message: e.data.message || 'There was an error, please try again' })
    }



    postEncoded(endPoint, params) {
        return axios.post(this.baseUrl + endPoint, qs.stringify(params), { headers: this.headers('urlencoded') })
            .then(res => res)
            .catch(e => {
                this.handleError(e.response)
            })
    }


    putEncoded(endPoint, params) {
        var token = localStorage.getItem('token')
        console.log(token)
        if (token == null) {
            token = ''
        }
        return axios.put(this.baseUrl + endPoint, qs.stringify(params), { headers: this.headers('urlencoded') })
            .then(res => res)
            .catch(e => {
                this.handleError(e.response)
            })

    }



    postMultipart(endPoint, params) {
        return axios.post(this.baseUrl + endPoint, this.makeItMultipartParams(params), { headers: this.headers('multipart') })
            .then(res => res)
            .catch(e => {
                this.handleError(e.response)
            })
    }


    putMultipart(endPoint, params) {
        var token = localStorage.getItem('token')
        console.log(token)
        if (token == null) {
            token = ''
        }
        return axios.put(this.baseUrl + endPoint, this.makeItMultipartParams(params), { headers: this.headers('multipart') })
            .then(res => res)
            .catch(e => {
                this.handleError(e.response)
            })

    }
    // makeEncodedParams(params) {
    //     return qs.stringify(params)
    // }
    makeItMultipartParams(params) {

        var p = new FormData()
        Object.keys(params).forEach(function (key, index) {
            if (Array.isArray(params[key])) {
                params[key].map(r => {
                    p.append(`${key}[]`, r)
                })
            } else {
                console.log(key)
                console.log(params[key])
                p.append(key, params[key])
            }

        });
        return p
    }

    makeExternalRequest(type, endPoint, params, headers = {}) {
        switch (type) {
            case "post":
                return axios.post(endPoint, params, { headers })

            case "get":
                return axios.get(endPoint, { headers })

            case "put":
                return axios.put(endPoint, params, { headers })
        }
    }
    headers(encodeType = '', defaultToken = '') {
        var access_token = localStorage.getItem('token') || defaultToken
        var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        if (encodeType == 'multipart') {
            delete (headers['Content-Type'])
        }
        if (encodeType == 'urlencoded') {
            headers['Content-Type'] = `application/x-www-form-urlencoded`
        }
        if (access_token) {
            headers['Authorization'] = `Bearer ${access_token}`
        }
        return headers
    }

}
