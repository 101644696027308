import Container from "@/components/Container.vue";
import EmptyState from "@/components/EmptyState.vue";
import ImageCropper from "@/components/ImageCropper/ImageCropper";
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/Sidebar.vue";
import "cropperjs/dist/cropper.css";
import VueCropper from "vue-cropperjs";
import { required } from 'vuelidate/lib/validators';

export default {
    data() {
        return {
            form: {
                title: '',
                fileImage: '',
            },
            errorMessage: '',
            loading: false,
            percentage: 0,
            uploading: false,
            counter: null,
            customFileInputWidth: 724

        }
    },
    validations: {
        form: {
            title: { required },
            fileImage: { required }
        }
    },
    components: {
        VueCropper,
        ImageCropper
    },
    methods: {
        calculateWidth() {
            const width = this.$refs.titleInput?.clientWidth
            this.customSelectWidth = width
            this.customFileInputWidth = width
        },

        setIframe() {
            this.embed = this.video_link.replace('watch?v=', 'embed/').split('&')[0]
            this.hasLink = true
        },
        async save() {
            this.errorMessage = ''
            this.loading = true
            try {
                const res = await this.$universityService.createBrochure({...this.form})
                this.$eventBus.$emit('showToast', { title: 'Brochure Added', msg: `${this.form.title} was added successfully.` })
                this.$router.push({ path: `${this.$paths.university.list}`, query: { tab: 1 } }).catch(e => { })
            } catch (e) {
                this.errorMessage = e.message
            } finally {
                this.loading = false
            }

        },
        cropEnd(image) {
            this.form.fileImage = image
        }
    }
}